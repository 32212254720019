<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- no -> fixed-top -->
  <div class="container p-0">
    <a class="navbar-brand" href="javascript:void(0);" [routerLink]="['/industrias-extractivas']">
      <img src="../../../assets/img/logo2.png" class="img-fluid" alt="..." />
    </a>

    <button #navbarToggler class="navbar-toggler" type="button" data-toggle="collapse"
      data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
      aria-label="Toggle navigation">
      <span *ngIf="toggler"></span>
      <span *ngIf="!toggler"></span>
      <span></span>
      <span></span>
    </button>

    <div class="collapse navbar-collapse d-lg-flex justify-content-between" id="navbarTogglerDemo02">
      <li class="nav-item nav-convoca active">
        <a class="nav-link nav-link-convoca" [routerLink]="['/home']">Inicio <span class="sr-only">(current)</span></a>
      </li>

      <li class="nav-item nav-convoca">
        <a class="nav-link nav-link-convoca" [routerLink]="['/nosotros']">Nosotros</a>
      </li>
      <li class="nav-item nav-convoca">
        <a class="nav-link nav-link-convoca" [routerLink]="['/reportajes']">Reportajes</a>
      </li>
      <li class="nav-item nav-convoca">
        <a class="nav-link nav-link-convoca" [routerLink]="['/planes']">Membresía</a>
      </li>
      <li class="nav-item nav-convoca">
        <a class="nav-link nav-link-convoca" [routerLink]="['/metodologia']">Metodología</a>
      </li>
      <li class="nav-item nav-convoca">
        <a class="nav-link nav-link-convoca"
          href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
          target="_blank">Laboratorio de datos</a>
      </li>

      <li class="nav-item nav-user d-none d-md-block">
        <div class="nav-item-container p-2 pl-0">
          <div *ngIf="!authService.token" class="d-flex align-items-center">
            <a role="button" [routerLink]="['/auth/ingreso']">
              <div class="inline-icon">
                <b>Iniciar sesión</b>
              </div>
            </a>
            <a role="button" class="btn btn-danger btn-custom-red ml-3"
              [routerLink]="['/auth/registro']">Registrarse</a>
          </div>

          <div *ngIf="authService.token" class="d-flex align-items-center">
            <!-- Dropdown para el usuario autenticado -->
            <div class="dropdown">
              <a role="button" class="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <b>{{ _usuarioService.perfilUsuario?.nombres }}</b>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                <a class="dropdown-item nav-link nav-link-convoca" [routerLink]="['/auth/cuenta']">Mi perfil</a>
                <a  *showForRoles="flagsAuth.ADMIN" class="dropdown-item nav-link nav-link-convoca" [routerLink]="['/administracion']">Administración</a>
                <div class="dropdown-divider"></div>
                <a role="button" class="dropdown-item nav-link nav-link-convoca" (click)="cerrarSesion()">Cerrar
                  sesión</a>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="nav-item nav-convoca d-md-none pt-3 border-top">
        <div class="d-flex align-items-center justify-content-between h-25 pb-3">
          <a class="nav-link nav-link-convoca"
            href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
            target="_blank">©Convoca 2021
          </a>
          <div class="social-header d-flex mr-3">
            <a class="jpg-facebook rounded-social mr-2" href="javascript:void(0);" (click)="shareFacebook()">
              <i class="fa fa-facebook-f social-icon icon-footer"></i>
            </a>
            <a class="jpg-twitter rounded-social" href="javascript:void(0);" (click)="shareTwitter()">
              <i class="fa fa-twitter social-icon icon-footer"></i>
            </a>
          </div>
        </div>
      </li>
    </div>
  </div>
</nav>
