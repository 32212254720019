
export class Empresa {

  // infraccionid: number;
  // ruc: string;
  // Longitud: string;
  // Latitud: string;
  // NombreEmpresa: string;
  // NombreEnpresaAntigua: string;
  // sectoreconomico: string;
  // region: string;
  // Provincia: string;
  // Distrito: string;
  // agno: string;
  // TipoInfraccion: string;

  Ruc: string;
  NombreEmpresa: string;
  NombreEnpresaAntigua: string;
  SectorEconomico: string;
  Region: string;
  Provincia: string;
  Distrito: string;
  Longitud: string;
  Latitud: string;
  Agno: string;
  IndInfAmb: string;
  IndInfLab: string;
  IndAccidentes: string;
  IndInfAmbAcc: string;
  IndInfLabAcc: string;
  IndAccidentesAcc: string;
  IndInfAmbSemaforo: string;
  IndInfLabSemaforo: string;
  IndAccidentesSemaforo: string;
  IndInfAmbAccSemaforo: string;
  IndInfLabAccSemaforo: string;
  IndAccidentesAccSemaforo: string;
  nroproyectos: string;
  nroproyectosacc: string;
  OtrosIndicadores: string;
  ReactivaPeru: string;

  Covid: number;
  derrames: string;
  derramesacc: string;
  accidentes: string;
  accidentesacc: string;

  permisosagua: string;
  permisosaguaacc: string;
  ConflictosSoc: string;
  ConfictosSocAcc: string;
  InicioActividades: string;
  Estado: string;
  MultasPerdonadas: string;
  MultasPerdonadasDolares: string;
  MultasPerdonadasUIT: string;
  MultaSoles: string;
  MultaDolares: string;
  MultaUIT: string;

  indinfamb_acc: string;
  NotaCovid: string;
  NotaCovid2: string;

  ReactivaPeruFormatted: number;
  ranking_actual: number;
  cambio_ranking: number;
  multas_laborales_judicial: number;
  monto_multa_judicial_dolares: number;
  monto_multa_judicial_soles: number;

  constructor() { }

}
