import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { ModalCaptchaComponent } from './components/modal-captcha/modal-captcha.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    TopbarComponent,
    FooterComponent,
    ModalCaptchaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule
  ],
  exports: [
    TopbarComponent,
    FooterComponent,
    ModalCaptchaComponent
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LcDEMoZAAAAAL1zYADrCQpJ0MBJ9GR115ElDjvr',
    } as RecaptchaSettings,
  }]
})
export class CoreModule { }
