import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UsuarioService } from './usuario.service';

const base_url = environment.base_url + '/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  usuario: string;
  token: string;

  constructor(
    private http: HttpClient,
    private usuarioService: UsuarioService
  ) {
    this.cargarStorage();
  }

  login(email: string, password: string, recuerdame: boolean) {
    let body = { email: email, password: password };
    return this.http.post(`${base_url}/login`, body).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.token = resp.token;
          this.usuario = email;
          this.setStorage(this.token, this.usuario, resp.roles);
          if (recuerdame) {
            localStorage.setItem('usuario_login', email);
            localStorage.setItem('password_login', password);
          } else {
            localStorage.removeItem('usuario_login');
            localStorage.removeItem('password_login');
          }
        }
        return resp;
      })
    );
  }

  logout() {
    this.usuario = '';
    this.token = '';
    localStorage.clear();
    this.usuarioService.removeStorage();
  }

  // FUNCIONES PARA MANEJO DE DATOS EN EL STORAGE
  cargarStorage() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token') || '';
      this.usuario = localStorage.getItem('usuario') || '';
      // this.usuario = JSON.parse( localStorage.getItem('usuario') );
    } else {
      this.token = '';
      this.usuario = '';
    }
  }

  getToken() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token') || '';
      return this.token;
    }
    return null;
  }

  private setStorage(token: string, usuario: string, roles: string[]) {
    localStorage.setItem('token', token);
    localStorage.setItem('usuario', usuario);
    localStorage.setItem('roles', JSON.stringify(roles));
  }

}
