import { InfraccionLaboral } from './infraccion-laboral.model';
import { DetalleEmpresaHeader } from './detalle-empresa-header.model';

export class DetalleEmpresa{

    empresa: DetalleEmpresaHeader;
    infraccionesLaborales: InfraccionLaboral[];
    
    constructor(){}

}

