import { Component, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UtilsService } from '../../../services/util/utils.service';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit, OnChanges {

  static activeDropdownInstance: SelectionComponent | null = null;

  @ViewChild('searchInput') searchInput!: ElementRef;

  @Input() inputPlaceholder: string = 'Seleccione una opción';
  @Input() inputOptions: any[] = [];
  @Input() inputDisplayField: string = '';
  @Input() inputValueField: string = '';

  @Output() outputOptionSelected = new EventEmitter<any>();
  @Output() outputOptionAdded = new EventEmitter<any>();

  public filteredOptions: any[] = [];
  public inputValue: string = '';
  public selectedOption: any | null = null;
  public showDropdown: boolean = false;
  public isLoading: boolean = false;


  constructor(
    private readonly utilsService: UtilsService,
  ) { }

  public ngOnInit(): void {
    this.updateFilteredOptions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputOptions']) {
      this.updateFilteredOptions();
      if (this.selectedOption && !this.inputOptions.includes(this.selectedOption)) {
        this.selectedOption = null;
      }
    }
  }

  private updateFilteredOptions(): void {
    this.filteredOptions = [...(this.inputOptions || [])];
  }

  @HostListener('document:click', ['$event'])
  private onDocumentClick(event: MouseEvent) {
    const element = event.target as HTMLElement;
    const isInsideComponent = element.closest('.select-add-option');
    if (!isInsideComponent) {
      this.showDropdown = false;
    }
  }

  public toggleDropdown(): void {
    if (SelectionComponent.activeDropdownInstance && SelectionComponent.activeDropdownInstance !== this) {
      SelectionComponent.activeDropdownInstance.showDropdown = false;
    }
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown) {
      SelectionComponent.activeDropdownInstance = this;
      this.searchInput.nativeElement.focus();
    } else {
      SelectionComponent.activeDropdownInstance = null;
    }
  }

  public onInputChange(): void {
    if (this.inputValue.trim()) {
      this.filteredOptions = (this.inputOptions || []).filter(option => {
        const displayValue = String(option[this.inputDisplayField] || '');
        return displayValue.toLowerCase().includes(this.inputValue.toLowerCase());
      });
    } else {
      this.updateFilteredOptions();
    }
  }

  public selectOption(option: any): void {
    this.selectedOption = option;
    this.inputValue = '';
    this.showDropdown = false;
    SelectionComponent.activeDropdownInstance = null;
    this.updateFilteredOptions();
    this.outputOptionSelected.emit(option);
  }

  public addNewOption(): void {
    if (this.inputValue.trim()) {
      const newOptionValue = this.inputValue.trim();

      const existingOption = (this.inputOptions || []).find(option => {
        const displayValue = String(option[this.inputDisplayField] || '');
        return displayValue.toLowerCase() === newOptionValue.toLowerCase();
      });

      if (existingOption) {
        this.selectOption(existingOption);
        return;
      }

      const newOption: any = {
        [this.inputValueField]: this.utilsService.cleanAndFormatString(newOptionValue),
        [this.inputDisplayField]: `${newOptionValue} (Datos flexibles)`
      };

      this.outputOptionAdded.emit(newOption);
      this.selectOption(newOption);
    }
  }

  public onEnterPress(): void {
    if (this.inputValue.trim()) {
      if (this.filteredOptions.length === 1) {
        this.selectOption(this.filteredOptions[0]);
      } else if (this.filteredOptions.length === 0) {
        this.addNewOption();
      }
    }
  }

  public getDisplayValue(option: any): string {
    if (!option) return '';
    return String(option[this.inputDisplayField] || '');
  }
}
