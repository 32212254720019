import { Directive, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Directive()
export abstract class AbstractForm implements OnInit {
  public form: FormGroup;
  public submitAttempt: boolean;

  protected constructor(
    private readonly formBuilder: FormBuilder
  ) { }

  public ngOnInit() {
    this.initForm();
  }

  protected abstract initForm(): void;

  public get superFormBuilder() {
    return this.formBuilder;
  }

  public isFieldRequired(field: string) {
    return (this.form.get(field)?.hasError('required') && this.submitAttempt);
  }

  public isFieldPattern(field: string) {
    return (this.form.get(field)?.hasError('pattern') && this.submitAttempt);
  }

  public isFieldMinLength(field: string) {
    return (this.form.get(field)?.hasError('minlength') && this.submitAttempt);
  }

  public isFieldMaxLength(field: string) {
    return (this.form.get(field)?.hasError('maxlength') && this.submitAttempt);
  }

  public isFieldArrayRequired(formArray: FormArray, index: number, field: string): boolean | undefined {
    return (formArray.controls[index].get(field)?.hasError('required') && this.submitAttempt);
  }

}
