import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareSocialMediaService {

  constructor() { }

  shareFacebook(link) {
    let url = 'http://www.facebook.com/sharer.php?u='+ link;
       let newwindow=window.open(url,'name','height=500,width=520,top=200,left=300,resizable');
  if (window.focus) {
      newwindow.focus()
  } 
  }

  shareTwitter(link){
    let url = 'https://twitter.com/intent/tweet?text='+ link;
    let newwindow=window.open(url,'name','height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  shareLinkedin(link){
    let url ='https://www.linkedin.com/shareArticle?mini=true&url='+ link;
    let newwindow=window.open(url,'name','height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
        newwindow.focus()
    }
  }


}
