import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

const base_url = environment.base_url + '/newsletter';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor( private http: HttpClient ) { }

  suscribirse( email: string, nombres: string ){
    return this.http.post(`${ base_url }/suscribirse`, {'email':email,'nombres':nombres})
    .pipe(
      map ( (resp:any) => {
        return resp;
      })
    );
  }

  confirmarCorreo( email: string ){
    return this.http.post(`${ base_url }/confirmar-correo`, {'email':email})
    .pipe(
      map ( (resp:any) => {
        return resp;
      })
    );
  }

}
