import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages/pages.routing';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: (): Promise<any> =>
      import('./modules/authentication/authentication.module').then(
        (module) => module.AuthenticationModule
      )
  },
  {
    path: 'main',
    loadChildren: (): Promise<any> =>
      import('./modules/main/main.module').then(
        (module) => module.MainModule
      )
  },
  {
    path: 'administracion',
    canActivate: [AuthGuard],
    loadChildren: (): Promise<any> =>
      import('./modules/content-management/content-management.module').then(
        (module) => module.ContentManagementModule
      )
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  // { path: '**', component: NopagefoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    PagesRoutingModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
