import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Mapboxgl from 'mapbox-gl';
import * as uuid from 'uuid';
import { DataEmpresaService } from './data-empresa.service';

@Injectable({
  providedIn: 'root'
})
export class MapDataService {

  mapThemes = {
    LIGHT_THEME: "mapbox://styles/mapbox/light-v10",
    STREETS_THEMEstreets: "mapbox://styles/mapbox/streets-v11",
    DARK_THEME: "mapbox://styles/mapbox/dark-v10"
  }

  constructor( public dataEmpresaService: DataEmpresaService) {
    Mapboxgl.accessToken = environment.mapboxKey;
  }

  // =============== PARA MAPA ESTÁNDAR ===============

  standar_initNewMap(options: any) {

    return new Mapboxgl.Map({
      container: options.htmlcontainer,
      style: options.theme,
      center: options.initialCoords,
      zoom: options.initialZoom,
      // maxZoom: options.maxZoom,
      // minZoom: options.minZoom,
      scrollZoom: options.scrollZoom,
      doubleClickZoom: options.doubleClickZoom
    });

  }

  standar_mapWithLinesHover(mapa: Mapboxgl.Map, options: any) {

    mapa.addSource('states', {
      'type': 'geojson',
      'data': options.mapaSrc
    });

    mapa.addLayer({
      'id': 'state-fills',
      'type': 'fill',
      'source': 'states',
      'layout': {},
      'paint': {
        // 'fill-color': '#627BC1',
        'fill-color': options.fillColor,
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          options.fillColorOpacity,
          options.fillColorOpacityHover
        ]
      }
    });

    mapa.addLayer({
      'id': 'state-borders',
      'type': 'line',
      'source': 'states',
      'layout': {},
      'paint': {
        'line-color': options.lineColor,
        'line-width': options.lineWidth
      }
    });

    let hoveredStateId = null;

    // Add events hover
    // mapa.on('mousemove', 'state-fills', (e) => {
    //   // mapa.getCanvas().style.cursor = 'pointer';
    //   if (e.features.length > 0) {
    //     if (hoveredStateId) {
    //       setFeatureState(hoveredStateId, false);
    //     }
    //     //hoveredStateId = e.features[0].properties.FIRST_IDDP;
    //     hoveredStateId = e.features[0].id;
    //     setFeatureState(hoveredStateId, true);
    //   }
    // });

    // mapa.on('mouseleave', 'state-fills', () => {
    //   mapa.getCanvas().style.cursor = '';
    //   if (hoveredStateId) {
    //     setFeatureState(hoveredStateId, false);
    //   }
    //   hoveredStateId = null;
    // });

    // mapa.on('click', 'state-fills', (e) => {
    //   // console.log('dpto-sel',e.features[0].id + '-' + e.features[0].properties.NOMBDEP);
    // });

    function setFeatureState(hoveredStateId, hover) {
      mapa.setFeatureState(
        { source: 'states', id: hoveredStateId },
        { hover: hover }
      );
    }

    // this.mapa.on('click', (e) => {
    //   console.log('ex',e.point.x);
    //   console.log('ex',e);
    //   console.log('ey',e.point.y);
    //   var bbox = [
    //     [e.point.x - 5, e.point.y - 5],
    //     [e.point.x + 5, e.point.y + 5]
    //   ];
    //   console.log('bbox', bbox);
    // });
    
  }

  standar_createMarker(mapa: Mapboxgl.Map, options: any, coords: any) {

    var size = coords.size;
    // var size = options.markerSize;
    var pointId = 'point-' + coords.lng.toString();
    var imgId = 'pulsing-dot-' + coords.lng.toString();

    var pointColor = coords.color;

    var pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd: function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // called once before every frame where the icon will be used
      render: function () {
        var duration = 2000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.3 * t + radius;
        var context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(' + pointColor + ',' + (1 - t) + ')';
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(' + pointColor + ', ' + options.markerColorOpacity + ')';
        context.strokeStyle = 'rgba(' + pointColor + ', ' + options.markerStrokeColorOpacity + ')';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        mapa.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    }

    mapa.addImage(imgId, pulsingDot, { pixelRatio: 2 });

    mapa.addSource(pointId, {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [coords.lng, coords.lat]
            },
            'properties': {
              'description': coords.description
            }
          },
        ]
      }
    });

    mapa.addLayer({
      'id': pointId,
      'type': 'symbol',
      'source': pointId,
      'layout': {
        'symbol-z-order': 'source',
        'icon-image': imgId,
        "icon-allow-overlap": false,
        "text-allow-overlap": false
      },
    });

    // Create a popup, but don't add it to the map yet.
    var popup = new Mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    mapa.on('mouseenter', pointId, (e) => {
      // Change the cursor style as a UI indicator.
      mapa.getCanvas().style.cursor = 'pointer';

      var coordinates = e.features[0].geometry.coordinates.slice();
      var description = e.features[0].properties.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapa);
    });

    mapa.on('mouseleave', pointId, () => {
      mapa.getCanvas().style.cursor = '';
      popup.remove();
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    // mapa.on('mouseenter', pointId, function() {
    //   mapa.getCanvas().style.cursor = 'pointer';
    // });

    // // Change it back to a pointer when it leaves.
    // mapa.on('mouseleave', pointId, function() {
    //   mapa.getCanvas().style.cursor = '';
    //   popup.remove();
    // });

  }

  standar_createMarkerNew(mapa: Mapboxgl.Map, options: any, coords: any) {

    var size = 120;
    // var size = coords.size;
    // var size = options.markerSize;
    // var pointId = 'point-'+ coords.lng.toString();
    // var imgId = 'pulsing-dot-'+coords.lng.toString();
    // var pointId = 'point-'+ coords.Longitud.toString() + Math.floor(Math.random() * 1000) + 1;
    // var imgId = 'pulsing-dot-'+coords.Longitud.toString() + Math.floor(Math.random() * 1000) + 1;
    // var pointId = 'point-'+ coords.Longitud.toString() + uuid.v4();
    // var imgId = 'pulsing-dot-'+coords.Longitud.toString() + uuid.v4();
    var pointId = 'point-' + uuid.v4();
    var imgId = 'pulsing-dot-' + uuid.v4();

    // console.log('point',pointId);

    var pointColor = '248,63,60';

    // const pointsColors: any = {
    //   //blue: '0,187,207',
    //   // blue: '238,24,96',
    //   blue: '65, 82, 174',  //azulmorado
    //   //red: '238,24,96',  //rojo-2
    //   red: '248,63,60',  //naranja
    //   //green: '106, 58, 176' //morado
    //   green: '133,194,91' //verde-limon
    // }

    if (coords.TipoInfraccion === 'red') {
      pointColor = '248,63,60';
    }
    if (coords.TipoInfraccion === 'green') {
      pointColor = '133,194,91';
    }
    if (coords.TipoInfraccion === 'yellow') {
      pointColor = '65, 82, 174';
    }

    var pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd: function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // called once before every frame where the icon will be used
      render: function () {
        var duration = 2000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.3 * t + radius;
        var context = this.context;

        //draw outer circle
        // context.clearRect(0, 0, this.width, this.height);
        // context.beginPath();
        // context.arc(
        //   this.width / 2,
        //   this.height / 2,
        //   outerRadius,
        //   0,
        //   Math.PI * 2
        // );
        // context.fillStyle = 'rgba('+ pointColor +',' + (1 - t) + ')';
        // context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(' + pointColor + ', ' + options.markerColorOpacity + ')';
        context.strokeStyle = 'rgba(' + pointColor + ', ' + options.markerStrokeColorOpacity + ')';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        mapa.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    }

    mapa.addImage(imgId, pulsingDot, { pixelRatio: 2 });

    mapa.addSource(pointId, {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [coords.Longitud, coords.Latitud]
            },
            'properties': {
              'description': coords.NombreEmpresa
            }
          },
        ]
      }
    });

    mapa.addLayer({
      'id': pointId,
      'type': 'symbol',
      'source': pointId,
      'layout': {
        'symbol-z-order': 'source',
        'icon-image': imgId,
        "icon-allow-overlap": false,
        "text-allow-overlap": false
      },
    });

    // Create a popup, but don't add it to the map yet.
    var popup = new Mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    mapa.on('mouseenter', pointId, (e) => {
      // Change the cursor style as a UI indicator.
      mapa.getCanvas().style.cursor = 'pointer';

      var coordinates = e.features[0].geometry.coordinates.slice();
      var description = e.features[0].properties.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapa);
    });

    mapa.on('mouseleave', pointId, () => {
      mapa.getCanvas().style.cursor = '';
      popup.remove();
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    // mapa.on('mouseenter', pointId, function() {
    //   mapa.getCanvas().style.cursor = 'pointer';
    // });

    // // Change it back to a pointer when it leaves.
    // mapa.on('mouseleave', pointId, function() {
    //   mapa.getCanvas().style.cursor = '';
    //   popup.remove();
    // });

  }

  // setFeatureState(hoveredStateId, hover) {
  //   this.mapa.setFeatureState(
  //     { source: 'states', id: hoveredStateId },
  //     { hover: hover }
  //   );
  // }

  // =============== PARA MAPA CON CLUSTERS ===============

  cluster_initNewMapTest(options: any) {

    var map = new Mapboxgl.Map({
      container: options.htmlcontainer,
      style: options.theme,
      // style: 'mapbox://styles/mapbox/dark-v10',
      center: [-103.59179687498357, 40.66995747013945],
      zoom: options.initialZoom
      // zoom: 3
    });

    map.on('load', function () {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.addSource('earthquakes', {
        type: 'geojson',
        // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data:
          'https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson',
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'earthquakes',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
      });

    });

    // inspect a cluster on click
    map.on('click', 'clusters', function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource('earthquakes').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map.on('click', 'unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var mag = e.features[0].properties.mag;
      var tsunami;

      if (e.features[0].properties.tsunami === 1) {
        tsunami = 'yes';
      } else {
        tsunami = 'no';
      }

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new Mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          'magnitude: ' + mag + '<br>Was there a tsunami?: ' + tsunami
        )
        .addTo(map);
    });

    map.on('mouseenter', 'clusters', function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', function () {
      map.getCanvas().style.cursor = '';
    });

  }

  cluster_initNewMap(options: any) {
    return new Mapboxgl.Map({
      container: options.htmlcontainer,
      style: options.theme,
      center: options.initialCoords,
      zoom: options.initialZoom,
      maxZoom: options.maxZoom,
      minZoom: options.minZoom,
      scrollZoom: options.scrollZoom,
      doubleClickZoom: options.doubleClickZoom
    });
  }

  cluster_loadMap(map: Mapboxgl.Map, coords:any, layerName: string, colorCircleAcc: string, colorTextCircleAcc: string){

    var sourceName = `infracciones-${layerName}`;
    var layerClusterId = `clusters-${layerName}`;
    var layerClusterCountId = `cluster-count-${layerName}`;
    var layerUnclusterId = `unclustered-point-${layerName}`;
    
    map.addSource(sourceName, {
      type: 'geojson',
      data:coords,
      cluster: true,
      clusterMaxZoom: 8, // Max zoom to cluster points on
      clusterRadius: 87 // Radius of each cluster when clustering points (defaults to 50)
    });
    
    map.addLayer({
      id: layerClusterId,
      type: 'circle',
      source: sourceName,
      filter: ['has', 'point_count'],
      paint: {
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          colorCircleAcc,
          // '#43b1ff',
          // '#51bbd6',
          6,
          // '#fde662',
          colorCircleAcc,
          14,
          colorCircleAcc,
          // '#fb6363'
          // '#f98145'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          12, // 20px
          5, // 0 - 6 puntos
          15, // 30px
          10, // 10 - 15 puntos
          25 // 40px (> 15)
        ]
      }
    });

    map.addLayer({
      id: layerClusterCountId,
      type: 'symbol',
      source: sourceName,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': [
          'step',
          ['get', 'point_count'],
          11, // 20px
          5, // 0 - 6 puntos
          13, // 30px
          10, // 10 - 15 puntos
          15 // 40px (> 15)
        ]
      },
      paint: {
        "text-color": colorTextCircleAcc
      }
    });

    map.addLayer({
      id: layerUnclusterId,
      type: 'circle',
      source: sourceName,
      filter: ['!', ['has', 'point_count']],
      paint: {
          // 'circle-color': '#11b4da',
          'circle-color': [
          "case",
            ["==", ['get', 'indicador'], 'laboral'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'ambiental'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'accidenteLaboral'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Pasivos'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Proyectos'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Accidentes'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Derrames'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Permisos de agua'], colorCircleAcc,
            "black"
          ],
          'circle-radius': 5,
          'circle-stroke-width': 1,
          // 'circle-stroke-color': '#ffffff',
          'circle-stroke-color': [
            "case",
              ["==", ['get', 'indicador'], 'laboral'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'ambiental'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'accidenteLaboral'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'Pasivos'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'Proyectos'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'Accidentes'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'Derrames'], colorCircleAcc,
              ["==", ['get', 'indicador'], 'Permisos de agua'], colorCircleAcc,
              "black"
            ]
      }
    });

    //ADD EVENTS

    // inspect a cluster on click
    map.on('click', layerClusterId, function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: [layerClusterId]
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource(sourceName).getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    map.on('click', layerUnclusterId, (e:any) => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var totalCoordinates = e.features[0].geometry.coordinates;
      var indicadorservicio = e.features[0].properties.indicadorservicio;
      var indicador = e.features[0].properties.indicador;
      var infraccionid = e.features[0].properties.infraccionid;
      
      var indicadorColor = colorCircleAcc;
      var empresa = '';
      var sectorEconomico = '';
      var agno = '';

      let obj = {
        'indicadorColor':indicadorColor,
        'indicador':indicador,
        'coordinates':coordinates,
        'empresa':empresa,
        'sectorEconomico':sectorEconomico,
        'agno':agno,
        'totalCoordinates':totalCoordinates
      }

      // SERVICIO DE MAPA
      if(indicadorservicio === 1){
        this.dataEmpresaService.getInfoForMapById(infraccionid).subscribe(
          resp => {
                if(resp?.success){
                  obj.empresa = resp.empresa?.NombreEmpresa ? resp.empresa?.NombreEmpresa : '';
                  obj.sectorEconomico = resp.empresa?.SectorEconomico ? resp.empresa?.SectorEconomico : '';
                  obj.agno = resp.empresa?.Agno ? resp.empresa?.Agno : '';
                  showModalHTML(map,e,obj);
                }
              }
        );
      }else{
        // SERVICIO DE MAPA INFORMACION ADICIONAL
        this.dataEmpresaService.getInfoAdicionalForMapById(infraccionid).subscribe(
          resp => {
                if(resp?.success){
                  obj.empresa = resp.empresa.NombreEmpresa;
                  obj.sectorEconomico = resp.empresa.SectorEconomico;
                  obj.agno = resp.empresa.Agno;
                  showModalHTML(map,e,obj);
                }
              }
        );
      }
      
      // var empresa = e.features[0].properties.empresa;
      // var sectorEconomico = e.features[0].properties.sectorEconomico;
      // var agno = e.features[0].properties.agno;      
      // console.log('indicadorColor',indicadorColor);

      
    });

    let showModalHTML= (map:any , e:any, obj:any) => {
      
      var indicadorDescripcion = '';

      switch(obj.indicador){
        case 'laboral':
          indicadorDescripcion = 'Infracción laboral';
          break;
        case 'accidenteLaboral':
          indicadorDescripcion = 'Accidente laboral';
          break;
        case 'ambiental':
          indicadorDescripcion = 'Infracción ambiental';
          break;
        case 'Pasivos':
        indicadorDescripcion = 'Pasivos';
          break;
        case 'Proyectos':
        indicadorDescripcion = 'Proyectos';
          break;
        case 'Accidentes':
        indicadorDescripcion = 'Accidentes';
          break;
        case 'Derrames':
        indicadorDescripcion = 'Derrames';
          break;
        case 'Permisos de agua':
        indicadorDescripcion = 'Permisos de agua';
          break;
        default:
          indicadorDescripcion = ''
          // indicadorColor = '#0000';
      }

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - obj.coordinates[0]) > 180) {
        obj.coordinates[0] += e.lngLat.lng > obj.coordinates[0] ? 360 : -360;
      }

      new Mapboxgl.Popup()
        .setLngLat(obj.coordinates)
        .setHTML(
          '<b style="color:'+ obj.indicadorColor +'">'+ indicadorDescripcion+'</b>' +'<br>'+
          '<b style="color: #000000">'+obj.empresa+'</b>' +'<br>'+
          'Sector económico: <b>' + obj.sectorEconomico + '</b>' +'<br>'+
          'Año: ' + obj.agno +'<br>'
          // 'magnitude: ' + mag + '<br>Was there a tsunami?: ' + tsunami
        ).addTo(map);

      var e2 = obj.totalCoordinates;

      map.easeTo({
        center: e2,
        //zoom: 9
      });

    }

    // map.on('click', layerUnclusterId, function (e) {
    //   var coordinates = e.features[0].geometry.coordinates.slice();
      
    //   var empresa = e.features[0].properties.empresa;
    //   var sectorEconomico = e.features[0].properties.sectorEconomico;
    //   var agno = e.features[0].properties.agno;
    //   var indicador = e.features[0].properties.indicador;
    //   var indicadorDescripcion = '';
    //   var indicadorColor = colorCircleAcc;
    //   // console.log('indicadorColor',indicadorColor);

    //   switch(indicador){
    //     case 'laboral':
    //       indicadorDescripcion = 'Infracción laboral';
    //       break;
    //     case 'accidenteLaboral':
    //       indicadorDescripcion = 'Accidente laboral';
    //       break;
    //     case 'ambiental':
    //       indicadorDescripcion = 'Infracción ambiental';
    //       break;
    //     case 'Pasivos':
    //     indicadorDescripcion = 'Pasivos';
    //       break;
    //     case 'Proyectos':
    //     indicadorDescripcion = 'Proyectos';
    //       break;
    //     case 'Accidentes':
    //     indicadorDescripcion = 'Accidentes';
    //       break;
    //     case 'Derrames':
    //     indicadorDescripcion = 'Derrames';
    //       break;
    //     case 'Permisos de agua':
    //     indicadorDescripcion = 'Permisos de agua';
    //       break;
    //     default:
    //       indicadorDescripcion = ''
    //       // indicadorColor = '#0000';
    //   }

    //   // Ensure that if the map is zoomed out such that
    //   // multiple copies of the feature are visible, the
    //   // popup appears over the copy being pointed to.
    //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    //   }

    //   new Mapboxgl.Popup()
    //     .setLngLat(coordinates)
    //     .setHTML(
    //       '<b style="color:'+ indicadorColor +'">'+indicadorDescripcion+'</b>' +'<br>'+
    //       '<b style="color: #000000">'+empresa+'</b>' +'<br>'+
    //       'Sector económico: <b>' + sectorEconomico + '</b>' +'<br>'+
    //       'Año: ' + agno +'<br>'
    //       // 'magnitude: ' + mag + '<br>Was there a tsunami?: ' + tsunami
    //     ).addTo(map);

    //   map.easeTo({
    //     center: e.features[0].geometry.coordinates,
    //     //zoom: 9
    //   });
    // });
    

    map.on('mouseenter', layerClusterId, function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layerClusterId, function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', layerUnclusterId, function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layerUnclusterId, function () {
      map.getCanvas().style.cursor = '';
    });

  }

  cluster_loadMapNotCluster(map: Mapboxgl.Map, coords:any, layerName: string, colorCircleAcc: string, colorTextCircleAcc: string, coordenadasFit:any = null){

    var sourceName = `infracciones-${layerName}`;
    var layerClusterId = `clusters-${layerName}`;
    var layerClusterCountId = `cluster-count-${layerName}`;
    var layerUnclusterId = `unclustered-point-${layerName}`;
    
    map.addSource(sourceName, {
      type: 'geojson',
      data:coords,
      cluster: true,
      clusterMaxZoom: 0, // Max zoom to cluster points on
      clusterRadius: 0 // Radius of each cluster when clustering points (defaults to 50)
    });
    
    map.addLayer({
      id: layerUnclusterId,
      type: 'circle',
      source: sourceName,
      filter: ['!', ['has', 'point_count']],
      paint: {
          // 'circle-color': '#11b4da',
          'circle-color': [
          "case",
            ["==", ['get', 'indicador'], 'laboral'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'ambiental'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'accidenteLaboral'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Pasivos'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Proyectos'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Accidentes'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Derrames'], colorCircleAcc,
            ["==", ['get', 'indicador'], 'Permisos de agua'], colorCircleAcc,
            "black"
          ],
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#767676',
          // 'circle-stroke-color': [
          //   "case",
          //     ["==", ['get', 'indicador'], 'laboral'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'ambiental'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'accidenteLaboral'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'Pasivos'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'Proyectos'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'Accidentes'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'Derrames'], colorCircleAcc,
          //     ["==", ['get', 'indicador'], 'Permisos de agua'], colorCircleAcc,
          //     "black"
          //   ]
      }
    });

    //ADD EVENTS

    // inspect a cluster on click
    map.on('click', layerClusterId, function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: [layerClusterId]
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource(sourceName).getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    map.on('click', layerUnclusterId, (e:any) => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var totalCoordinates = e.features[0].geometry.coordinates;
      var indicadorservicio = e.features[0].properties.indicadorservicio;
      var indicador = e.features[0].properties.indicador;
      var infraccionid = e.features[0].properties.infraccionid;
      
      var indicadorColor = colorCircleAcc;
      var empresa = '';
      var sectorEconomico = '';
      var agno = '';

      let obj = {
        'indicadorColor':indicadorColor,
        'indicador':indicador,
        'coordinates':coordinates,
        'empresa':empresa,
        'sectorEconomico':sectorEconomico,
        'agno':agno,
        'totalCoordinates':totalCoordinates
      }

      // map.easeTo({
      //   center: e.features[0].geometry.coordinates,
      //   zoom: 9
      // });

      // SERVICIO DE MAPA
      if(indicadorservicio === 1){
        this.dataEmpresaService.getInfoForMapById(infraccionid).subscribe(
          resp => {
                if(resp?.success){
                  obj.empresa = resp.empresa?.NombreEmpresa ? resp.empresa?.NombreEmpresa : '';
                  obj.sectorEconomico = resp.empresa?.SectorEconomico ? resp.empresa?.SectorEconomico : '';
                  obj.agno = resp.empresa?.Agno ? resp.empresa?.Agno : '';
                  showModalHTML(map,e,obj);
                }
              }
        );
      }else{
        // SERVICIO DE MAPA INFORMACION ADICIONAL
        this.dataEmpresaService.getInfoAdicionalForMapById(infraccionid).subscribe(
          resp => {
                if(resp?.success){
                  obj.empresa = resp.empresa.NombreEmpresa;
                  obj.sectorEconomico = resp.empresa.SectorEconomico;
                  obj.agno = resp.empresa.Agno;
                  showModalHTML(map,e,obj);
                }
              }
        );
      }
      
      // var empresa = e.features[0].properties.empresa;
      // var sectorEconomico = e.features[0].properties.sectorEconomico;
      // var agno = e.features[0].properties.agno;      
      // console.log('indicadorColor',indicadorColor);

      
    });

    let showModalHTML= (map:any , e:any, obj:any) => {
      
      var indicadorDescripcion = '';

      switch(obj.indicador){
        case 'laboral':
          indicadorDescripcion = 'Infracción laboral';
          break;
        case 'accidenteLaboral':
          indicadorDescripcion = 'Accidente laboral';
          break;
        case 'ambiental':
          indicadorDescripcion = 'Infracción ambiental';
          break;
        case 'Pasivos':
        indicadorDescripcion = 'Pasivos';
          break;
        case 'Proyectos':
        indicadorDescripcion = 'Proyectos';
          break;
        case 'Accidentes':
        indicadorDescripcion = 'Accidentes';
          break;
        case 'Derrames':
        indicadorDescripcion = 'Derrames';
          break;
        case 'Permisos de agua':
        indicadorDescripcion = 'Permisos de agua';
          break;
        default:
          indicadorDescripcion = ''
          // indicadorColor = '#0000';
      }

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - obj.coordinates[0]) > 180) {
        obj.coordinates[0] += e.lngLat.lng > obj.coordinates[0] ? 360 : -360;
      }

      new Mapboxgl.Popup()
        .setLngLat(obj.coordinates)
        .setHTML(
          '<b style="color:'+ obj.indicadorColor +'">'+ indicadorDescripcion+'</b>' +'<br>'+
          '<b style="color: #000000">'+obj.empresa+'</b>' +'<br>'+
          'Sector económico: <b>' + obj.sectorEconomico + '</b>' +'<br>'+
          'Año: ' + obj.agno +'<br>'
          // 'magnitude: ' + mag + '<br>Was there a tsunami?: ' + tsunami
        ).addTo(map);

      var e2 = obj.totalCoordinates;

      map.easeTo({
        center: e2,
        //zoom: 9
      });

    }

    // console.log('NO hey');

    // ADD ZOOM AUTOMATIC
    // if(coordenadasFit){

    //   console.log('hey');

    //   let bbox = this.getPerimeterToFit(coordenadasFit);

    //   map.fitBounds(bbox,{padding: 100});

    //   // map.fitBounds(bbox, {
    //   //   padding: {top: 10, bottom:25, left: 15, right: 5}
    //   //   });
      
    // }

    map.on('mouseenter', layerClusterId, function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layerClusterId, function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', layerUnclusterId, function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layerUnclusterId, function () {
      map.getCanvas().style.cursor = '';
    });

  }

  getPerimeterToFit(coordenadasFit){

    let w, s, e, n;

    // Calculate the bounding box with a simple min, max of all latitudes and longitudes
    coordenadasFit.forEach((point) => {
      if (w === undefined) {
          n = s = point[0].lat;
          w = e = point[0].lng;
      }

      if (point[0].lat > n) {
          n = point[0].lat;
      } else if (point[0].lat < s) {
          s = point[0].lat;
      }
      if (point[0].lng > e) {
          e = point[0].lng;
      } else if (point[0].lng < w) {
          w = point[0].lng;
      }
    });

    return [
      [w, s],
      [e, n]
    ]

  }

  cluster_loadMapOld(map: Mapboxgl.Map, options: any, coords:any){

    this.standar_mapWithLinesHover(map, options);

    
    map.addSource('infracciones', {
      type: 'geojson',
      data:coords,
      cluster: true,
      clusterMaxZoom: 8, // Max zoom to cluster points on
      clusterRadius: 55 // Radius of each cluster when clustering points (defaults to 50)
    });
    
    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'infracciones',
      filter: ['has', 'point_count'],
      paint: {
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#43b1ff',
          // '#51bbd6',
          6,
          '#fde662',
          14,
          '#fb6363'
          // '#f98145'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20, // 20px
          6, // 0 - 6 puntos
          30, // 30px
          14, // 10 - 15 puntos
          40 // 40px (> 15)
        ]
      }
    });

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'infracciones',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 16
      },
      paint: {
        "text-color": "#383838"
      }
    });


    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'infracciones',
      filter: ['!', ['has', 'point_count']],
      paint: {
          // 'circle-color': '#11b4da',
          'circle-color': [
          "case",
            ["==", ['get', 'indicador'], 'red'], '#f83f3c',
            ["==", ['get', 'indicador'], 'yellow'], '#4152ae',
            ["==", ['get', 'indicador'], 'green'], '#85c25b',
            "black"
          ],
          'circle-radius': 8,
          'circle-stroke-width': 1,
          // 'circle-stroke-color': '#ffffff',
          'circle-stroke-color': [
            "case",
              ["==", ['get', 'indicador'], 'red'], '#f83f3c',
              ["==", ['get', 'indicador'], 'yellow'], '#4152ae',
              ["==", ['get', 'indicador'], 'green'], '#85c25b',
              "black"
            ]
      }
    });

    //ADD EVENTS

    // inspect a cluster on click
    map.on('click', 'clusters', function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource('infracciones').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    map.on('click', 'unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      
      var empresa = e.features[0].properties.empresa;
      var sectorEconomico = e.features[0].properties.sectorEconomico;
      var agno = e.features[0].properties.agno;
      var indicador = e.features[0].properties.indicador;
      var indicadorDescripcion = '';
      var indicadorColor = '';

      switch(indicador){
        case 'red':
          indicadorDescripcion = 'Accidente laboral';
          indicadorColor = '#f83f3c';
          break;
        case 'green':
          indicadorDescripcion = 'Infracción ambiental';
          indicadorColor = '#85c25b';
          break;
        case 'yellow':
          indicadorDescripcion = 'Infracción laboral';
          indicadorColor = '#4152ae';
          break;
        default:
          indicadorDescripcion = ''
          indicadorColor = '#0000';
      }

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new Mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          '<b style="color:'+ indicadorColor +'">'+indicadorDescripcion+'</b>' +'<br>'+
          '<b style="color: #000000">'+empresa+'</b>' +'<br>'+
          'Sector económico: <b>' + sectorEconomico + '</b>' +'<br>'+
          'Año: ' + agno +'<br>'
          // 'magnitude: ' + mag + '<br>Was there a tsunami?: ' + tsunami
        ).addTo(map);

      map.easeTo({
        center: e.features[0].geometry.coordinates,
        //zoom: 9
      });
    });

    map.on('mouseenter', 'clusters', function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('mouseenter', 'unclustered-point', function () {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'unclustered-point', function () {
      map.getCanvas().style.cursor = '';
    });

  }
  
  formatData(points, name, indicadorservicio){

    let myGeoJSON: any = {};
    myGeoJSON.type = "FeatureCollection";

    myGeoJSON.crs = {};
    myGeoJSON.crs.type = name;
    // myGeoJSON.crs.properties = { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" };
  
    myGeoJSON.features = [];

    for (let i = 0; i < points.length; i++) {
      
      let geojson: any = {};
      geojson.type = "Feature";
      geojson.properties = {};
      geojson.geometry = {};

      let id = "pt-" + uuid.v4();
      geojson.properties.id = id;
      geojson.properties.mag = 8;
      geojson.properties.time = 1507425650893;
      geojson.properties.felt = null;
      geojson.properties.tsunami = 0;
      // geojson.properties.empresa = points[i].NombreEmpresa;
      // geojson.properties.sectorEconomico = points[i].SectorEconomico;
      // geojson.properties.agno = points[i].Agno;
      
      geojson.properties.infraccionid = points[i].infraccionid ? points[i].infraccionid : points[i].informacionid;
      geojson.properties.indicador = points[i].TipoInfraccion ? points[i].TipoInfraccion : points[i].Tipo ;
      geojson.properties.indicadorservicio = indicadorservicio;
      
      geojson.geometry.type = "Point";
      geojson.geometry.coordinates = [ points[i].Longitud, points[i].Latitud ,0];

      myGeoJSON.features.push(geojson);
      // mapa.setFeatureState({ id: id, source: "earthquakes" }, { indicador: points[i].TipoInfraccion });
    }
    
    return myGeoJSON;
  }
  
}
