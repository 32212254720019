import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

const base_url = environment.base_url + '/pagos';
@Injectable({
  providedIn: 'root'
})
export class PagosService {

  constructor( private http: HttpClient,
              ) { }

  getAutorizacionPago(idMembresia: number){
    
    return this.http.get(`${ base_url }/autorizar/${idMembresia}`).pipe(
      tap(
        (resp:any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            //this.manejarError(error);
            return throwError(error);
        }}
      )
    );
  }

}
