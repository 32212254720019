<div class="container-fluid nosotros">
  <div class="row nosotros-hero">
    <div class="col d-block align-self-center nosotros-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white">Nosotros</h2>
        <p class="text-center text-white">Inicio / Nosotros</p>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <img src="../../../assets/buttons/arrow-custom.png" alt="" class="sm-width-image arrow-hero">
      </div>
    </div>
  </div>
  </div>
  <div class="container nosotros">
  <br>
  <div class="row mb-1">
    <div class="col-sm col-sm-12 col-md-12 mt-4 ml-2 mb-2">
      <h2>Sé parte de Convoca Deep Data</h2>
    </div>
    <div class="col-sm-12 p-4 pb-1">
      <p class="text-justify">
        Convoca Deep Data es el resultado de más de 5 años de trabajo periodístico de Convoca.pe, basado en el procesamiento y
        análisis de bases de datos para generar contenidos de impacto público. Con toda esta experiencia y con un equipo de
        periodistas, científicos de datos y programadores, hemos realizado el trabajo duro de los datos (limpieza, organización
        y análisis) para ponerlos al servicio de la sociedad a través de esta plataforma.
      </p>
      <p class="text-justify">
        En esta primera etapa nos hemos concentrado en el procesamiento de más de 2.4 millones de datos sobre las compañías más
        importantes de las industrias extractivas en el Perú tomando en cuenta que la actividad minera y de hidrocarburos
        cumplen un papel predominante en la economía del país, generan un gran impacto en la vida de las poblaciones de los
        Andes y la Amazonía, y ejercen influencia en los tomadores de decisiones. Saber quién es quién en estas actividades
        productivas es conocer cómo funciona el poder.
      </p>
      <p class="text-justify">
        Convoca.pe tiene un amplio conocimiento sobre las actividades extractivas y su impacto público debido a las diversas
        series investigativas que ha desarrollado y que han sido premiadas internacionalmente como 'Excesos sin castigo', que en
        2016 ganó el Data Journalism Awards 2016, el premio global de periodismo de datos más importante a escala mundial, y fue
        uno de los tres finalistas del Premio Iberoamericano Gabriel García Márquez 2016 en la categoría 'Innovación'. En Perú,
        este proyecto también fue elegido como finalista al Gran Premio Nacional de Periodismo.
      </p>
      <p class="text-justify">
        El desarrollo de Convoca Deep Data a lo largo de seis meses ha significado un gran desafío debido al estado caótico en
        el que encontramos la información generada por diversas fuentes públicas pero consideramos que el esfuerzo valía la pena
        para contribuir a la generación de conocimiento de interés público. El nombre de esta plataforma responde precisamente a
        la importancia de trabajar en la calidad de los datos y por ello podrás encontrar en esta poderosa herramienta
        información limpia, refinada, estructurada, y gracias a nuestra funcionalidad de cruce de datos, podrás subir tu propia
        data y cruzarla con la información de la plataforma. Además, te enviaremos notificaciones personalizadas sobre las
        nuevas actualizaciones de la información y podrás visualizar la información en mapas interactivos.
      </p>
      <p class="text-justify bold">
        Te invitamos a ser parte de nuestra comunidad para
        que Convoca.pe continúe haciendo periodismo de investigación de alto
        impacto público.
      </p>
      <p class="text-justify bold">
        Buscamos ser sostenibles con tu suscripción a esta plataforma y nuestro compromiso con la independencia editorial y la búsqueda de la verdad.
      </p>
    </div>
  </div>
  <div class="col-12 text-center">
    <h3 class="text-danger">
      ¡Únete a Convoca Deep Data!
    </h3>
    <br>
    <h3>¿Por qué elegir nuestra plataforma?</h3>
  </div>
  <br>

  <div class="d-lg-none col-sm-12 col-md-12 mb-5">
    <div class="row">
      <div class="col d-block justify-content-center align-items-center">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid justify-content-center align-items-center"
          src="../../../assets/icons/conocimiento.png" alt="">
        </div>
        <h6 class="text-center text-danger">Alto conocimiento</h6>
      </div>
      <div class="col d-block ">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid justify-content-center align-items-center"
          src="../../../assets/icons/precision.png" alt="">
        </div>
        <h6 class="text-center text-danger">Precisión</h6>
      </div>
    </div>
    <div class="row">
      <div class="col d-block ">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid" src="../../../assets/icons/rapidez.png" alt="">
        </div>
        <h6 class="text-center text-danger">Rapidéz</h6>
      </div>
      <div class="col d-block ">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid" src="../../../assets/icons/convergencia.png" alt="">
        </div>
        <h6 class="text-center text-danger">Convergencia</h6>
      </div>
    </div>
    <div class="row">
      <div class="col d-block ">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid" src="../../../assets/icons/experiencia.png" alt="">

        </div>
        <h6 class="text-center text-danger">Experiencia de Usuario</h6>
      </div>
      <div class="col d-block">
        <div class="d-flex align-items-center justify-content-center">
          <img class="img-fluid" src="../../../assets/icons/alerta.png" alt="">
        </div>
        <h6 class="text-center text-danger">Siempre Alertas</h6>
      </div>
    </div>
  </div>

  <!--Inner Plataforma Start-->
    <div class="container d-none d-lg-block">
        <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
            <br>
            <div class="col-xs-12">
                <div class="jpg-innerbannercontent">
                    <div class="row m-0 col-sm-12">
                        <div class="col-md-6 p-0">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/conocimiento.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left c-title">CONOCIMIENTO</h3>
                                    <p class="text-justify">La plataforma es una fuente de generación de conocimiento de interés público que se puede obtener a través del cruce de información y análisis de datos que están organizados aquí.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/precision.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left">PRECISIÓN</h3>
                                    <p class="text-justify">Accede a más de 2.4 millones de datos limpios y procesados con una metodología sólida diseñada por los profesionales de Convoca Deep Data y con información de diversas fuentes oficiales.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>

                    <div class="row m-0 col-sm-12">
                        <div class="col-md-6">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/rapidez.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left">AHORRO Y RAPIDEZ</h3>
                                    <p class="text-justify">Periodistas, investigadores, comunicadores y organizaciones, pueden ahorrar tiempo y recursos: todo lo que querías saber de las industrias extractivas en un solo lugar.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/convergencia.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left">CONVERGENCIA</h3>
                                    <p class="text-justify">Procesamos los datos más relevantes de las industrias extractivas en Perú para que optimices tu análisis e investigación desde más de 30 fuentes de información.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>

                    <div class="row m-0 col-sm-12">
                        <div class="col-md-6">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/experiencia.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left">EXPERIENCIA DE USUARIO</h3>
                                    <p class="text-justify">Cruza información con tus propios datos, interactúa con las visualizaciones y explora por los diferentes filtros disponibles con total facilidad. La plataforma puede utilizarla cualquier persona que busque generar conocimiento. Es simple y potente.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row m-0 col-sm-12 p-0">
                                <div class="col-sm-4 p-0">
                                    <img src="../../../assets/icons/alerta.png" class="img-fluid" alt="...">
                                </div>
                                <div class="col-sm-8">
                                    <h3 class="text-left">ALERTA</h3>
                                    <p class="text-justify">Obtén información actualizada sobre las empresas más poderosas de las industrias extractivas, según nivel de cumplimiento de las normas ambientales y laborales, estructura empresarial, representantes, autorizaciones otorgadas por el Estado, proyectos, entre otros datos.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  <!--Inner Plataforma End-->

</div>
