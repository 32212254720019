import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationExtras } from '@angular/router';
import { IContentCarousel, IContentHome } from '../../interfaces';
import { CmsSeccionesService } from '../../services/cms-secciones.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public empresaSearch: string = '';
  public contentHome: IContentHome | null = null;

  public carouselReportajes: { data: IContentCarousel[], metadata: any } = {
    data: [],
    metadata: {
      id: 'reportajes',
      href: '#reportajes'
    }
  };

  constructor(
    public _usuarioService: UsuarioService,
    public _authService: AuthService,
    private readonly router: Router,
    private readonly cmsSeccionesService: CmsSeccionesService
  ) { }

  public ngOnInit(): void {
    this.getContentHome();
    this.getContentCarousel();
  }

  private getContentHome(): void {
    this.cmsSeccionesService.getContentHome().subscribe({
      next: (resp: any) => {
        if (resp.success) {
          this.contentHome = resp.response[0];
        }
      },
      error: (error) => {
        console.error('Error al obtener el contenido de inicio:', error);
      }
    });
  }

  private getContentCarousel(): void {
    this.cmsSeccionesService.getContentCarousel().subscribe({
      next: (resp: any) => {
        if (resp.success) {
          this.carouselReportajes.data = resp.response.data;
        }
      },
      error: (error) => {
        console.error('Error al obtener el contenido del carousel:', error);
      }
    });
  }

  public verIndustrias(): void {
    if (!this.empresaSearch) {
      return;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: { "search": this.empresaSearch },
      skipLocationChange: false
    };
    this.router.navigate(["/industrias-extractivas"], navigationExtras);
  }
}
