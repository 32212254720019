<div class="container-fluid reportajes">
  <div class="row reportajes-hero">
    <div class="col d-block align-self-center reportajes-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white">Elige nuestros planes</h2>
        <p class="text-center text-white">Inicio / Planes</p>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <img src="../../../assets/buttons/arrow-custom.png" alt="" class="sm-width-image arrow-hero" />
      </div>
    </div>
  </div>
</div>

<!-- NUEVOS PLANES -->
<div *ngIf="!showCarrito && membresiasAgrupadas" class="jpg-haslayout">
  <div class="container-fluid mt-2 jpg-cuenta">
    <div class="row justify-content-md-center">
      <div class="container col-xs-12 col-sm-12" style="color: #202b3f">
        <div class="row my-4">
          <!-- PLANS -->
          <div *ngFor="let membresia of membresiasAgrupadas"
            class="col-md-3 mb-4 d-flex d-md-block justify-content-center">
            <div class="card shadow border-0">
              <div class="card-header p-0 d-flex justify-content-between align-items-center text-white"
                [ngStyle]="{'background-color':membresia.color }">
                <span class="mx-3">{{membresia.nombre}}</span>
                <img [src]="membresia.link_imagen" alt="Ícono" class="img-fluid header-img-plan">
              </div>
              <div class="card-body">
                <!-- PRICES -->
                <div *ngIf="membresia.id===1" class="my-3">
                  <h2 class="card-title price-principal mb-0">
                    <span>
                      GRATIS / ${{ membresia.planes[0].precio }}
                    </span>
                  </h2>
                </div>
                <div *ngIf="membresia.id===4" class="my-3">
                  <div *ngIf="planPremiumShow === 1 && membresia.planes[0].precio !== '0.00'"
                    class="d-flex flex-column justify-content-center">
                    <h2 class="card-title price-principal mb-0">
                      <span>
                        ${{ membresia.planes[0].precio }} / {{ membresia.planes[0].nombre_plan.toUpperCase()}}
                      </span>
                    </h2>
                  </div>

                  <div *ngIf="planPremiumShow === 3 && membresia.planes[1].precio !== '0.00'"
                    class="d-flex flex-column justify-content-center mb-3">
                    <h2 class="card-title price-principal mb-0">
                      <span>
                        ${{ membresia.planes[2].precio }} / {{ membresia.planes[2].nombre_plan.toUpperCase() }}
                      </span>
                    </h2>
                    <h2 class="card-title mb-0 ml-4 text-center">
                      <span class="price-ahorro">Ahorra {{ membresia.planes[2].descuento }}%</span>
                    </h2>
                  </div>

                  <div *ngIf="planPremiumShow === 4 && membresia.planes[3].precio !== '0.00'"
                    class="d-flex flex-column justify-content-center mb-3">
                    <h2 class="card-title price-principal mb-0">
                      <span>
                        ${{ membresia.planes[3].precio }} / {{ membresia.planes[3].nombre_plan.toUpperCase() }}
                      </span>
                    </h2>
                    <h2 class="card-title mb-0 ml-4 text-center">
                      <span class="price-ahorro">Ahorra {{ membresia.planes[3].descuento }}%</span>
                    </h2>
                  </div>
                </div>
                <!-- BENEFITS -->
                <div class="row mb-3">
                  <div class="col-12">
                    <div class="plan-features" [innerHTML]="membresia.caracteristicas | safeHtml">
                    </div>
                  </div>
                </div>
                <!-- RADIOBUTTONS -->
                <div *ngIf="membresia.id===4"
                  class="form-check p-0 d-flex flex-column flex-lg-row justify-content-center my-3">
                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="radioPremium" id="radioPremium1" value="1"
                        checked (change)="changeMembresiaPremium(1)" />
                      <label class="custom-control-label" for="radioPremium1">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium1">
                      {{ membresia.planes[0].nombre_plan.toUpperCase() }}
                    </label>
                  </div>

                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="radioPremium" id="radioPremium2" value="2"
                        (change)="changeMembresiaPremium(3)" />
                      <label class="custom-control-label" for="radioPremium2">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium2">
                      {{ membresia.planes[2].nombre_plan.toUpperCase() }}
                    </label>
                  </div>

                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="radioPremium" id="radioPremium3" value="3"
                        (change)="changeMembresiaPremium(4)" />
                      <label class="custom-control-label" for="radioPremium3">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium3">
                      {{ membresia.planes[3].nombre_plan.toUpperCase() }}
                    </label>
                  </div>
                </div>

                <div class="text-center mb-3">
                  <a *ngIf="membresia.id === 1" class="btn btn-main text-white" role="button"
                    [routerLink]="['/industrias-extractivas']">ELEGIR</a>
                  <a *ngIf="membresia.id === 4" class="btn btn-main text-white" role="button"
                    (click)="seleccionarProducto(membresia.planes[this.planPremiumShow - 1])">ELEGIR</a>
                  <a *ngIf="membresia.id === 5 || membresia.id === 6" class="btn btn-main"
                    href="https://forms.gle/xfNvAygPWnXNusQ49" target="_blank">ELEGIR</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    <div class="card border-0 jpg-howitwork-hold jpg-bgwhite jpg-haslayout">
      <ul class="jpg-navarticletab jpg-navarticletabvtwo nav navbar-nav">
        <li class="nav-item">
          <a class="active" id="all-tab" data-toggle="tab" href="#tab01">Transferencia bancaria</a>
        </li>
        <li class="nav-item">
          <a id="trading-tab" data-toggle="tab" href="#tab03">Pago en línea</a>
        </li>
      </ul>
      <div class="tab-content jpg-haslayout">
        <div class="jpg-contentarticle tab-pane active fade show" id="tab01">
          <div class="row">
            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <h5>Estas son nuestras cuentas BCP:</h5>
                <p class="mb-0">Asociación Convoca</p>
                <p class="mb-0">Cuenta Corriente</p>
                <p class="mb-0">Dólares:</p>
                <p class="mb-0">
                  Cuenta corriente en dólares en el Banco de Crédito del Perú
                  (BCP): 193-2265763-1-03
                </p>
                <p class="mb-0">
                  Código de cuenta Interbancaria en dólares:
                  00219300226576310316
                </p>
                <p class="mb-0">Soles:</p>
                <p class="mb-0">
                  Cuenta corriente en soles del Banco de Crédito del Perú (BCP):
                  193-2580605-0-15
                </p>
                <p class="mb-0">
                  Código de cuenta Interbancaria en soles: 00219300258060501516
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <p class="mb-0">
                  Envía tu comprobante de pago a
                  <b>deepdataconvoca&#64;gmail.com</b> y nos pondremos en contacto
                  contigo.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="jpg-contentarticle tab-pane fade" id="tab03">
          <div class="row">
            <div class="jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <h5>¡Muy pronto!</h5>
                <p class="mb-0">
                  En breve podrás acceder a esta opción de pago, nos
                  comunicaremos contigo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-dark" (click)="modal.close('Save click')"> Entendido </button>
  </div>
</ng-template>

<div *ngIf="showCarrito">
  <div class="jpg-haslayout">
    <br /><br />
    <div class="container mt-2 jpg-cuenta">
      <div class="row justify-content-md-center">
        <div class="container col-xs-12 col-sm-12">
          <br />
          <h2>Carrito</h2>
          <div style="font-size: 1rem">
            <div style="border-bottom: 2px solid" class="col-sm-12 d-flex justify-content-between">
              <span style="font-weight: 700">Producto</span>
              <span style="font-weight: 700">Precio</span>
            </div>
            <br />
            <div class="col-sm-12 mt-2 mb-2 d-flex justify-content-between">
              <div>
                <span><b>{{ membresiaSelected.nombre }}</b> <br />Licencia de uso
                  no comercial</span>
              </div>
              <span style="font-weight: 700">$ {{ membresiaSelected.precio }}</span>
            </div>
            <div *ngIf="authorization" class="col-sm-12 mt-2 mb-2 d-flex justify-content-between">
              <div>
                <span><b>IGV</b></span>
              </div>
              <span style="font-weight: 700">$ {{ authorization.igv }}</span>
            </div>
            <br />
            <div style="border-top: 2px solid" class="col-sm-12 d-flex justify-content-between">
              <a role="button" (click)="regresar()"><span style="font-weight: 600" class="text-danger">CAMBIAR DE
                  PLAN</span></a>
              <span *ngIf="authorization" style="font-weight: 700">TOTAL: $ {{ authorization.total }}</span>
              <span *ngIf="!authorization" style="font-weight: 700">TOTAL: $ {{ membresiaSelected.precio }}</span>
            </div>
            <br />
            <div class="col-sm-12 text-right mt-2">
              <div *ngIf="authorization" class="form-group jpg-btnarea">
                <form name="f1" id="f1" action="#" method="post" class="alignet-form-vpos2">
                  <input type="hidden" name="acquirerId" [(ngModel)]="acquirer_id" />
                  <input type="hidden" name="idCommerce" [(ngModel)]="id_commerce" />
                  <input type="hidden" name="purchaseOperationNumber" [(ngModel)]="purchase_operation_number" />
                  <input type="hidden" name="purchaseAmount" [(ngModel)]="precio_membresia" />
                  <input type="hidden" name="purchaseCurrencyCode" [(ngModel)]="purchase_currency_code" />
                  <input type="hidden" name="language" value="SP" />
                  <input type="hidden" name="shippingFirstName" value="nombre" />
                  <input type="hidden" name="shippingLastName" value="apellido" />
                  <input type="hidden" name="shippingEmail" value="convocadeepdata@gmail.com" />
                  <input type="hidden" name="shippingAddress" value="LIMA" />
                  <input type="hidden" name="shippingZIP" value="ZIP 123" />
                  <input type="hidden" name="shippingCity" value="LIMA" />
                  <input type="hidden" name="shippingState" value="LIMA" />
                  <input type="hidden" name="shippingCountry" value="PE" />
                  <input type="hidden" name="userCommerce" value="" />
                  <input type="hidden" name="userCodePayme" value="" />
                  <input type="hidden" name="descriptionProducts" value="Producto ABC" />
                  <input type="hidden" name="programmingLanguage" value="PHP" />
                  <input type="hidden" name="purchaseVerification" [(ngModel)]="purchaseVerification" />
                  <input type="hidden" name="reserved1" [(ngModel)]="reserved1" />
                  <span><b>Realiza tu pago con Visa o Mastercard.</b>
                    &nbsp;</span><input type="button" class="jpg-btn"
                    onclick="javascript:AlignetVPOS2.openModal('','2')" value="Realizar pago" />
                </form>
              </div>
              <div *ngIf="!authorization" class="form-group jpg-btnarea">
                <a role="button" (click)="finalizarFree()" class="jpg-btn"> Finalizar </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
  </div>
</div>