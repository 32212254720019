import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SortEvent } from '../../directives/sortable.directive';
import { AbstractForm } from '../../../core/class/forms.abstract';
import { FormBuilder } from '@angular/forms';
import { ITableActions, ITableHeaders } from '../../../interfaces';

@Component({
  selector: 'app-table-sorteable',
  templateUrl: './table-sorteable.component.html',
  styleUrls: ['./table-sorteable.component.css']
})
export class TableSorteableComponent extends AbstractForm implements OnInit, OnChanges {

  @Input() inputHeaders: ITableHeaders[] = [];
  @Input() inputData: any[] = [];
  @Input() inputActions: ITableActions | null = null;
  @Input() inputPagination: any = null;
  @Input() inputIsLoading: boolean = false;
  @Output() outputPageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputPageSizeChange: EventEmitter<any> = new EventEmitter<any>();

  public data: any[] = [];
  public paginatedData: any[] = [];
  public pageSizes: number[] = [5, 10, 20, 50];
  public page: number = 1;

  constructor(
    formBuilder: FormBuilder,
  ) {
    super(formBuilder);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.valuesChanges();
    if (this.inputData && this.inputData.length > 0) {
      this.data = [...this.inputData];
      this.paginateData();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData'] && !changes['inputData'].firstChange && changes['inputData'].currentValue && changes['inputData'].currentValue.length > 0) {
      this.data = [...changes.inputData.currentValue];
      this.paginateData();
    }
  }

  public initForm(): void {
    this.form = this.superFormBuilder.group({
      searchTerm: [null],
      pageSize: [10]
    });
  }

  private valuesChanges(): void {
    this.form.get('searchTerm')?.valueChanges.subscribe((term: string) => {
      this.onSearch(term);
    });
    this.form.get('pageSize')?.valueChanges.subscribe((pageSize: number) => {
      if (this.inputPagination) {
        this.outputPageSizeChange.emit({ page: 1, limit: +pageSize });
      } else {
        this.page = 1;
        this.paginateData();
      }
    });
  }

  public onSearch(term: string): void {
    const searchTerm = term.trim().toLowerCase();
    if (searchTerm) {
      this.data = this.inputData.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(searchTerm)
        )
      );
    } else {
      this.data = [...this.inputData];
    }
    !this.inputPagination && (this.page = 1);
    this.paginateData();
  }

  public onSortData({ column, direction }: SortEvent): void {
    if (column === '' || direction === '') {
      this.data = [...this.inputData];
    } else {
      this.data = [...this.data].sort((a, b) => {
        const res = a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
        return direction === 'asc' ? res : -res;
      });
    }
    this.paginateData();
  }

  public emitPageChange(page: number): void {
    this.data.length = 0;
    this.outputPageChange.emit({ page: page, limit: this.inputPagination.per_page });
  }

  public onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateData();
  }

  public paginateData(): void {
    if (!this.inputPagination) {
      const pageSizeValue: number = +this.form.get('pageSize')?.value;
      const start = (this.page - 1) * pageSizeValue;
      const end = start + pageSizeValue;
      this.paginatedData = this.data.slice(start, end);
    }
  }

  get calculatedIndex(): number {
    return (this.inputPagination.current_page - 1) * (this.inputPagination.per_page || 10);
  }

}
