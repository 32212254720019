<form [formGroup]="form">
  <div class="form-group form-inline justify-content-between align-items-center">
    <div class="input-group w-50">
      <input type="text" class="form-control " placeholder="Búsqueda de texto completo, ingrese una palabra clave..."
        formControlName="searchTerm" maxlength="255">
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
      </div>
    </div>

    <button *ngIf="inputActions && inputActions.create" type="button" class="btn btn-create"
      (click)="inputActions.create.handler(null)">
      <i [class]="inputActions.create.icon" aria-hidden="true"></i> {{inputActions.create.label}}
    </button>
  </div>
  <div class="table-responsive">
    <table class="table table-striped table-borderless jpg-table">
      <thead class="thead-main">
        <tr *ngIf="inputHeaders.length>0">
          <th scope="col" *ngFor="let header of inputHeaders" [sortable]="header.sortable ? header.key : ''"
            (sort)="header.sortable ? onSort($event) : null" class="text-left align-middle"
            [ngClass]="{'disabled-header': !header.sortable}">
            {{ header.label }}
          </th>
          <th *ngIf="inputActions" scope="col" class="text-center align-middle"> Acciones </th>
        </tr>
      </thead>
      <tbody *ngIf="inputData.length>0" class="tbody-main">
        <tr *ngFor="let row of data">
          <td *ngFor="let header of inputHeaders" class="text-left align-middle">
            <div [ngSwitch]="header.key">
              <img *ngSwitchCase="'link_imagen'" [src]="row[header.key]" [alt]=" row[header.key]"
                class="img-fluid align-self-center">
              <a *ngSwitchCase="'link'" role="link" [href]="row[header.key]" target="_blank"
                rel="noopener noreferrer">{{row[header.key]}}</a>
              <div *ngSwitchCase="'caracteristicas'" [innerHTML]="row[header.key] | safehtml"></div>
              <span *ngSwitchDefault>
                {{ row[header.key] }}
              </span>
            </div>
          </td>
          <td *ngIf="inputActions && inputActions.edit" class="text-center align-middle">
            <a role="button" class="btn-edit" data-toggle="tooltip" data-placement="bottom" title="Editar"
              (click)="inputActions.edit.handler(row)">
              <i [class]="inputActions.edit.icon" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="inputPagination" class="d-flex justify-content-between align-items-center">
    <div class="text-start select-page">
      <span class="jpg-select">
        <select formControlName="pageSize" id="perPage" class="w-100">
          <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
        </select>
      </span>
    </div>
    <div class="mx-auto">
      <ngb-pagination [collectionSize]="inputPagination.total" [(page)]="inputPagination.current_page" [pageSize]="inputPagination.per_page"
        [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="emitPageChange($event)">
        <ng-template ngbPaginationFirst>Primero</ng-template>
        <ng-template ngbPaginationLast>Último</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</form>
