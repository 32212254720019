import { Component, OnInit } from '@angular/core';
import { PagosService } from '../../services/pagos.service';
// import { sha512 } from 'js-sha512';
import { MembresiaService } from '../../services/membresia.service';
import { AlertService } from '../../services/util/alert.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../services/auth.service';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-cuenta',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css'],
})
export class PlanesComponent implements OnInit {
  authorization: any;
  membresias: any;
  membresiasForUser: any;
  membresiaSelected: any;

  membresiaFree: any;
  membresiaEsencial: any;
  membresiaBasica: any;
  membresiaPremium: any;

  acquirer_id: string;
  id_commerce: string;
  purchase_operation_number: string;
  precio_membresia: number;
  purchase_currency_code: string;
  reserved1: string;

  // CALCULANDO IGV
  precioSinIGV: number;
  montoIGV: number;
  precioConIGV: number;
  precioConIGVStr: string;

  purchaseOperationNumber: string;
  purchaseVerification: string;

  showCarrito: boolean = false;
  isFree: boolean = false; //Temporal

  currentUser: any;

  // PARA CONTROLAR LAS MEMBRESÍAS
  planBasicShow: number = 1;
  planPremiumShow: number = 1;

  constructor(
    public pagoService: PagosService,
    public alertService: AlertService,
    public router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    public membresiaService: MembresiaService
  ) {
    this.checkUsuario();
  }

  ngOnInit(): void {
    this.listarMembresias();
    this.listarMembresiasForUser();

    //this.purchaseVerification = sha512.create().update('114'+'11825'+'10000910'+'100'+'604'+'GaGjrwrZgPdJuZnGB$9384567383').hex()
    //this.purchaseVerification = sha512('144'+'11902'+ this.purchaseOperationNumber +'1250'+'840'+'GaGjrwrZgPdJuZnGB$9384567383');
    //console.log('this.pruchaseVerification',this.purchaseVerification);
  }

  listarMembresias() {
    this.alertService.alertLoading('Cargando ...');

    this.membresiaService.getMembresias().subscribe((resp) => {
      if (resp.success) {
        this.membresias = resp.membresias.sort(this.compare);
        this.alertService.closeAlert();

        this.membresiaFree = this.membresias.filter((x) => x.id === 1);
        this.membresiaBasica = this.membresias.filter((x) => x.id === 2);
        this.membresiaEsencial = this.membresias.filter((x) => x.id === 3);
        this.membresiaPremium = this.membresias.filter((x) => x.id === 4);

        // this.membresias = this.membresias.map((m) => {
        //   m.precio = this.calcularMontosIGVRevert(m.precio);
        // });
      }
    });
  }

  listarMembresiasForUser() {
    this.membresiaService.getMembresiasForUser().subscribe((resp) => {
      if (resp.success) {
        this.membresiasForUser = resp.membresias.sort(this.compare);
      }
    });
  }

  checkUsuario() {
    if (this.authService.usuario == null || !this.authService.usuario) {
      this.router.navigate(['/auth/ingreso']);
      this.alertService
        .alertInfo(
          'Debe iniciar sesión',
          'Para comprar alguno de nuestros planes por favor ingrese con su credenciales.'
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/auth/ingreso']);
          }
        });
    }
  }

  // PARA EL ORDENAMIENTO
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const objA = a.orden;
    const objB = b.orden;

    let comparison = 0;
    if (objA > objB) {
      comparison = 1;
    } else if (objA < objB) {
      comparison = -1;
    }
    return comparison;
  }

  seleccionarProducto(membresia) {
    let allowed = this.membresiasForUser.some(
      (e) => e.id_plan === membresia.id_plan
    );

    if (allowed) {
      this.alertService.alertLoading('Cargando...');
      this.membresiaSelected = membresia;
      this.getAutorizacionPago(this.membresiaSelected.id_plan);
    } else {
      // NO ESTÁ PERMITIDO - SOLO PARA PERIODISTAS
      this.alertService
        .alertInfo(
          'Suscripción especial para periodistas',
          'Buscamos apoyar a los periodistas independientes para que continúen generando contenido de impacto público. Para acceder a este paquete especial debes inscribirte aquí:',
          'Completar formulario'
        )
        .then((resp) => {
          if (resp.value) {
            window.open('https://forms.gle/xfNvAygPWnXNusQ49', '_blank');
          }
        });
    }
  }

  selectPlanPeriodista() {
    window.open('https://forms.gle/xfNvAygPWnXNusQ49', '_blank');
  }

  getAutorizacionPago(idMembresia: number) {
    this.pagoService.getAutorizacionPago(idMembresia).subscribe((resp) => {
      // console.log('resp',resp);
      if (resp.success) {
        if (!resp.autorizacion.free) {
          this.authorization = resp.autorizacion;
          // console.log('auth',this.authorization);

          this.acquirer_id = this.authorization.acquirer_id;
          this.id_commerce = this.authorization.id_commerce;
          this.purchase_operation_number =
            this.authorization.purchase_operation_number;

          this.calcularMontosIGV(parseFloat(this.membresiaSelected.precio));
          this.precio_membresia = parseFloat(
            this.precioConIGVStr.replace('.', '')
          );

          this.purchase_currency_code =
            this.authorization.purchase_currency_code;
          this.purchaseVerification = this.authorization.purchaseVerification;

          this.reserved1 = this.usuarioService.perfilUsuario.id;
        }

        window.scrollTo(0, 0);
        this.showCarrito = true;
        this.alertService.closeAlert();
      }
    });
  }

  calcularMontosIGV(montoBase) {
    this.precioSinIGV = montoBase;
    this.precioConIGVStr = this.convertToDocument(montoBase * 1.18);
    this.precioConIGV = this.convertToDocument(montoBase * 1.18);
    this.montoIGV = this.convertToDocument(
      this.precioConIGV - this.precioSinIGV
    );
  }

  calcularMontosIGVRevert(montoBase) {
    let precioConIGV = montoBase;
    // console.log('montoBase',montoBase);
    let precioSinIGVStr = this.convertToDocument(montoBase / 1.18);
    // console.log('precioSinIGVStr',precioSinIGVStr);
    let precioSinIGV = this.convertToDocument(montoBase / 1.18);
    // console.log('precioSinIGV',precioSinIGV);
    let montoIGV = this.convertToDocument(precioConIGV - precioSinIGV);
    // console.log('montoIGV',montoIGV);
    return precioSinIGVStr;
  }

  // Para redondeos
  roundNumberV2(num, scale) {
    if (Math.round(num) != num) {
      if (Math.pow(0.1, scale) > num && num != 0.01) {
        var n = 0;
        return n;
        //return n.toFixed(2);
      }
      var sign = Math.sign(num);
      var arr = ('' + Math.abs(num)).split('.');
      if (arr.length > 1) {
        if (arr[1].length > scale) {
          var integ = +arr[0] * Math.pow(10, scale);
          var dec = integ + (+arr[1].slice(0, scale) + Math.pow(10, scale));
          var proc = +arr[1].slice(scale, scale + 1);
          if (proc >= 5) {
            dec = dec + 1;
          }
          dec = (sign * (dec - Math.pow(10, scale))) / Math.pow(10, scale);
          return dec;
        }
      }
    }
    return num;
  }

  convertToDocument(x) {
    x = this.roundNumberV2(parseFloat(x), 2);
    x = x.toFixed(2);
    // x = parseFloat(x).toFixed(2);
    return x;
  }

  getAutorizacionPagoFree(membresia) {
    this.alertService.alertLoading('Cargando...');
    this.membresiaSelected = membresia;
    window.scrollTo(0, 0);
    this.showCarrito = true;
    this.alertService.closeAlert();
  }

  getAutorizacionPagoTemporal(idMembresia: number) {
    // console.log('idMembresia',idMembresia);
    let freePlans = [1, 2, 3, 4, 5];

    if (freePlans.includes(idMembresia)) {
      this.isFree = idMembresia === 1 ? true : false;
      window.scrollTo(0, 0);
      this.showCarrito = true;
      this.alertService.closeAlert();
    } else {
      this.alertService.alertInfo(
        'Pago en línea ¡Muy pronto!',
        'En breve podrás acceder a esta opción de pago, nos comunicaremos contigo.'
      );
    }
  }

  // registerFreePlan(){

  //   let freePlanIdTemporal = 1;

  //   this.alertService.alertLoading('Registrando...');

  //   this.membresiaService.adquirirFree(freePlanIdTemporal).subscribe(
  //     resp => {
  //       if(resp.success){
  //         this.alertService.alertSuccessCustom('¡Felicidades!','Ahora eres parte de la comunidad Convoca Deep Data.','Déjanos tus comentarios en <b>deepdataconvoca@gmail.com</b> para seguir mejorando nuestra platafoma.','Empezar').then(
  //           resp => {
  //             if(resp.value){ this.router.navigate(['/industrias-extractivas']); }
  //           }
  //         );
  //       }
  //     }
  //   );

  // }

  regresar() {
    this.showCarrito = false;
    this.membresiaSelected = null;
    this.authorization = null;

    this.precioConIGV = null;
    this.montoIGV = null;
    this.precioSinIGV = null;
    this.precioConIGVStr = '';

    this.planBasicShow = 1;
    this.planPremiumShow = 1;
    this.changeMembresiaBasica(1);
    this.changeMembresiaPremium(1);
  }

  finalizarFree() {
    // console.log('this.membresiaSelected',this.membresiaSelected);

    this.alertService.alertLoading('Registrando...');

    this.membresiaService
      .adquirirFree(this.membresiaSelected.id_plan)
      .subscribe((resp) => {
        if (resp.success) {
          this.usuarioService.getUsuarioPerfil().subscribe((respPerfil) => {
            console.log('respPerfil', respPerfil);

            if (respPerfil.success) {
              this.alertService
                .alertSuccessCustom(
                  '¡Felicidades!',
                  'Ahora eres parte de la comunidad Convoca Deep Data.',
                  'Déjanos tus comentarios en <b>deepdataconvoca@gmail.com</b> para seguir mejorando nuestra platafoma.',
                  'Empezar'
                )
                .then((respModal) => {
                  if (respModal.value) {
                    this.router.navigate(['/industrias-extractivas']);
                  }
                });
            }
          });
        }
      });
  }

  closeResult = '';

  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'lg',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // TO CONTROL HTML PLANS TO SHOW
  changeMembresiaBasica(plan) {
    this.planBasicShow = plan;
  }

  changeMembresiaPremium(plan) {
    this.planPremiumShow = plan;
  }
}
