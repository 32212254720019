<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor mt-5" style="background-color: white; height: 75vh;">
  <div class="jpg-haslayout">

    <br>

    <!-- Busqeuda de empresas Listing Start-->
    <div class="jpg-haslayout">
      <div class="container-fluid">

        <div class="col-sm-12 text-center">
          <img style="width: 14rem;" src=".../../../assets/img/desktop.png" class="img-fluid" alt="Los Angeles">
        </div>
        <div class="col-sm-12 text-center">
          <h2>Gracias por tu visita</h2>
        </div>
        <br>
        <div class="col-sm-12 text-center">
          <p>Para continuar explorando ingresa <br> a <b>deepdata.convoca.pe</b> desde una computadora.</p>
        </div>
        <br>
        <div class="col-sm-12 text-center">
          <button class="btn btn-outline-dark w-50" [routerLink]='["/main/home"]'>Cerrar</button>
        </div>

      </div>
    </div>
    <!-- Busqeuda de empresas Listing End-->

  </div>
</main>