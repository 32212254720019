import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { DataEmpresaService } from '../../../services/data-empresa.service';
import { CruceDatos } from '../../../models/cruce-datos.model';
import { AlertService } from '../../../services/util/alert.service';

import * as XLSX from 'xlsx';
import { UsuarioService } from '../../../services/usuario.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-busqueda-masiva',
  templateUrl: './busqueda-masiva.component.html',
  styleUrls: ['./busqueda-masiva.component.css']
})
export class BusquedaMasivaComponent implements OnInit {

  cruceDatos: CruceDatos = new CruceDatos();

  fileSubir: File | null;
  fileTemp: any;

  fileName: string = "Sube tu archivo aquí";

  storeData: any;
  csvData: any;
  jsonData: any;
  textData: any;
  htmlData: any;
  fileUploaded: File;
  worksheet: any;

  isAllowed: any;

  // PARA EL FILTRADO
  ambientales: boolean = true;
  laborales: boolean = true;
  multas: boolean = true;
  derrames: boolean = true;
  accidentes: boolean = true;
  estructura: boolean = true;
  representantes: boolean = true;
  proyectos: boolean = true;
  permisos: boolean = true;

  constructor(public router: Router,
    private route: ActivatedRoute,
    public _alertService: AlertService,
    public _authService: AuthService,
    public _usuarioService: UsuarioService,
    public _dataEmpresaService: DataEmpresaService,
  ) {

    // if(this._authService.token){
    //   this.checkMembresia();
    // }else{
    //   this._alertService.alertError('Inicia sesión','Para ver esta funcionalidad debes iniciar sesión.','Ingresar').then(
    //     resp => {
    //       if (resp.value){
    //         this.router.navigate(['/ingreso']);
    //       }
    //     }
    //   );
    // }

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  checkMembresia() {

    // SI ES PREMIUM
    let freePlans = [11, 6, 7, 8];

    if (freePlans.includes(this._usuarioService.perfilUsuario?.id_plan)) {

      this.isAllowed = true;
      this.subirArchivo();

    } else {
      this.isAllowed = false;
      this._alertService.alertInfo('Descubre más funcionalidades', 'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.', 'Ver planes').then(
        resp => {
          if (resp.value) {
            this.router.navigate(['/planes']);
          }
        }
      );
    }

  }

  checkMembresiaOld() {

    // SI ES PREMIUM
    let freePlans = [11, 6, 7, 8];

    if (freePlans.includes(this._usuarioService.perfilUsuario.id_plan)) {
      this.isAllowed = true;
      this._alertService.closeAlert();
    } else {
      this.isAllowed = false;
      this._alertService.alertInfo('Descubre más funcionalidades', 'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.', 'Ver planes').then(
        resp => {
          if (resp.value) {
            this.router.navigate(['/planes']);
          }
        }
      );
    }

  }

  // verBusquedaResultados() {

  //   this._alertService.alertLoading("Procesando información");

  //   this._dataEmpresaService.getCruceEmpresa().subscribe(
  //     resp => {
  //       if (resp.success) {
  //         this.cruceDatos = resp.resultados;

  //         let navigationExtras: NavigationExtras = {};
  //         if (this.cruceDatos) {
  //           navigationExtras = {
  //             queryParams: {
  //               "resultados": JSON.stringify(this.cruceDatos)
  //             },
  //             skipLocationChange: true
  //           };
  //         }

  //         this.router.navigate(["/busqueda-masiva-datos"], navigationExtras);
  //       }
  //     }
  //   );
  // }


  // SUBIDA Y PREVISUALIZACION DE IMAGENES
  seleccionFile(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const archivo = input.files[0];
      this.fileName = archivo.name;

      if (!archivo) {
        this.fileSubir = null;
        if (!this.fileTemp) { this.fileTemp = null; }
        return;
      }

      if (archivo.type.indexOf('sheet') < 0 && archivo.type.indexOf('excel') < 0) {
        this._alertService.alertInfo('Archivo no permitido', 'Sólo debe subir archivos .xls .xlsx');
        if (!this.fileTemp) { this.fileTemp = null; }
        this.fileName = 'Seleccionar archivo';
        return;
      }

      if ((archivo.size / 1000) > 1000) {
        // Swal.fire('La imagen debe ser máximo de 1MB', 'El archivo seleccionado es muy grande', 'error');
        this.fileSubir = null;
        if (!this.fileTemp) { this.fileTemp = null; }
        this.fileName = 'Seleccionar archivo';
        return;
      }

      this.fileSubir = archivo;

      let reader = new FileReader();
      let urlImagenTemp = reader.readAsDataURL(archivo);

      reader.onloadend = () => {
        this.fileTemp = reader.result;
      };

      // this.readExcel(this.fileSubir);
      // let resp = this._dataEmpresaService.getCruceEmpresaWithFile(this.fileSubir);

    }


  }

  readExcel(file) {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      var data = new Uint8Array(this.storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];
    }
    readFile.readAsArrayBuffer(file);

    //Generate Excel File with given name
    // readFile.writeBuffer().then((data) => {
    //   let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   fs.saveAs(blob, nombreArchivo);
    // })

  }

  subirArchivo() {

    let filtrosString = this.getFiltros();

    if (this.fileSubir) {

      this._alertService.alertLoading('Procesando información');

      this._dataEmpresaService.getCruceEmpresaWithFile(this.fileSubir, filtrosString).subscribe(
        resp => {

          this.cruceDatos = resp.resultados;

          let navigationExtras: NavigationExtras = {};
          if (this.cruceDatos) {
            navigationExtras = {
              queryParams: {
                "resultados": JSON.stringify(this.cruceDatos)
              },
              skipLocationChange: true
            };
            this.router.navigate(["/busqueda-masiva-datos"], navigationExtras);
          }

        }
      );
    } else {
      this._alertService.alertInfo('Debe seleccionar un archivo', 'Sólo debe subir archivos .xlx .xlsx .csv');
    }


  }

  getFiltros() {

    let resultFiltro: string[] = [];

    if (this.ambientales) {
      resultFiltro.push('fac_infracciones_ambientales');
    }
    if (this.laborales) {
      resultFiltro.push('fac_infracciones_laborales');
    }
    if (this.multas) {
      resultFiltro.push('fac_multas_perdonadas');
    }
    if (this.derrames) {
      resultFiltro.push('fac_informacion_derrames');
    }
    if (this.accidentes) {
      resultFiltro.push('fac_informacion_accidentes');
    }
    if (this.estructura) {
      resultFiltro.push('fac_estructura');
    }
    if (this.representantes) {
      resultFiltro.push('fac_representantes');
    }
    if (this.proyectos) {
      resultFiltro.push('fac_informacion_proyectos');
    }
    if (this.permisos) {
      resultFiltro.push('fac_informacion_permisos');
    }

    return resultFiltro;

  }


}
