<!--Inner Home Banner Start-->
<div class="d-none d-lg-block">
  <div class="jpg-haslayout banner-extractivas">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <div class="jpg-innerbannercontent">
            <div class="jpg-title">
              <h2 class="hero-title">
                Conoce a las empresas mineras y de hidrocarburos en el Perú
              </h2>
            </div>
          </div>
          <div class="breadcum row d-flex justify-content-center align-items-center">
            <a href="https://convoca.pe/newslettercomunidatos" target="_blank">
              <p class="text-center text-white mb-0 mx-2">Newsletter</p>
            </a>
            <p class="mb-0 text-white">|</p>
            <a role="button" (click)="shareFacebook()">
              <img class="mx-2" src="../../../../assets/icons/fb-icon.png" />
            </a>
            <a role="button" (click)="shareTwitter()">
              <img class="mx-2" src="../../../../assets/icons/twitter-icon.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-block d-lg-none container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white banner-mobile">
          Conoce a las empresas mineras y de hidrocarburos en el Perú
        </h2>
        <div class="d-flex justify-content-center align-items-center">
          <a href="https://convoca.pe/newslettercomunidatos" target="_blank">
            <p class="text-center text-white mb-0">Newsletter</p>
          </a>
          <p class="mb-0 text-white mx-2">|</p>
          <a role="button" (click)="shareFacebook()">
            <img class="mx-2" src="../../../../assets/icons/fb-icon.png" />
          </a>
          <a role="button" (click)="shareTwitter()">
            <img class="mx-2" src="../../../../assets/icons/twitter-icon.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Home Banner End-->

<!-- FILTERS DESKTOP -->
<div class="d-none d-lg-block jpg-haslayout">
  <!-- CARGA MASIVA BANNER-->
  <div class="card border-0 mr-0 ml-0 mb-3 ">
    <div class="card-body p-0 ">
      <div class="carga-masiva-banner d-flex justify-content-center align-items-center">
        <p class="mb-0 mx-2 text-white cm-banner-title">
          Cruza tu base de datos
        </p>
        <button type="button" class="btn btn-danger cm-button mx-1 text-uppercase font-weight-bold"
          (click)="busquedaMasivaRedirect()">
          BÚSQUEDA MASIVA
        </button>
      </div>
    </div>
  </div>
  <!-- FORM FILTROS-->
  <div class="jpg-categoryslidercontent">
    <div class="row">
      <div class="col-12 col-lg-8 d-flex justify-content-between align-items-center">
        <div class="jpg-cattitle mx-3">
          <h3 class="mb-2">
            <img src="../../../../assets/icons/economy-activity-icon.png" class="mr-2" />Actividad económica
          </h3>
          <span class="jpg-select">
            <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad" class="w-100">
              <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                {{ actividad }}
              </option>
            </select>
          </span>
        </div>

        <div class="jpg-cattitle mx-3">
          <h3 class="mb-2">
            <img src="../../../../assets/icons/empresa.png" class="mr-2" />Empresa
          </h3>
          <div class="form-group mb-0">
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>
            <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
              [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
              [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)" />
            <div class="invalid-feedback" *ngIf="searchFailed">
              No encontramos coincidencias
            </div>
          </div>
        </div>

        <div class="jpg-cattitle mx-3">
          <h3>
            <img src="../../../../assets/icons/map-icon.png" class="mr-2" />Región
          </h3>
          <div class="form-group mb-0">
            <span class="jpg-select">
              <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion">
                <option *ngFor="let region of cboRegiones" [ngValue]="region">
                  {{ region }}
                </option>
              </select>
            </span>
          </div>
        </div>

        <div class="jpg-cattitle mx-3">
          <h3>
            <img src="../../../../assets/icons/calendar-icon.png" class="mr-2" />
            Año
          </h3>
          <div class="form-group mb-0">
            <span class="jpg-select">
              <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio">
                <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                  {{ anio }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 col-md pl-4 pb-4 d-flex justify-content-start align-items-end">
        <button type="button" class="btn btn-dark filter-button text-uppercase font-weight-bold"
          (click)="realizarBusqueda()">
          BUSCAR
        </button>
      </div>
      <div class="col-12 col-md py-4 d-flex align-items-center">
        <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%">
          <h2 class="filter-title">Filtrar resultados:</h2>
          <a role="button" (click)="limpiarFiltros()" class="red-link">Limpiar filtros</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-12">
      <div class="row filters-mobile-buttons">
        <div class="col-6 p-0 py-3 d-flex justify-content-center filters-mobile-button">
          <a role="button" (click)="openMobileFilterModal(mobileFilter)"
            class="w-100 link-filter d-flex align-items-center justify-content-center"><img
              src="../../../../assets/icons/filter.png" class="mr-2" />
            Filtrar por
          </a>
        </div>
        <div class="col-6 p-0 py-3 d-flex justify-content-center filters-mobile-button">
          <a role="button" (click)="showOrderButtons()"
            class="w-100 link-filter d-flex align-items-center justify-content-center" #toggleOrdenamientoMenu>
            <img src="../../../../assets/icons/arrows.png" class="mr-2" />
            Ordenar por
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <div *ngIf="showMobileOrderButtons" #ordenamientoMenu class="ordenamiento-container">
        <div class="ordenamiento-mobile-button w-50">
          <div class="col-12 p-0 py-3 d-flex justify-content-center filters-mobile-button">
            <a role="button" (click)="selectOrdenamiento(0)" class="link-filter d-flex align-items-center">
              Ascendente
            </a>
          </div>
          <div class="col-12 p-0 py-3 d-flex justify-content-center filters-mobile-button">
            <a role="button" (click)="selectOrdenamiento(1)" class="link-filter d-flex align-items-center">
              Descendente
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 text-center mobile-registry-number py-4">
        {{ totalRegistrosEmpresas }} resultados encontrados
      </div>
    </div>
  </div>
</div>

<!-- DESKTOP VIEW-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
  <!-- Busqueda de empresas Listing Start-->
  <div class="jpg-haslayout">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card border-0 jpg-howitwork-hold jpg-bgwhite jpg-haslayout">
            <div class="row">
              <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                <div class="col-md-12">
                  <div class="row align-items-center justify-content-between mb-4">
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <div class="circle circle-muy-infractora mr-2"></div>
                      <h6 class="mb-0 leyenda-muy-infractora">Muy infractora</h6>
                    </div>
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <div class="circle circle-frecuentemente-infractora mr-2"></div>
                      <h6 class="mb-0 leyenda-frecuentemente-infractora">Frecuentemente infractora</h6>
                    </div>
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <div class="circle circle-medianamente-infractora mr-2"></div>
                      <h6 class="mb-0 leyenda-medianamente-infractora">Medianamente infractora</h6>
                    </div>
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <div class="circle circle-poco-infractora mr-2"></div>
                      <h6 class="mb-0 leyenda-poco-infractora">Poco infractora</h6>
                    </div>
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <div class="circle circle-sin-procesos-de-sanción mr-2"></div>
                      <h6 class="mb-0 leyenda-sin-procesos-de-sanción">Sin procesos de sanción</h6>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <div class="separator mx-3"></div>
                    </div>
                    <div class="col-auto text-center d-flex align-items-center my-1">
                      <button type="button"
                        class="btn btn-dark filter-button text-uppercase font-weight-bold btn-methodologies-sources"
                        [routerLink]="'/metodologias'">
                        Metodología y <br /> Fuentes
                      </button>
                    </div>
                  </div>

                  <!-- Tabla -->
                  <div class="table-responsive" style="overflow-x: auto;">
                    <table class="table table-striped jpg-tablecategories tbl-main">
                      <thead class="thead-main">
                        <tr>
                          <!-- Columna de nombre de empresa -->
                          <th class="text-center fit" style="width: 500px;" scope="col" sortable="NombreEmpresa"
                            (sort)="onSort($event)">
                            Empresa
                          </th>
                          <!-- Columna de Ranking -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="ranking_actual"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent" tooltipClass="my-custom-tooltip-class">
                              Ranking
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de Posicion -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="cambio_ranking"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent2" tooltipClass="my-custom-tooltip-class">
                              Posición +/-
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de Infracciones Ambientales -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="IndInfAmb"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent3" tooltipClass="my-custom-tooltip-class">
                              Infracciones Ambientales
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de Infracciones Laborales -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="IndInfLab"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent4" tooltipClass="my-custom-tooltip-class">
                              Infracciones Laborales
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de Derrames -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="derramesacc"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent5" tooltipClass="my-custom-tooltip-class">
                              Derrames
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de Accidentes laborales -->
                          <th class="text-center fit" style="width: 150px;" scope="col" sortable="accidentesacc"
                            (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent6" tooltipClass="my-custom-tooltip-class">
                              Accidentes Laborales
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                          <!-- Columna de multas judiciales -->
                          <th class="text-center fit" style="width: 150px;" scope="col"
                            sortable="multas_laborales_judicial" (sort)="onSort($event)">
                            <div placement="bottom" [ngbTooltip]="tipContent7" tooltipClass="my-custom-tooltip-class">
                              Multas Laborales Judicializadas
                              <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                target="_blank">
                                <span>
                                  <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                </span>
                              </a>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody *ngIf="empresas.length > 0 && !verCifras">
                        <tr *ngFor="let e of empresasPaginated">
                          <td class="text-left" scope="row">
                            <a class="text-dark d-block" role="button" (click)="verDetalleEmpresa(e)">
                              <div>
                                <img src="../../../../assets/icons/building.svg" class="mr-2 icon-table-build" />
                                {{ e.NombreEmpresa }}
                              </div>
                              <span class="mr-2" *ngIf="e.ReactivaPeruFormatted && e.ReactivaPeruFormatted !== 0">
                                <i class="fa fa-money"
                                  [ngbTooltip]="'Montos de los préstamos otorgados a empresas extractivas por el programa Reactiva Perú hasta mayo de 2020.'"
                                  placement="right"></i>
                                S/ {{ e.ReactivaPeruFormatted }}
                              </span>
                              <span *ngIf="e.Covid">
                                <img class="icon-covid-list" src="../../../../assets/img/covid-virus.png"
                                  [ngbTooltip]="'Número de trabajadores contagiados por COVID-19, según lo reportado por las empresas extractivas al Ministerio de Energía y Minas hasta julio de 2020.'"
                                  placement="right" />
                                {{ e.Covid ? e.Covid : "-" }}
                              </span>
                              &nbsp;&nbsp;
                              <span *ngIf="e.NotaCovid" class="mt-2">
                                <i placement="top" [ngbTooltip]="e.NotaCovid" container="body"
                                  class="fa fa-info-circle f-icon"></i>
                              </span>
                            </a>
                          </td>
                          <!-- Columna de Ranking -->
                          <td class="align-middle text-center">
                            <strong>{{ (e.ranking_actual) }}</strong>
                          </td>
                          <!-- Columna de Posicion -->
                          <td class="align-middle">
                            <div class="text-center d-flex align-items-center justify-content-center">
                              <i [ngClass]="getArrowDirection(e.cambio_ranking)"
                                [ngStyle]="{'color': getArrowColor(e.cambio_ranking), 'font-size': '24px'}"></i>
                              <strong [ngStyle]="{'color': getColorPuntos(e), 'margin-left': '8px'}">
                                {{ getPuntosFormatted(e) }}
                              </strong>
                            </div>
                          </td>
                          <!-- Columna de Infracciones Ambientales -->
                          <td class="align-middle text-center">
                            <div class="mb-3">
                              <div class="circle text-white"
                                [ngStyle]="{'background-color':getInfraccionesColor(e.IndInfAmbSemaforo)}">
                                {{ e.IndInfAmb }}
                              </div>
                            </div>
                          </td>
                          <!-- Columna de Infracciones Laborales -->
                          <td class="align-middle text-center">
                            <div class="mb-3">
                              <div class="circle text-white"
                                [ngStyle]="{'background-color':getInfraccionesColor(e.IndInfLabSemaforo)}">
                                {{ e.IndInfLab }}
                              </div>
                            </div>
                          </td>
                          <!-- Columna de Derrames -->
                          <td class="align-middle text-center">
                            <div class="mb-3">
                              <strong>{{ e.derramesacc }}</strong>
                            </div>
                          </td>
                          <!-- Columna de Accidentes -->
                          <td class="align-middle text-center">
                            <div class="mb-3">
                              <strong>{{ e.accidentesacc }}</strong>
                            </div>
                          </td>
                          <!-- Columna combinada de Multas -->
                          <td class="align-middle">
                            <div class="mb-3">
                              <div style="display: flex; justify-content: space-between;">
                                <span>N° Multas: </span><strong [ngStyle]="{'float': 'right'}">{{
                                  e.multas_laborales_judicial}}</strong>
                              </div>
                              <div style="display: flex; justify-content: space-between;">
                                <span>Multa($): </span><strong [ngStyle]="{'float': 'right'}">{{
                                  e.monto_multa_judicial_dolares }}</strong>
                              </div>
                              <div style="display: flex; justify-content: space-between;">
                                <span>Multa(S/): </span><strong [ngStyle]="{'float': 'right'}">{{
                                  e.monto_multa_judicial_soles }}</strong>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody *ngIf="empresas.length === 0">
                        <tr>
                          <td colspan="12">No se encontraron registros con los criterios ingresados.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- Paginación -->
                  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center p-2"
                    *ngIf="empresas.length > 0 && !verCifras">
                    <!-- Paginación centrada -->
                    <div class="d-flex justify-content-center flex-grow-1 p-2 mb-3 mb-md-0">
                      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
                        (pageChange)="refreshEmpresasPagination()">
                        <ng-template ngbPaginationFirst>Primero</ng-template>
                        <ng-template ngbPaginationLast>Último</ng-template>
                        <ng-template ngbPaginationPrevious>&lt;</ng-template>
                        <ng-template ngbPaginationNext>&gt;</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                      </ngb-pagination>
                    </div>
                    <!-- Botón de descarga alineado a la derecha -->
                    <button class="btn btn-custom-primary text-uppercase font-weight-bold px-5 py-1"
                      (click)="descargarResultados()">
                      <i class="fa fa-download"></i> Descargar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-4">
            <div class="row mb-3">
              <div class="col-md-6 col-12">
                <div class="jpg-innerspace">
                  <p class="mb-4">
                    <span class="map-title">Explora en el mapa </span>
                    <span>(Haz zoom para encontrar lo que buscas)</span>
                  </p>
                  <div class="card border-0">
                    <div class="position-relative map-long">
                      <div *ngIf="!mapaMobile" id="mapa-mapbox" class="echarts position-absolute"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="jpg-innerspace">
                  <select id="cboTipoInfraccion" class="form-control mb-3" name="cboTipoInfraccion"
                    [(ngModel)]="selectedTipoInfraccion" (ngModelChange)="getPointsForMap(true)">
                    <option *ngFor="let tipoInfraccion of cboTipoInfraccion" [ngValue]="tipoInfraccion">
                      {{ tipoInfraccion.id }}
                    </option>
                  </select>
                  <div class="row mb-5">
                    <div class="col-md-7">
                      <div>
                        <img src="../../../../assets/img/circle-color-2.png" alt="Infracciones ambientales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Infracciones laborales
                          <span *ngIf="indicadoresNew?.ind_mapa_laborales ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                      <div>
                        <img src="../../../../assets/img/circle-color-3.png" alt="Accidentes laborales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Infracciones ambientales
                          <span *ngIf="indicadoresNew?.ind_mapa_ambientales ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                      <div>
                        <img src="../../../../assets/img/circle-color-6.png" alt="Accidentes laborales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Derrames de petróleo
                          <span *ngIf="indicadoresNew?.ind_mapa_derrames ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                      <div>
                        <img src="../../../../assets/img/circle-color-4.png" alt="Infracciones laborales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Pasivos
                          <span *ngIf="indicadoresNew?.ind_mapa_pasivos ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span></label>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div>
                        <img src="../../../../assets/img/circle-color-1.png" alt="Infracciones ambientales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Permisos de agua
                          <span *ngIf="indicadoresNew?.ind_mapa_permisos ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                      <div>
                        <img src="../../../../assets/img/circle-color-8.png" alt="Accidentes laborales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Proyectos
                          <span *ngIf="indicadoresNew?.ind_mapa_proyectos ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                      <div>
                        <img src="../../../../assets/img/circle-color-7.png" alt="Accidentes laborales"
                          class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                        <label class="lbl-leyenda mb-0">Accidentes
                          <span *ngIf="indicadoresNew?.ind_mapa_accidentes ==='NUEVO'"
                            class="badge badge-pill badge-danger ml-1">NUEVO</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- Sección de COVID -->
                  <div class="row mb-1">
                    <div class="col-auto align-items-start">
                      <img src="../../../../assets/icons/covid-icon.png" />
                    </div>
                    <div class="col">
                      <h5>
                        Cantidad de trabajadores reportados con COVID-19:
                      </h5>
                      <p class="covid-info mb-2">{{ totalCovid }}*</p>
                      <p class="covid-disclaimer">
                        *Datos reportados por empresas mineras al Minem
                        hasta el de agosto de 2020
                      </p>
                    </div>
                  </div>
                  <!-- Sección de Reactiva Perú -->
                  <div class="row">
                    <div class="col-auto align-items-start">
                      <img src="../../../../assets/icons/money-icon.png" />
                    </div>
                    <div class="col">
                      <h5> Monto de los créditos recibidos por Reactiva Perú: </h5>
                      <p class="reactiva-info mb-2">
                        S/ {{ totalReactiva | number: "1.2-2" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="jpg-innerspace">
                  <p>
                    Fuentes: Organismo de Evaluación y Fiscalización
                    Ambiental (OEFA), Superintendencia Nacional de
                    Fiscalización Laboral (SUNAFIL), Ministerio de Energía y
                    Minas (MINEM), Ministerio de Economía y Finanzas (MEF),
                    Autoridad Nacional del Agua (ANA).
                  </p>
                  <p>
                    <b>*</b> Estas son empresas que no aparecen con procesos
                    de sanción en los registros de las entidades
                    fiscalizadores respectivas. En algunos casos son
                    compañías que están en suspensión temporal, baja de
                    oficio o baja definitiva, según SUNAT, pero sobre las
                    que existe en esta plataforma información relevante
                    sobre permisos o actividades que regula el Estado.
                  </p>
                  <a class="metodologia-link" routerLink="/main/metodologia"> Ver metodología </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Busqueda de empresas Listing End-->
</main>
<!-- </div> -->
<!--Main End-->
<ng-template #tipContent>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Orden de las empresas según nivel de cumplimiento de las leyes ambientales y
          laborales determinado por el algoritmo de
          Convoca Deep Data.</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent2>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">El lugar que ocupa una empresa en el orden de acuerdo con sus infracciones.</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent3>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Incumplimiento de normas que protegen el medio ambiente según el Organismo de
          Evaluación y Fiscalización Ambiental
          (OEFA).</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent4>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Incumplimiento de leyes laborales según la Superintendencia Nacional de Fiscalización
          Laboral (Sunafil).</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent5>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Derrames de hidrocarburos debido a fallas en equipos o procesos..</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent6>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Incidentes en el trabajo que causan lesiones, enfermedades y muertes.</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tipContent7>
  <div class="tip-container">
    <div class="tip-message m-2">
      <div class="d-flex my-2 align-items-center">
        <p class="tip-description">Monto de multa que el Estado no puede cobrar debido a que la empresa infractora
          impuso una demanda judicial contra el
          proceso sancionador.</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileFilter let-modal>
  <div class="modal-header d-flex justify-content-end pb-0">
    <a role="button" (click)="modal.close()">
      <img class="mr-2 toltip-icon" src="../../../../assets/icons/close.png" />
    </a>
  </div>
  <div class="modal-body pt-0">
    <h4 class="modal-title text-center mb-3">Filtrar por:</h4>
    <div class="filter-grid">
      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/economy-activity-icon.png" class="mr-2" />Actividad económica
        </h3>
        <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad" class="w-100">
          <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
            {{ actividad }}
          </option>
        </select>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/empresa.png" class="mr-2" />Empresa
        </h3>
        <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
          [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
          [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)" />
        <div class="invalid-feedback" *ngIf="searchFailed">
          No encontramos coincidencias
        </div>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/map-icon.png" class="mr-2" />Región
        </h3>
        <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion" class="w-100">
          <option *ngFor="let region of cboRegiones" [ngValue]="region">
            {{ region }}
          </option>
        </select>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/calendar-icon.png" class="mr-2" />
          Año
        </h3>
        <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" class="w-100">
          <option *ngFor="let anio of cboAnios" [ngValue]="anio">
            {{ anio }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 p-0 d-flex mt-4">
      <button type="button" class="btn btn-dark filter-button text-uppercase font-weight-bold w-50"
        (click)="realizarBusqueda()">
        BUSCAR
      </button>
      <div class="w-50 d-flex justify-content-center align-items-center">
        <a role="button" (click)="limpiarFiltros()" class="red-link">Limpiar filtros</a>
      </div>
    </div>
  </div>
</ng-template>