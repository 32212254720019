import { Injectable } from '@angular/core';
import { ITableHeaders } from '../../interfaces';
import { HttpParams } from '@angular/common/http';
import { CustomHttpEncoder } from './customHttpEncoder.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public generateParams(params: any = {}): HttpParams {
    let httpParams: HttpParams = new HttpParams({ encoder: new CustomHttpEncoder() });
    let arrayValue: string;
    Object.keys(params).forEach((key) => {
      const param = params[key];
      if (param !== undefined) {
        if (Array.isArray(param)) {
          param.forEach((value, index) => {
            if (index === 0) {
              arrayValue = value;
            } else {
              arrayValue += ',' + value;
            }
          });
          httpParams = httpParams.append(key, arrayValue);
        } else {
          httpParams = httpParams.set(key, param);
        }
      }
    });
    return httpParams;
  }

  //EXTRAER LAS KEY
  public getHeaders(obj: Record<string, any>, order?: string[]): ITableHeaders[] {
    const keys = order && order.length ? order : Object.keys(obj);

    const headers: ITableHeaders[] = keys
      .filter((key) => obj.hasOwnProperty(key))
      .map((key) => ({
        key: key,
        label: this.formatLabel(key),
        sortable: true
      }));

    return headers;
  }

  public formatLabel(key: string): string {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (l) => l.toUpperCase());
  }


  public cleanAndFormatString(input: string): string {
    return input
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

}
