<div class="d-none d-md-block jpg-haslayout jpg-innerbannerholder jpg-haslayout-home home-background custom-font">
  <div class="container">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center div-principal">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7 align-self-center">
        <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
          <div class="">
            <h1 *ngIf="contentHome" class="mb-3 home-main-title">
              {{ contentHome.titulo }}
            </h1>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
        <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
          <div class="jpg-title">
            <div class="d-none d-md-block card card-home-convoca">
              <div class="card-body">
                <div class="img-home-card d-flex justify-content-center align-items-center">
                  <b>Realiza tu búsqueda</b>
                </div>
              </div>
            </div>

            <div class="card card-home-convoca">
              <div class="card-body pt-2">
                <form (ngSubmit)="verIndustrias()">
                  <div class="input-group mb-2">
                    <input type="text" class="form-control-convoca-busqueda"
                      placeholder="Ingresa el nombre de la empresa" aria-label="Busqueda" [(ngModel)]="empresaSearch"
                      id="empresa" name="empresa" />
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <button type="submit" class="btn btn-dark m-1 btn-home-buscar">Buscar</button>
                    <a routerLink="/industrias-extractivas" class="btn btn-dark btn-lista m-1 btn-home">Ver la lista
                      completa</a>
                  </div>
                </form>
              </div>
            </div>

            <div class="card card-home-convoca">
              <div class="img-home-card-2 px-4 pt-3 pb-5">
                <b>Búsqueda masiva de datos</b>
                <p class="p-home mt-2">
                  ¿Tienes tu propia base de datos? Súbela en dos pasos y crúzala
                  con la información de la plataforma.
                </p>
                <a class="btn btn-dark m-1 btn-home-buscar" [routerLink]="['/busqueda-masiva']">Ingresa aquí</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<!--Inner Home Banner End-->

<!--Reportaje start End-->
<section class="d-none d-md-block jpg-haslayout">
  <app-carousel [inputCarouselInfo]="carouselReportajes"></app-carousel>
</section>

<!--Inner Home Banner Start-->
<div class="d-none d-md-block jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir">
  <div class="container">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
        <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
          <!-- <div class="jpg-title">
                        <img src="../../../assets/img/deppdata.jpg" class="img-fluid" alt="...">
                    </div> -->

          <br />

          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/v6w9AhAgtpw"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
        <div class="jpg-innerbannercontent">
          <div class="col-sm-12 mt-4">
            <!-- <h2 class="text-left">SÉ PARTE DE CONVOCA DEEP DATA<span style="font-weight: 400;">CONVOCA</span>DATOS</h2> -->
            <h2 class="text-left">SÉ PARTE DE <br />CONVOCA DEEP DATA</h2>
            <!-- <h2 class="text-left">CONVIRTIENDO <br> DATOS EN CONOCIMIENTO</h2> -->
            <br />
            <p class="text-left">
              El aumento en el volumen y la velocidad de la producción de los
              datos es abrumador así como el estado caótico en el que usualmente
              los encontramos. Por eso, Convoca.pe ha creado <b>Deep Data</b>,
              una plataforma generadora de conocimiento sobre las industrias
              extractivas en el Perú a partir del procesamiento de más de 2.4
              millones de datos que ahora están a tu alcance.
            </p>
            <p class="text-left">
              Con un equipo de periodistas, científicos de datos y
              programadores, hemos realizado el trabajo duro de los datos
              (limpieza, organización y análisis) para ponerlos al servicio del
              interés público. La actividad minera y de hidrocarburos cumplen un
              papel predominante en la economía del país, generan un gran
              impacto en la vida de las poblaciones de los Andes y la Amazonía y
              ejercen influencia en los tomadores de decisiones. Saber quién es
              quién en estas actividades productivas es conocer cómo funciona el
              poder.
            </p>
            <p class="text-left">
              Te invitamos a ser parte de nuestra comunidad para que Convoca.pe
              continúe haciendo periodismo de investigación de alto impacto
              público. Buscamos ser sostenibles con tu aporte y nuestro
              compromiso con la verdad para seguir trabajando con independencia.
              Únete a <b>Convoca Deep Data.</b>
            </p>
          </div>
          <div class="col-sm-12 mt-4 text-left p-0">
            <a role="button" [routerLink]="['/auth/registro']"><img src="../../../assets/buttons/descubrelo.png"
                class="img-fluid" alt="..." /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Home Banner End-->

<!--Inner Plataforma Start-->
<div class="d-none d-md-block jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir">
  <div class="container">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="col-xs-12">
        <div class="jpg-innerbannercontent">
          <div class="col-sm-12">
            <h2 style="color: #f30015" class="text-center">
              ¿Por qué elegir nuestra plataforma?
            </h2>
            <br />
          </div>

          <br />

          <div class="row m-0 col-sm-12">
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/conocimiento.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left c-title">CONOCIMIENTO</h3>
                  <p class="text-justify">
                    La plataforma es una fuente de generación de conocimiento de
                    interés público que se puede obtener a través del cruce de
                    información y análisis de datos que están organizados aquí.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/precision.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left">PRECISIÓN</h3>
                  <p class="text-justify">
                    Accede a más de 2.4 millones de datos limpios y procesados
                    con una metodología sólida diseñada por los profesionales de
                    Convoca Deep Data y con información de diversas fuentes
                    oficiales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br /><br />

          <div class="row m-0 col-sm-12">
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/rapidez.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left">AHORRO Y RAPIDEZ</h3>
                  <p class="text-justify">
                    Periodistas, investigadores, comunicadores y organizaciones,
                    pueden ahorrar tiempo y recursos: todo lo que querías saber
                    de las industrias extractivas en un solo lugar.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/convergencia.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left">CONVERGENCIA</h3>
                  <p class="text-justify">
                    Procesamos los datos más relevantes de las industrias
                    extractivas en Perú para que optimices tu análisis e
                    investigación desde más de 30 fuentes de información.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br /><br />

          <div class="row m-0 col-sm-12">
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/experiencia.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left">EXPERIENCIA DE USUARIO</h3>
                  <p class="text-justify">
                    Cruza información con tus propios datos, interactúa con las
                    visualizaciones y explora por los diferentes filtros
                    disponibles con total facilidad. La plataforma puede
                    utilizarla cualquier persona que busque generar
                    conocimiento. Es simple y potente.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row m-0 col-sm-12">
                <div class="col-sm-4">
                  <img src="../../../assets/icons/alerta.png" class="img-fluid" alt="..." />
                </div>
                <div class="col-sm-8">
                  <h3 class="text-left">ALERTA</h3>
                  <p class="text-justify">
                    Obtén información actualizada sobre las empresas más
                    poderosas de las industrias extractivas, según nivel de
                    cumplimiento de las normas ambientales y laborales,
                    estructura empresarial, representantes, autorizaciones
                    otorgadas por el Estado, proyectos, entre otros datos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Plataforma End-->

<!--Inner laboratorio datos Start-->
<div class="d-none d-md-block jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir">
  <div class="container">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 p-0">
        <div class="jpg-innerbannercontent">
          <div class="col-sm-12 mt-4">
            <h2 class="text-center">LABORATORIO DE DATOS</h2>
            <p>
              Queremos compartir nuestro conocimiento y experiencia. Aprende con
              nosotros sobre cómo trabajar con datos: ofreceremos charlas,
              cursos, talleres y programas especializados. Aprenderás a acceder
              a los datos, analizarlos y poder contar historias de impacto
              público con nuevos ángulos.
            </p>
          </div>
          <br />
          <div class="row col-sm-12 m-0">
            <div class="col-sm-12">
              <div class="row col-sm-12 d-flex align-items-center m-0">
                <div class="col-md-12 col-lg-6 offset-lg-1 d-flex justify-content-center align-items-center">
                  <img src="../../../assets/icons/cursos.png" class="img-fluid" alt="..." />
                  <h3 class="text-left c-title">
                    Nuestros talleres, cursos y charlas
                  </h3>
                </div>
                <div class="col-md-12 col-lg-5">
                  <a href="https://escuela.convoca.pe/" target="_blank">
                    <img src="../../../assets/buttons/vernuestros_cursos.png" class="img-fluid" alt="..." />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner laboratorio datos End-->

<!--Inner programas datos Start-->
<div class="d-none d-md-block jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir">
  <div class="container-fluid">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="row col-sm-12 d-flex align-items-center">
        <div class="col-sm-5 offset-sm-1 col-md-4 offset-md-1">
          <h3 class="text-left c-title">
            Investigar con big data temas de impacto público:
          </h3>
          <p class="text-justify">
            Conoce la técnica, metodología y herramientas para procesar
            abundante información y cazar historias de interés público.
          </p>
          <a href="https://escuela.convoca.pe/cursos/taller-investigar-con-big-data-temas-de-impacto-publico/"
            target="_blank">
            <img src="../../../assets/buttons/inscribete.png" class="img-fluid" alt="..." width="220" />
          </a>
        </div>
        <div class="col-sm-6">
          <img src="../../../assets/img/cdeppdata_16.webp" class="img-fluid" alt="..." />
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="row col-sm-12 d-flex align-items-center">
        <div class="col-sm-5 offset-sm-1 col-md-4 offset-md-1">
          <h3 class="text-left c-title">
            Herramientas digitales para el periodismo de datos:
          </h3>
          <p class="text-justify">
            Aplica herramientas para la extracción, limpieza, construcción,
            análisis y visualización de bases de datos para el periodismo.
          </p>
          <!-- <p class="text-justify">Fecha: 20 de agosto de 2020</p> -->
          <a href="https://escuela.convoca.pe/cursos/herramientas-digitales-para-el-periodismo-de-datos/"
            target="_blank">
            <img src="../../../assets/buttons/inscribete.png" class="img-fluid" alt="..." width="220" />
          </a>
        </div>
        <div class="col-sm-6">
          <img src="../../../assets/img/cdeppdata_14.webp" class="img-fluid" alt="..." />
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-5">
      <div class="row col-sm-12 d-flex align-items-center">
        <div class="col-sm-5 offset-sm-1 col-md-4 offset-md-1">
          <h3 class="text-left c-title">
            Taller de aplicaciones web y visualización de datos:
          </h3>
          <p class="text-justify">
            Conoce cómo planificar y ejecutar un proyecto digital basado en
            datos y qué herramientas puedes utilizar para la visualización de
            hallazgos.
          </p>
          <a href="https://escuela.convoca.pe/cursos/taller-de-aplicaciones-web-y-visualizacion-de-datos/"
            target="_blank"><img src="../../../assets/buttons/inscribete.png" class="img-fluid" alt="..."
              width="220" /></a>
        </div>
        <div class="col-sm-6">
          <img src="../../../assets/img/cdeppdata_12.webp" class="img-fluid" alt="..." />
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner programas datos End-->

<!-- RESPONSIVE MOBILE -->
<!-- Iniciar sesion - usuario - responsive -->
<div class="d-md-none card mb-0 card-home-convoca-login card-login">
  <div class="card-body">
    <form action="">
      <div *ngIf="!_authService.token" class="d-flex justify-content-center align-items-center">
        <a role="button" [routerLink]="['/auth/ingreso']">
          <!-- <img class="img-btn-custom" src="../../../assets/buttons/iniciar_sesion.png" alt="..."> -->
          <div class="inline-icon">
            <i class="fa fa-user mr-1" style="font-size: 1.4rem"></i>
            <b>Iniciar sesión</b>
          </div>
        </a>
        <a class="btn btn-danger btn-custom-red ml-3" role="button" [routerLink]="['/auth/registro']">Registrarse</a>
      </div>
      <div *ngIf="_authService.token" class="d-flex justify-content-center align-items-center">
        <a role="button" [routerLink]="['/auth/cuenta']">
          <div class="inline-icon">
            <i class="fa fa-user mr-1" style="font-size: 1.4rem"></i> ¡Hola!,
            <b>{{ _usuarioService.perfilUsuario.nombres }} </b>
          </div>
          <!-- <img src="../../../assets/buttons/ver-cuenta.png" alt="..."> -->
        </a>

        <a class="btn btn-secondary btn-custom-gray ml-3" role="button" (click)="cerrarSesion()">Cerrar
          sesión</a>
      </div>
    </form>
  </div>
</div>
<!-- Iniciar sesion - usuario - responsive end-->

<!-- Home Responsive -->
<div class="d-block d-md-none jpg-haslayout jpg-innerbannerholder jpg-haslayout-home custom-font">
  <!-- MAIN TITLE -->
  <div class="container-fluid home-background-mobile">
    <div class="row justify-content-md-center d-flex justify-content-between align-items-center">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 div-home mt-4">
        <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
          <div class="">
            <h1 class="mb-3 home-main-title text-left">
              LA PLATAFORMA DE ANÁLISIS DE DATOS MÁS COMPLETA SOBRE LAS
              INDUSTRIAS EXTRACTIVAS EN EL PERÚ
            </h1>
            <!-- <p class="main-subtitle text-left">MÁS COMPLETA SOBRE LAS INDUSTRIAS EXTRACTIVAS EN EL PERÚ</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BUSCA POR EMPRESA -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 p-0">
        <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
          <div class="jpg-title">
            <div class="card card-home-convoca">
              <!-- <div class="img-home-card d-flex justify-content-center align-items-center"><b>Realiza tu búsqueda</b></div> -->
              <div class="card-body">
                <h3 class="text-white">Busca por empresa</h3>
                <form action="">
                  <div class="input-group mb-2">
                    <input type="text" class="form-control form-control-convoca-busqueda text-center text-white"
                      placeholder="Ingresa el nombre" aria-label="Busqueda" [(ngModel)]="empresaSearch" id="empresa2"
                      name="empresa" />
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <a *ngIf="!empresaSearch" class="btn btn-dark m-1 btn-custom-red"
                      href="javascript:void(0);">Buscar</a>
                    <a *ngIf="empresaSearch" class="btn btn-dark m-1 btn-custom-red" href="javascript:void(0);"
                      (click)="verIndustrias()">Buscar</a>
                    <a class="btn btn-dark btn-lista m-1 btn-home" href="javascript:void(0);"
                      (click)="verIndustrias()">Ver la lista completa</a>
                  </div>
                </form>
              </div>
            </div>
            <!-- <div class="card card-home-convoca">
                            <div class="img-home-card-2 p-3">
                                <b>Búsqueda masiva de datos</b>
                                <p class="p-home mt-2">¿Tienes tu propia base de datos? Súbela en dos pasos y crúzala con la información de la plataforma.</p>
                                <a class="btn btn-dark m-1 btn-home-buscar" href="javascript:void(0);" [routerLink]='["/busqueda-masiva"]'>Ingresa aquí</a>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- REPORTAJES -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 p-0">
        <div id="demo" class="carousel slide" data-ride="carousel">
          <!-- Indicators -->
          <ul class="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" class="active"></li>
            <li data-target="#demo" data-slide-to="1"></li>
            <li data-target="#demo" data-slide-to="2"></li>
            <li data-target="#demo" data-slide-to="3"></li>
          </ul>

          <!-- The slideshow -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <a href="https://convoca.pe/agenda-propia/14-derrames-de-petroleo-han-ocurrido-durante-la-pandemia-en-el-peru"
                target="_blank">
                <img src=".../../../assets/img/reportajes/portada2.webp" alt="portada-2" />
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                  <h3 class="text-left text-white m-0">
                    14 DERRAMES DE PETRÓLEO HAN OCURRIDO DURANTE LA PANDEMIA EN
                    EL PERÚ
                  </h3>
                  <!-- <p></p> -->
                </div>
              </a>
            </div>

            <div class="carousel-item">
              <a href="https://convoca.pe/investigacion/el-estado-todavia-no-ha-remediado-ni-uno-de-los-3448-pasivos-ambientales-por"
                target="_blank">
                <img src=".../../../assets/img/reportajes/portada1.webp" alt="portada-1" />
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                  <h3 class="text-left text-white m-0">
                    EL ESTADO NO HA REMEDIADO NINGUNO DE LOS MÁS DE 3 MIL
                    PASIVOS AMBIENTALES DE HIDROCARBUROS QUE HAY EN EL PERÚ
                  </h3>
                  <!-- <p></p> -->
                </div>
              </a>
            </div>

            <div class="carousel-item">
              <a href="https://convoca.pe/agenda-propia/covid-19-seis-companias-mineras-acumulan-el-88-de-casos-de-trabajadores-contagiados"
                target="_blank">
                <img src=".../../../assets/img/reportajes/portada3.webp" alt="portada-3" />
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                  <h3 class="text-left text-white m-0">
                    COVID-19: SEIS COMPAÑÍAS MINERAS ACUMULAN EL 88% DE CASOS DE
                    TRABAJADORES CONTAGIADOS QUE SE REPORTARON AL MINEM
                  </h3>
                  <!-- <p></p> -->
                </div>
              </a>
            </div>

            <div class="carousel-item">
              <a href="https://convoca.pe/agenda-propia/reactiva-peru-de-promesa-para-las-mypes-caja-de-grandes-companias-y-otras-vinculadas"
                target="_blank">
                <img src=".../../../assets/img/reportajes/portada4.webp" alt="portada-4" />
                <div class="carousel-caption d-flex align-items-center justify-content-center">
                  <h3 class="text-left text-white m-0">
                    REACTIVA PERÚ: DE PROMESA PARA LAS MYPES A CAJA DE GRANDES
                    COMPAÑÍAS Y OTRAS VINCULADAS A LA CORRUPCIÓN
                  </h3>
                  <!-- <p></p> -->
                </div>
              </a>
            </div>
          </div>

          <!-- Left and right controls -->
          <!-- <a class="carousel-control-prev" href="#demo" data-slide="prev">
                          <span class="carousel-control-prev-icon"></span>
                        </a>
                        <a class="carousel-control-next" href="#demo" data-slide="next">
                          <span class="carousel-control-next-icon"></span>
                        </a> -->
        </div>
      </div>
    </div>
  </div>

  <!-- SE PARTE DE CONVOCA -->
  <div class="jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir bg-white">
    <div class="container">
      <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-4">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
          <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
            <h2 class="text-left mt-3 mb-4">Sé parte de Convoca DeepData</h2>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 p-0">
          <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/v6w9AhAgtpw"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-7">
          <div class="jpg-innerbannercontent">
            <div class="col-sm-12 mt-1">
              <br />
              <p class="text-left">
                El aumento en el volumen y la velocidad de la producción de los
                datos es abrumador así como el estado caótico en el que
                usualmente los encontramos. Por eso, Convoca.pe ha creado
                <b>Deep Data</b>, una plataforma generadora de conocimiento
                sobre las industrias extractivas en el Perú a partir del
                procesamiento de más de 2.4 millones de datos que ahora están a
                tu alcance.
              </p>
              <p class="text-left">
                Con un equipo de periodistas, científicos de datos y
                programadores, hemos realizado el trabajo duro de los datos
                (limpieza, organización y análisis) para ponerlos al servicio
                del interés público. La actividad minera y de hidrocarburos
                cumplen un papel predominante en la economía del país, generan
                un gran impacto en la vida de las poblaciones de los Andes y la
                Amazonía y ejercen influencia en los tomadores de decisiones.
                Saber quién es quién en estas actividades productivas es conocer
                cómo funciona el poder.
              </p>
              <p class="text-left">
                Te invitamos a ser parte de nuestra comunidad para que
                Convoca.pe continúe haciendo periodismo de investigación de alto
                impacto público. Buscamos ser sostenibles con tu aporte y
                nuestro compromiso con la verdad para seguir trabajando con
                independencia. Únete a <b>Convoca Deep Data.</b>
              </p>
            </div>
            <div class="col-sm-12 mt-4 text-left p-0">
              <a role="button" [routerLink]="['/auth/registro']"><img src="../../../assets/buttons/descubrelo.png"
                  class="img-fluid" alt="..." /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BUSQUEDA MASIVA -->
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 p-0">
    <div class="jpg-innerbannercontent d-flex flex-lg-row flex-column align-items-center">
      <div class="jpg-title">
        <div class="card card-home-convoca">
          <!-- <div class="img-home-card d-flex justify-content-center align-items-center"><b>Realiza tu búsqueda</b></div> -->
          <div class="card-body">
            <h3 class="text-white">Búsqueda masiva de datos en Excel</h3>
            <p class="p-home mt-2 text-white">
              ¿Tienes tu propia base de datos? Súbela en dos pasos y crúzala con
              la información de la plataforma.
            </p>
            <a class="btn btn-dark m-1 btn-custom-red w-100" role="button" [routerLink]="['/busqueda-masiva']">Ingresa
              aquí</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- POR QUÉ ELEGIR -->
  <div class="jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir bg-white">
    <div class="container mt-5">
      <div class="row justify-content-md-center d-flex justify-content-between align-items-center mb-2">
        <div class="col-xs-12">
          <div class="jpg-innerbannercontent">
            <div class="col-sm-12">
              <h2 class="text-left">¿Por qué elegir nuestra plataforma?</h2>
              <br />
            </div>

            <div class="row m-0 col-sm-12">
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/conocimiento.png" class="img-custom sm-width-image mr-3" alt="..." />
                  <h4 class="text-left c-title">CONOCIMIENTO</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    La plataforma es una fuente de generación de conocimiento de
                    interés público que se puede obtener a través del cruce de
                    información y análisis de datos que están organizados aquí.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/precision.png" class="img-fluid img-custom sm-width-image mr-3"
                    alt="..." />

                  <h4 class="text-left c-title">PRECISIÓN</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    Accede a más de 2.4 millones de datos limpios y procesados
                    con una metodología sólida diseñada por los profesionales de
                    Convoca Deep Data y con información de diversas fuentes
                    oficiales.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/rapidez.png" class="img-fluid img-custom sm-width-image mr-3"
                    alt="..." />
                  <h4 class="text-left c-title">AHORRO y RAPIDEZ</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    Periodistas, investigadores, comunicadores y organizaciones,
                    pueden ahorrar tiempo y recursos: todo lo que querías saber
                    de las industrias extractivas en un solo lugar.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/convergencia.png" class="img-fluid img-custom sm-width-image mr-3"
                    alt="..." />
                  <h4 class="text-left c-title">CONVERGENCIA</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    Procesamos los datos más relevantes de las industrias
                    extractivas en Perú para que optimices tu análisis e
                    investigación desde más de 30 fuentes de información.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/experiencia.png" class="img-fluid img-custom sm-width-image mr-3"
                    alt="..." />
                  <h4 class="text-left c-title">EXPERIENCIA DE USUARIO</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    Cruza información con tus propios datos, interactúa con las
                    visualizaciones y explora por los diferentes filtros
                    disponibles con total facilidad. La plataforma puede
                    utilizarla cualquier persona que busque generar
                    conocimiento. Es simple y potente.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                  <img src="../../../assets/icons/alerta.png" class="img-fluid img-custom sm-width-image mr-3"
                    alt="..." />
                  <h4 class="text-left c-title">ALERTA</h4>
                </div>
                <div class="mt-2">
                  <p class="text-justify">
                    Obtén información actualizada sobre las empresas más
                    poderosas de las industrias extractivas, según nivel de
                    cumplimiento de las normas ambientales y laborales,
                    estructura empresarial, representantes, autorizaciones
                    otorgadas por el Estado, proyectos, entre otros datos.
                  </p>
                </div>
              </div>

              <!-- <div class="row m-0 col-sm-12">
                                <div class="col-sm-12 mb-3 p-0 text-left">
                                    <img src="../../../assets/icons/conocimiento.png" class="img-fluid img-custom mr-1 d-inline" alt="...">
                                    <h3 class="text-left c-title d-inline">CONOCIMIENTO</h3>
                                </div>
                                <div class="col-sm-12 p-0">
                                    <p class="text-justify">La plataforma es una fuente de generación de conocimiento de interés público que se puede obtener a través del cruce de información y análisis de datos que están organizados aquí.
                                    </p>
                                </div>
                            </div> -->

              <!-- <div class="row m-0 col-sm-12">
                                <div class="col-sm-12 mb-3 p-0 text-left d-flex jusfity-content-center">
                                    <img src="../../../assets/icons/experiencia.png" class="img-fluid img-custom mr-1 align-self-center" alt="...">
                                    <h3 class="text-left c-title align-self-center">EXPERIENCIA DE USUARIO</h3>
                                </div>
                                <div class="col-sm-12 p-0">
                                    <p class="text-justify">Cruza información con tus propios datos, interactúa con las visualizaciones y explora por los diferentes filtros.
                                    </p>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LABORATORIO DE DATOS -->
  <div class="jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir bg-white">
    <div class="container">
      <div class="row justify-content-md-center d-flex justify-content-between align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-12 p-0">
          <div class="jpg-innerbannercontent">
            <div class="col-sm-12 mt-4">
              <h2 class="text-left">Laboratorio de Datos</h2>
              <p class="text-justify mr-2 ml-2">
                Queremos compartir nuestro conocimiento y experiencia. Aprende
                con nosotros sobre cómo trabajar con datos: ofreceremos charlas,
                cursos, talleres y programas especializados. Aprenderás a
                acceder a los datos, analizarlos y poder contar historias de
                impacto público con nuevos ángulos.
              </p>
            </div>
            <br />
            <div class="col-sm-12">
              <div class="row col-sm-12 d-flex align-items-center m-0 p-0">
                <div class="col-md-12 d-flex justify-content-left align-items-center">
                  <img src="../../../assets/icons/cursos.png" class="img-fluid w-25" alt="..." />
                  <h3 class="text-left c-title f1-4">
                    Nuestros talleres, cursos y charlas
                  </h3>
                </div>
                <div class="col-md-12">
                  <a href="https://escuela.convoca.pe/" target="_blank">
                    <img src="../../../assets/buttons/vernuestros_cursos.png" class="img-fluid" alt="..." />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- INVESTIGAR -->
  <div class="jpg-haslayout jpg-innerbannerholder jpg-haslayout-descubrir bg-white">
    <div class="container-fluid p-0">
      <div class="row col-sm-12 m-0 p-0 mb-3">
        <h4 class="text-left c-title bold p-4 pt-0">
          Investigar con big data temas de impacto público:
        </h4>

        <img src="../../../assets/img/cdeppdata_16.webp" class="img-fluid" alt="..." />

        <p class="text-justify p-4 mb-0">
          Conoce la técnica, metodología y herramientas para procesar abundante
          información y cazar historias de interés público.
        </p>

        <div class="row col-sm-12 d-flex justify-content-center align-items-center m-0 p-0">
          <div class="col-md-12 text-center">
            <a href="https://escuela.convoca.pe/cursos/taller-investigar-con-big-data-temas-de-impacto-publico/"
              target="_blank">
              <img src="../../../assets/buttons/inscribete.png" class="img-fluid w-75" alt="..." width="220" />
            </a>
          </div>
        </div>
      </div>

      <div class="row col-sm-12 m-0 p-0 mb-3">
        <h4 class="text-left c-title bold p-4 pt-0">
          Herramientas digitales para el periodismo de datos:
        </h4>

        <img src="../../../assets/img/cdeppdata_14.webp" class="img-fluid" alt="..." />

        <p class="text-justify p-4 mb-0">
          Aplica herramientas para la extracción, limpieza, construcción,
          análisis y visualización de bases de datos para el periodismo.
        </p>

        <div class="row col-sm-12 d-flex justify-content-center align-items-center m-0 p-0">
          <div class="col-md-12 text-center">
            <a href="https://escuela.convoca.pe/cursos/herramientas-digitales-para-el-periodismo-de-datos/"
              target="_blank">
              <img src="../../../assets/buttons/inscribete.png" class="img-fluid w-75" alt="..." width="220" />
            </a>
          </div>
        </div>
      </div>

      <div class="row col-sm-12 m-0 p-0 mb-5">
        <h4 class="text-left c-title bold p-4 pt-0">
          Taller de aplicaciones web y visualización de datos:
        </h4>

        <img src="../../../assets/img/cdeppdata_12.webp" class="img-fluid" alt="..." />

        <p class="text-justify p-4 mb-0">
          Conoce cómo planificar y ejecutar un proyecto digital basado en datos
          y qué herramientas puedes utilizar para la visualización de hallazgos.
        </p>

        <div class="row col-sm-12 d-flex justify-content-center align-items-center m-0 p-0">
          <div class="col-md-12 text-center">
            <a href="https://escuela.convoca.pe/cursos/taller-de-aplicaciones-web-y-visualizacion-de-datos/"
              target="_blank">
              <img src="../../../assets/buttons/inscribete.png" class="img-fluid w-75" alt="..." width="220" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Home Responsive end-->

<!-- RESPONSIVE MOBILE END-->

<br />
