// MODULES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';

// COMPONENTS
import { CarouselComponent } from './components/carousel/carousel.component';
import { TableSorteableComponent } from './components/table-sorteable/table-sorteable.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SortableHeaderDirective } from './directives/sortable.directive';
import { EditModalComponent } from './components/edit-modal/edit-modal.component';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { SafehtmlPipe } from './pipes/safehtml.pipe';
import { ShowForRolesDirective } from './directives/show-for-roles.directive';

@NgModule({
  declarations: [
    CarouselComponent,
    TableSorteableComponent,
    EditModalComponent,

    SortableHeaderDirective,
    FileDragNDropDirective,
    ShowForRolesDirective,
    SafehtmlPipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgxSimpleTextEditorModule
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgxSimpleTextEditorModule,
    CarouselComponent,
    TableSorteableComponent,
    EditModalComponent,

    SortableHeaderDirective,
    FileDragNDropDirective,
    ShowForRolesDirective,
    SafehtmlPipe
  ]
})
export class SharedModule { }
