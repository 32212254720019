<!--Filter Results Strart-->
<div class="d-none d-lg-block jpg-haslayout">
  <!-- <div class="jpg-title">
      <h2>Filtrar resultados:</h2>
    </div> -->
  <div class="">
    <div class="jpg-categoryslidercontent">
      <div class="row">
        <div class="col-12 col-md pb-3 pb-lg-0 d-flex align-items-center">
          <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%">
            <!-- <h3>Actividad económica</h3> -->
            <h2 style="
                margin: 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 20px;
              ">
              Filtrar resultados:
            </h2>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-industry"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Actividad económica</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad"
                  (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                    {{ actividad }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-building-o"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Empresa</h3>
            <div class="form-group mb-0">
              <!-- <input type="text" placeholder="Empresa" id="organizacion" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()" (input)="filterEmpresas($event.target.value)"> -->

              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
              </ng-template>

              <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
                [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
                [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
                (keydown.enter)="realizarBusqueda()" />
              <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
              <div class="invalid-feedback" *ngIf="searchFailed">
                No encontramos coincidencias
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-map-marker"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Región</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion"
                  (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let region of cboRegiones" [ngValue]="region">
                    {{ region }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-calendar-o"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Año</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                    {{ anio }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md p-3 pt-lg-1 pb-lg-0">
          <div class="jpg-cattitle btn-limpiar">
            <button type="button" class="btn btn-dark text-uppercase font-weight-bold" (click)="limpiarFiltros()">
              Limpiar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Filter Results End-->

<!-- BANNER MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white banner-mobile">
          Descubre a las empresas mineras y de hidrocarburos en el Perú
        </h2>
        <p class="text-center text-white">Inicio / Búsquedas / Empresas</p>
      </div>
    </div>
  </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-12">
      <div class="row card p-4" style="background-color: #ececec">
        <div class="col-12 text-left p-0">
          <h4>Realiza tu búsqueda:</h4>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-building-o"></i> Empresa</label>
            <!-- <input type="text" class="w-100" placeholder="Empresa" id="organizacion-mb" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()"> -->

            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>

            <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
              [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
              [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
              (keydown.enter)="realizarBusqueda()" />
            <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
            <div class="invalid-feedback" *ngIf="searchFailed">
              No encontramos coincidencias
            </div>
          </div>
          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-industry"></i> Actividad económica</label>
            <select id="cboActividad" class="w-100" name="cboActividad" [(ngModel)]="selectedActividad"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                {{ actividad }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-map-marker"></i> Región</label>
            <select id="cboRegion" class="w-100" name="cboRegion" [(ngModel)]="selectedRegion"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let region of cboRegiones" [ngValue]="region">
                {{ region }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-calendar-o"></i> Año</label>
            <select id="cboAnio" class="w-100" name="cboAnio" [(ngModel)]="selectedAnio"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                {{ anio }}
              </option>
            </select>
          </div>

          <div class="form-group d-flex justify-content-between mt-2">
            <button type="button" class="btn btn-dark text-uppercase w-100 mr-2" (click)="limpiarFiltros()">
              Limpiar
            </button>
            <!-- <button type="button" class="btn btn-danger text-uppercase w-100 ml-2"
                            [routerLink]='["/busqueda-masiva"]'>BÚSQUEDA MASIVA</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="empresa" class="d-block d-lg-none container-fluid card" style="background-color: #f9f8f8">
  <div class="row pt-4 pl-4 pr-4">
    <div class="col-8 text-left p-0 pr-2">
      <h5>{{ empresa.NombreEmpresa }}</h5>
      <span class="text-success font-weight-bold">
        <i class="fa fa-building"></i> {{ empresa.Estado }}
      </span>
      <span> RUC: {{ empresa.Ruc }} </span>
    </div>
    <div class="col-4 text-left p-0">
      <p class="mb-1">Inicio de actividades:</p>
      <span>{{ empresa.InicioActividades }}</span>
    </div>
  </div>

  <!-- <div class="row pt-2 pl-4 pr-2 pb-4">
        <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2">Empresa muy infractora
    </div> -->

  <div class="row p-4">
    <div class="col-12 text-left p-0">
      <h5>>> Indicadores</h5>
      <select class="w-100" id="cboIndicadores" name="cboIndicadores" [(ngModel)]="selectedIndicador">
        <option *ngFor="let indicador of cboIndicadores" [ngValue]="indicador">
          {{ indicador.id }}
        </option>
      </select>
    </div>
  </div>
</div>

<!-- CARDS REPLACING TABLE -->

<!-- AMBIENTALES -->
<div *ngIf="selectedIndicador.value === '1'">
  <div class="d-block d-lg-none" *ngIf="infraccionesAmbientales">
    <!-- MAIN -->
    <div *ngIf="infraccionesAmbientales.length > 0 && !detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <a class="text-dark d-block" href="javascript:void(0)">
          <div class="row pt-3 pl-0 pr-0">
            <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
              <div class="col-5 p-1 pr-3">
                <p class="mt-2 mb-2">Lote/Unidad Ambiental</p>
              </div>
              <div class="col-7 p-1 pr-3">
                <p class="mt-2 mb-2">Infracción</p>
              </div>
            </div>
          </div>

          <div *ngFor="let a of infraccionesAmbientales" class="row p-0 div-card-row">
            <div class="col-12 font-weight-bold d-flex align-items-center">
              <div class="col-5 p-1 pr-3">
                <p class="mt-3 mb-3">{{ a.unidad_minera }}</p>
              </div>
              <div class="col-5 p-1 pr-3 aling-self-content">
                <p class="mt-3 mb-3">{{ a.infraccion }}</p>
              </div>
              <div class="col-2 p-1 pr-3 text-right">
                <button style="background: none; color: #dc3545" (click)="verDetalleRow(a)">
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- NO ROWS -->
    <div *ngIf="infraccionesAmbientales.length <= 0" class="mb-4">
      <div class="container-fluid card p-3" style="font-size: 0.8rem">
        <p class="mb-0 text-center">
          <b>No se encontraron registros con los criterios ingresados.</b>
        </p>
      </div>
    </div>

    <!-- PAGINATION -->
    <div *ngIf="infraccionesAmbientales.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination [collectionSize]="infraccionesAmbientalesCollectionSize" [(page)]="infraccionesAmbientalesPage"
          [pageSize]="infraccionesAmbientalesPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true"
          [rotate]="true" (pageChange)="refreshData(1); window.scrollTo(0, 0)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>
            < </ng-template>
              <ng-template ngbPaginationNext>></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <!-- DETALLE -->
    <div *ngIf="infraccionesAmbientales.length > 0 && detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <div class="row pt-3 pl-0 pr-0">
          <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
            <div class="col-2 p-1 pr-3 text-left">
              <button style="background: none; color: #dc3545" (click)="volverListaDetalle()">
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-10 p-1 pr-3">
              <div class="row">
                <div class="col-6 p-1 pr-3 d-flex align-items-center">
                  <p class="mt-2 mb-2">Lote/Unidad Ambiental</p>
                </div>
                <div class="col-6 p-1 pr-3 d-flex align-items-center">
                  <p class="mt-2 mb-2">Infracción</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 p-1 pr-3">
                  <p class="mt-2 mb-2">{{ selectedRowDetail.unidad_minera }}</p>
                </div>
                <div class="col-6 p-1">
                  <p class="mt-2 mb-2">{{ selectedRowDetail.infraccion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Ubicación</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.region }},
                {{ selectedRowDetail.provincia }},
                {{ selectedRowDetail.distrito }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Estado</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.estado }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de sancion</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a *ngIf="
                  selectedRowDetail.url_sancion &&
                  selectedRowDetail.url_sancion != null
                " [href]="selectedRowDetail.url_sancion" target="_blank">{{ selectedRowDetail.fec_sancion }}</a>
              <p *ngIf="
                  !(
                    selectedRowDetail.url_sancion &&
                    selectedRowDetail.url_sancion != null
                  )
                " class="mt-3 mb-3">
                {{ selectedRowDetail.fec_sancion }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de reconsideración</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a *ngIf="selectedRowDetail.url_reconsideracion?.trim()" [href]="selectedRowDetail.url_reconsideracion"
                target="_blank">
                {{ selectedRowDetail.fec_reconsideracion }}
              </a>
              <p *ngIf="!selectedRowDetail.url_reconsideracion?.trim()" class="mt-3 mb-3">
                {{ selectedRowDetail.fec_reconsideracion }}
              </p>
            </div>

          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de apelación</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a *ngIf="
                  selectedRowDetail.url_apelacion &&
                  selectedRowDetail.url_apelacion != null
                " [href]="selectedRowDetail.url_apelacion" target="_blank">{{ selectedRowDetail.fec_apelacion }}</a>
              <p *ngIf="
                  !(
                    selectedRowDetail.url_apelacion &&
                    selectedRowDetail.url_apelacion != null
                  )
                " class="mt-3 mb-3">
                {{ selectedRowDetail.fec_apelacion }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Multa (S/)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                selectedRowDetail.multa_soles
                ? (selectedRowDetail.multa_soles | number: "1.2-2")
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Multa ($)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                selectedRowDetail.multa_dolares
                ? (selectedRowDetail.multa_dolares | number: "1.2-2")
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Multa (UIT)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                selectedRowDetail.multa_uit
                ? (selectedRowDetail.multa_uit | number: "1.2-2")
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESCARGAR -->
    <div class="container-fluid">
      <div class="row pr-4 pl-4 pb-4">
        <div *ngIf="
            _usuarioService.isPremium() && infraccionesAmbientales?.length > 0
          " class="col-12 col-sm-6 d-flex">
          <a href="javascript:void(0);" (click)="descargarResultados(1)"
            class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2">
            <i class="fa fa-download"></i> Descargar</a>
        </div>
        <div class="col-12 col-sm-6 align-self-center">
          <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
            class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- LABORALES -->
<div *ngIf="selectedIndicador.value === '2'">
  <div class="d-block d-lg-none" *ngIf="infraccionesLaborales">
    <!-- MAIN -->
    <div *ngIf="infraccionesLaborales.length > 0 && !detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <a class="text-dark d-block" href="javascript:void(0)">
          <div class="row pt-3 pl-0 pr-0">
            <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
              <div class="col-4 p-1 pr-3">
                <p class="mt-2 mb-2">Ubicación</p>
              </div>
              <div class="col-8 p-1 pr-3">
                <p class="mt-2 mb-2">Infracción</p>
              </div>
            </div>
          </div>

          <div *ngFor="let a of infraccionesLaborales" class="row p-0 div-card-row">
            <div class="col-12 font-weight-bold d-flex align-items-center">
              <div class="col-4 p-1 pr-3 aling-self-content">
                <p class="mt-3 mb-3">
                  {{ a.region }}, {{ a.provincia }}, {{ a.distrito }}
                </p>
              </div>
              <div class="col-6 p-1 pr-3">
                <p class="mt-3 mb-3">{{ a.infraccion }}</p>
              </div>
              <div class="col-2 p-1 pr-3 text-right">
                <button style="background: none; color: #dc3545" (click)="verDetalleRow(a)">
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- NO ROWS -->
    <div *ngIf="infraccionesLaborales.length <= 0" class="mb-4">
      <div class="container-fluid card p-3" style="font-size: 0.8rem">
        <p class="mb-0 text-center">
          <b>No se encontraron registros con los criterios ingresados.</b>
        </p>
      </div>
    </div>

    <!-- PAGINATION -->
    <div *ngIf="infraccionesLaborales.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination [collectionSize]="infraccionesLaboralesCollectionSize" [(page)]="infraccionesLaboralesPage"
          [pageSize]="infraccionesLaboralesPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true"
          [rotate]="true" (pageChange)="refreshData(2); window.scrollTo(0, 0)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>
            < </ng-template>
              <ng-template ngbPaginationNext>></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <!-- DETALLE -->
    <div *ngIf="infraccionesLaborales.length > 0 && detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <div class="row pt-3 pl-0 pr-0">
          <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
            <div class="col-2 p-1 pr-3 text-left">
              <button style="background: none; color: #dc3545" (click)="volverListaDetalle()">
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-10 p-1 pr-3">
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-2 mb-2">Ubicación</p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-2 mb-2">Infracción</p>
                </div>
              </div>
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-3 mb-3">
                    {{ selectedRowDetail.region }},
                    {{ selectedRowDetail.provincia }},
                    {{ selectedRowDetail.distrito }}
                  </p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-3 mb-3">{{ selectedRowDetail.infraccion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Número de expediente</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.nro_expediente }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución 1ra instancia</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a *ngIf="
                  selectedRowDetail.url_expediente &&
                  selectedRowDetail.url_expediente != null
                " [href]="selectedRowDetail.url_expediente" target="_blank">{{ selectedRowDetail.fec_primera_instancia
                }}</a>
              <p *ngIf="
                  !(
                    selectedRowDetail.url_expediente &&
                    selectedRowDetail.url_expediente != null
                  )
                " class="mt-3 mb-3">
                {{ selectedRowDetail.fec_primera_instancia }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución 2da instancia</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a *ngIf="
                  selectedRowDetail.url_expediente &&
                  selectedRowDetail.url_expediente != null
                " [href]="selectedRowDetail.url_expediente" target="_blank">{{ selectedRowDetail.fec_segunda_instancia
                }}</a>
              <p *ngIf="
                  !(
                    selectedRowDetail.url_expediente &&
                    selectedRowDetail.url_expediente != null
                  )
                " class="mt-3 mb-3">
                {{ selectedRowDetail.fec_segunda_instancia }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Multa (S/)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                selectedRowDetail.multa_soles
                ? (selectedRowDetail.multa_soles | number: "1.2-2")
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Multa ($)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                selectedRowDetail.multa_dolares
                ? (selectedRowDetail.multa_dolares | number: "1.2-2")
                : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESCARGAR -->
    <div class="container-fluid">
      <div class="row pr-4 pl-4 pb-4">
        <div *ngIf="_usuarioService.isPremium() && derrames?.length > 0" class="col-12 col-sm-6 d-flex">
          <a href="javascript:void(0);" (click)="descargarResultados(2)"
            class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2">
            <i class="fa fa-download"></i> Descargar</a>
        </div>
        <div class="col-12 col-sm-6 align-self-center">
          <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
            class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MULTAS PERDONADAS -->
<div *ngIf="selectedIndicador.value === '3'">
  <div class="d-block d-lg-none" *ngIf="multasPerdonadas">
    <!-- MAIN -->
    <div *ngIf="multasPerdonadas.length > 0 && !detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <a class="text-dark d-block" href="javascript:void(0)">
          <div class="row pt-3 pl-0 pr-0">
            <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
              <div class="col-4 p-1 pr-3">
                <p class="mt-2 mb-2">Lote/Unidad Ambiental</p>
              </div>
              <div class="col-8 p-1 pr-3">
                <p class="mt-2 mb-2">Infracción</p>
              </div>
            </div>
          </div>

          <div *ngFor="let a of multasPerdonadas" class="row p-0 div-card-row">
            <div class="col-12 font-weight-bold d-flex align-items-center">
              <div class="col-4 p-1 pr-3 aling-self-content">
                <p class="mt-3 mb-3">{{ a.unidad_minera }}</p>
              </div>
              <div class="col-6 p-1 pr-3">
                <p class="mt-3 mb-3">{{ a.infraccion }}</p>
              </div>
              <div class="col-2 p-1 pr-3 text-right">
                <button style="background: none; color: #dc3545" (click)="verDetalleRow(a)">
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- NO ROWS -->
    <div *ngIf="multasPerdonadas.length <= 0" class="mb-4">
      <div class="container-fluid card p-3" style="font-size: 0.8rem">
        <p class="mb-0 text-center">
          <b>No se encontraron registros con los criterios ingresados.</b>
        </p>
      </div>
    </div>

    <!-- PAGINATION -->
    <div *ngIf="multasPerdonadas.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination [collectionSize]="multasPerdonadasCollectionSize" [(page)]="multasPerdonadasPage"
          [pageSize]="multasPerdonadasPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [rotate]="true"
          (pageChange)="refreshData(3); window.scrollTo(0, 0)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>
            < </ng-template>
              <ng-template ngbPaginationNext>></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <!-- DETALLE -->
    <div *ngIf="multasPerdonadas.length > 0 && detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <div class="row pt-3 pl-0 pr-0">
          <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
            <div class="col-2 p-1 pr-3 text-left">
              <button style="background: none; color: #dc3545" (click)="volverListaDetalle()">
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-10 p-1 pr-3">
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-2 mb-2">Lote/Unidad Ambiental</p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-2 mb-2">Infracción</p>
                </div>
              </div>
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-2 mb-2">{{ selectedRowDetail.unidad_minera }}</p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-2 mb-2">{{ selectedRowDetail.infraccion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Empresa</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.nombre_empresa }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">RUC</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.ruc }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Ubicación</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.region }},
                {{ selectedRowDetail.provincia }},
                {{ selectedRowDetail.distrito }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Nro. Expediente</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.nro_expediente }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de sancion</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a [href]="selectedRowDetail.url_sancion" target="_blank">{{
                selectedRowDetail.fec_sancion
                }}</a>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de reconsideración</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a [href]="selectedRowDetail.url_reconsideracion" target="_blank">{{ selectedRowDetail.fec_reconsideracion
                }}</a>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución de apelación</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <a [href]="selectedRowDetail.url_apelacion" target="_blank">{{
                selectedRowDetail.fec_apelacion
                }}</a>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto básico (S/)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.monto_basico_soles }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto máximo (S/)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.monto_maximo_soles }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto básico ($)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.monto_basico_dolares }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto máximo ($)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.monto_maximo_dolares }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto básico (UIT)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.monto_basico_uit }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto máximo (UIT)</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.monto_maximo_uit }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESCARGAR -->
    <div class="container-fluid">
      <div class="row pr-4 pl-4 pb-4">
        <div *ngIf="_usuarioService.isPremium() && derrames?.length > 0" class="col-12 col-sm-6 d-flex">
          <a href="javascript:void(0);" (click)="descargarResultados(2)"
            class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2">
            <i class="fa fa-download"></i> Descargar</a>
        </div>
        <div class="col-12 col-sm-6 align-self-center">
          <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
            class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MULTAS JUDICIALIZADAS -->
<div *ngIf="selectedIndicador.value === '4'">
  <div class="d-block d-lg-none" *ngIf="multasJudicializadas">
    <!-- MAIN -->
    <div *ngIf="multasJudicializadas.length > 0 && !detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <a class="text-dark d-block" href="javascript:void(0)">
          <div class="row pt-3 pl-0 pr-0">
            <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
              <div class="col-6 p-1 pr-3">
                <p class="mt-2 mb-2">Materia incumplida</p>
              </div>
              <div class="col-6 p-1 pr-3">
                <p class="mt-2 mb-2">Número de expediente judicial</p>
              </div>
            </div>
          </div>

          <div *ngFor="let a of multasJudicializadas" class="row p-0 div-card-row">
            <div class="col-12 font-weight-bold d-flex align-items-center">
              <div class="col-6 p-1 pr-3 aling-self-content">
                <p class="mt-3 mb-3">{{ a.materia_incumplida }}</p>
              </div>
              <div class="col-4 p-1 pr-3">
                <p class="mt-3 mb-3">{{ a.expediente_judicial }}</p>
              </div>
              <div class="col-2 p-1 pr-3 text-right">
                <button style="background: none; color: #dc3545" (click)="verDetalleRow(a)">
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- NO ROWS -->
    <div *ngIf="multasJudicializadas.length <= 0" class="mb-4">
      <div class="container-fluid card p-3" style="font-size: 0.8rem">
        <p class="mb-0 text-center">
          <b>No se encontraron registros con los criterios ingresados.</b>
        </p>
      </div>
    </div>

    <!-- PAGINATION -->
    <div *ngIf="multasJudicializadas.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination [collectionSize]="multasJudicializadasCollectionSize" [(page)]="multasJudicializadasPage"
          [pageSize]="multasJudicializadasPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true"
          [rotate]="true" (pageChange)="refreshData(4); window.scrollTo(0, 0)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>
            < </ng-template>
              <ng-template ngbPaginationNext>></ng-template>
              <ng-template ngbPaginationEllipsis>...</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <!-- DETALLE -->
    <div *ngIf="multasJudicializadas.length > 0 && detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: 0.8rem">
        <div class="row pt-3 pl-0 pr-0">
          <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
            <div class="col-2 p-1 pr-3 text-left">
              <button style="background: none; color: #dc3545" (click)="volverListaDetalle()">
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-10 p-1 pr-3">
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-2 mb-2">Materia incumplida</p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-2 mb-2">Número de expediente judicial</p>
                </div>
              </div>
              <div class="row">
                <div class="col-5 p-1 pr-3">
                  <p class="mt-2 mb-2">
                    {{ selectedRowDetail.materia_incumplida }}
                  </p>
                </div>
                <div class="col-7 p-1 pr-3">
                  <p class="mt-2 mb-2">
                    {{ selectedRowDetail.expediente_judicial }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resolución Impugnada</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.resolucion_impugnada }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Fecha de sanción</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.fecha_sancion }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto de multa judicializada</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                formatdatanumber(
                selectedRowDetail.multa_judicializada_soles
                ? selectedRowDetail.multa_judicializada_soles
                : 0
                )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Monto de multa judicializada $</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{
                formatdatanumber(
                selectedRowDetail.multa_judicializada_dolares
                ? selectedRowDetail.multa_judicializada_dolares
                : 0
                )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Estado del proceso</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">{{ selectedRowDetail.estado_procesal }}</p>
            </div>
          </div>
        </div>
        <div class="row p-0 div-card-row">
          <div class="col-12 font-weight-bold d-flex align-items-center">
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="mt-3 mb-3">Resultado del proceso</p>
            </div>
            <div class="col-7 p-1 pr-3">
              <p class="mt-3 mb-3">
                {{ selectedRowDetail.resultado_del_proceso }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESCARGAR -->
    <div class="container-fluid">
      <div class="row pr-4 pl-4 pb-4">
        <div *ngIf="_usuarioService.isPremium() && derrames?.length > 0" class="col-12 col-sm-6 d-flex">
          <a href="javascript:void(0);" (click)="descargarResultados(3)"
            class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2">
            <i class="fa fa-download"></i> Descargar</a>
        </div>
        <div class="col-12 col-sm-6 align-self-center">
          <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
            class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- END CARDS REPLACING TABLE -->

<!--Main Start-->
<div class="d-none d-lg-block">
  <main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
    <!-- Semaforo de empresa Start-->

    <div class="jpg-haslayout">
      <div class="container-fluid">
        <!--Company Details Start-->
        <div class="row mb-3">
          <div class="col-xs-12 col-sm-12 col-md-12 float-left">
            <div class="card border-0 jpg-bgwhite jpg-haslayout">
              <div class="card-body">
                <div class="row align-items-center">
                  <div *ngIf="empresa" class="col">
                    <h2>{{ empresa.NombreEmpresa }}</h2>
                    <ul class="jpg-userlisting-breadcrumb jpg-userlisting-breadcrumbvtwo">
                      <li>
                        <span class="text-success font-weight-bold">
                          <i class="fa fa-building"></i>{{ empresa.Estado }}</span>
                      </li>
                      <li>
                        <span>
                          <i class="fa fa-list-alt"></i>
                          RUC: {{ empresa.Ruc }}</span>
                      </li>
                      <li>
                        <span><i class="fa fa-calendar-o"></i> INICIO DE
                          ACTIVIDADES: {{ empresa.InicioActividades }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-auto align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
                      class="btn btn-danger text-uppercase font-weight-bold">Regresar</a>
                  </div>
                </div>
                <div class="jpg-widgettitle mt-2 w-100"></div>

                <!--Tabs infranctions Start-->

                <ul class="jpg-navarticletab jpg-navarticletabvthree nav navbar-nav">
                  <li class="nav-item mr-3">
                    <a class="" [ngClass]="indicador == 1 ? 'active' : ''" id="all-tab" data-toggle="tab"
                      href="#tab01">Infracciones ambientales</a>
                  </li>
                  <li class="nav-item mr-3">
                    <a id="business-tab" [ngClass]="indicador == 2 ? 'active' : ''" data-toggle="tab"
                      href="#tab02">Infracciones laborales</a>
                  </li>
                  <li class="nav-item mr-3">
                    <!-- <a id="trading-tab" data-toggle="tab" href="#tab03">Multas perdonadas</a> -->
                    <!-- <a id="trading-tab" href="javascript:void(0);" (click)="_alertService.verModalMultas()">Multas perdonadas</a> -->
                  </li>
                  <li class="nav-item mr-3">
                    <a id="trading-tab" [ngClass]="indicador == 4 ? 'active' : ''" data-toggle="tab"
                      href="#tab04">Multas judicializadas</a>
                    <!-- <a id="trading-tab" href="javascript:void(0);" (click)="_alertService.verModalMultas()">Multas perdonadas</a> -->
                  </li>
                </ul>

                <div class="tab-content jpg-haslayout jpg-haslayout-gray">
                  <!--Infracciones ambientales-->
                  <div class="jpg-contentarticle tab-pane fade" [ngClass]="indicador == 1 ? 'active show' : ''"
                    id="tab01">
                    <div class="row">
                      <div class="semaforoInfracciones mt-4 pl-5 badge badge-pill badge-semaforo col-md-6">
                        <div class="d-flex align-self-center align-items-center">
                          <img [src]="
                              empresa.IndInfAmbAccSemaforo
                                ? '../../../../assets/img/' +
                                  empresa.IndInfAmbAccSemaforo +
                                  '.png'
                                : ''
                            " alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                          <label *ngIf="empresa.IndInfAmbAccSemaforo === 'red'" class="mr-2">Empresa muy
                            infractora</label>
                          <label *ngIf="empresa.IndInfAmbAccSemaforo === 'orange'" class="mr-2">Empresa frecuentemente
                            infractora</label>
                          <label *ngIf="empresa.IndInfAmbAccSemaforo === 'yellow'" class="mr-2">Empresa medianamente
                            infractora</label>
                          <label *ngIf="empresa.IndInfAmbAccSemaforo === 'green'" class="mr-2">Empresa poco
                            infractora</label>
                          <label *ngIf="empresa.IndInfAmbAccSemaforo === 'gray'" class="mr-2">Empresa sin procesos de
                            sanción</label>
                        </div>
                      </div>

                      <div *ngIf="
                          _usuarioService.isPremium() &&
                          infraccionesAmbientales?.length > 0
                        " class="col-md-6 mt-4 pr-5 text-right">
                        <a href="javascript:void(0);" (click)="descargarResultados(1)"
                          class="btn btn-dark text-uppercase font-weight-bold px-5">
                          <i class="fa fa-download"></i> Descargar</a>
                      </div>
                      <br />
                    </div>

                    <div class="row">
                      <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="table-responsive">
                            <table *ngIf="infraccionesAmbientales"
                              class="table table-striped jpg-tablecategories tbl-nivel3">
                              <thead>
                                <tr>
                                  <!-- <th class="text-center">Nro. Expediente</th> -->
                                  <!-- <th class="text-center">Empresa</th>
                                                                    <th class="text-center">RUC</th> -->
                                  <th class="text-center">Ubicación</th>
                                  <th class="text-center">
                                    Lote/Unidad Ambiental
                                  </th>
                                  <th class="text-center">Infracción</th>
                                  <th class="text-center">Estado</th>
                                  <th>Resolución de sancion</th>
                                  <th>Resolución de reconsideración</th>
                                  <th>Resolución de apelación</th>
                                  <th>Multa (S/)</th>
                                  <th>Multa ($)</th>
                                  <th>Multa (UIT)</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="infraccionesAmbientales?.length > 0">
                                <tr *ngFor="let a of infraccionesAmbientales">
                                  <!-- <td class="text-left">{{ a.nro_expediente}}</td> -->
                                  <!-- <td class="text-left">{{ a.nombre_empresa}}</td>
                                                                    <td class="text-left">{{ a.ruc}}</td> -->
                                  <td class="text-left">
                                    {{
                                    a.region +
                                    ", " +
                                    a.provincia +
                                    ", " +
                                    a.distrito
                                    }}
                                  </td>
                                  <td class="text-left">
                                    {{ a.unidad_minera }}
                                  </td>
                                  <td class="text-left">{{ a.infraccion }}</td>
                                  <td class="text-left">{{ a.estado }}</td>

                                  <td class="text-center">
                                    <!-- Enlace válido con URL válida -->
                                    <a class="text-primary" *ngIf="validarUrl(a.url_sancion)" [href]="a.url_sancion"
                                      target="_blank">
                                      {{ a.fec_sancion }}
                                    </a>
                                    <span *ngIf="!validarUrl(a.url_sancion)">
                                      {{ a.fec_sancion }}
                                    </span>
                                  </td>

                                  <td class="text-center ">
                                    <!-- <a [href]="a.url_reconsideracion" target="_blank">{{ a.fec_reconsideracion }}</a> -->
                                    <a class="text-primary" *ngIf="validarUrl(a.url_reconsideracion)"
                                      [href]="a.url_reconsideracion" target="_blank">
                                      {{ a.fec_reconsideracion }}
                                    </a>

                                    <span *ngIf="!validarUrl(a.url_reconsideracion)">
                                      {{ a.fec_reconsideracion }}
                                    </span>
                                  </td>
                                  <td class="text-center ">
                                    <!-- <a [href]="a.url_apelacion" target="_blank">{{ a.fec_apelacion }}</a> -->
                                    <a class="text-primary"
                                      *ngIf="a.url_apelacion?.trim() && validarUrl(a.url_apelacion)"
                                      [href]="a.url_apelacion" target="_blank">
                                      {{ a.fec_apelacion }}
                                    </a>

                                    <span *ngIf="!a.url_apelacion?.trim() || !validarUrl(a.url_apelacion)">
                                      {{ a.fec_apelacion }}
                                    </span>
                                  </td>

                                  <td class="text-center">
                                    {{
                                    a.multa_soles
                                    ? (a.multa_soles | number: "1.2-2")
                                    : ""
                                    }}
                                  </td>
                                  <td class="text-center">
                                    {{
                                    a.multa_dolares
                                    ? (a.multa_dolares | number: "1.2-2")
                                    : ""
                                    }}
                                  </td>
                                  <td class="text-center">
                                    {{
                                    a.multa_uit
                                    ? (a.multa_uit | number: "1.2-2")
                                    : ""
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="infraccionesAmbientales?.length <= 0">
                                <tr>
                                  <td colspan="12">
                                    No se encontraron registros.
                                  </td>
                                </tr>
                              </tbody>

                              <div *ngIf="
                                  infraccionesAmbientales.length > 0 &&
                                  !verCifras
                                " class="col-sm-12 p-0 mt-4 mb-4">
                                <div class="d-flex justify-content-left">
                                  <ngb-pagination [collectionSize]="
                                      infraccionesAmbientalesCollectionSize
                                    " [(page)]="infraccionesAmbientalesPage"
                                    [pageSize]="infraccionesAmbientalesPageSize" [ellipses]="false" [maxSize]="5"
                                    [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(1)">
                                    <ng-template ngbPaginationFirst>Primero</ng-template>
                                    <ng-template ngbPaginationLast>Último</ng-template>
                                    <ng-template ngbPaginationPrevious>
                                      < </ng-template>
                                        <ng-template ngbPaginationNext>></ng-template>
                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{
                                          page
                                          }}</ng-template>
                                  </ngb-pagination>
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#infraccionesambientales">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Indicador de incumplimiento
                                        </button> -->

                    <a class="link-nivel-3" href="https://convoca.pe/deepdata/metodologia" target="_blank"><i
                        class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      Indicador de incumplimiento</a>
                  </div>

                  <!--Infracciones laborales-->
                  <div class="jpg-contentarticle tab-pane fade" [ngClass]="indicador == 2 ? 'active show' : ''"
                    id="tab02">
                    <div class="row">
                      <div class="semaforoInfracciones mt-4 pl-5 badge badge-pill badge-semaforo col-md-6">
                        <div class="d-flex align-self-center align-items-center">
                          <img [src]="
                              empresa.IndInfLabAccSemaforo
                                ? '../../../../assets/img/' +
                                  empresa.IndInfLabAccSemaforo +
                                  '.png'
                                : ''
                            " alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                          <label *ngIf="empresa.IndInfLabAccSemaforo === 'red'" class="mr-2">Empresa muy
                            infractora</label>
                          <label *ngIf="empresa.IndInfLabAccSemaforo === 'orange'" class="mr-2">Empresa frecuentemente
                            infractora</label>
                          <label *ngIf="empresa.IndInfLabAccSemaforo === 'yellow'" class="mr-2">Empresa medianamente
                            infractora</label>
                          <label *ngIf="empresa.IndInfLabAccSemaforo === 'green'" class="mr-2">Empresa poco
                            infractora</label>
                          <label *ngIf="empresa.IndInfLabAccSemaforo === 'gray'" class="mr-2">Empresa sin procesos de
                            sanción</label>
                        </div>
                      </div>
                      <div *ngIf="
                          _usuarioService.isPremium() &&
                          infraccionesLaborales?.length > 0
                        " class="col-md-6 mt-4 pr-5 text-right">
                        <a href="javascript:void(0);" (click)="descargarResultados(2)"
                          class="btn btn-dark text-uppercase font-weight-bold px-5">
                          <i class="fa fa-download"></i> Descargar</a>
                      </div>
                      <br />
                    </div>

                    <div class="row">
                      <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="table-responsive">
                            <table *ngIf="infraccionesLaborales" class="table table-striped jpg-tablecategories">
                              <thead>
                                <tr>
                                  <th class="text-center">Ubicación</th>
                                  <th class="text-center">Infracción</th>
                                  <th class="text-center">
                                    Número de expediente
                                  </th>
                                  <th class="text-center">
                                    Resolución 1ra instancia
                                  </th>
                                  <th class="text-center">
                                    Resolución 2da instancia
                                  </th>
                                  <th class="text-center">Multa (S/)</th>
                                  <th class="text-center">Multa ($)</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="infraccionesLaborales?.length > 0">
                                <tr *ngFor="let l of infraccionesLaborales">
                                  <td class="text-left">
                                    {{
                                    l.region +
                                    ", " +
                                    l.provincia +
                                    ", " +
                                    l.distrito
                                    }}
                                  </td>
                                  <td class="text-left">{{ l.infraccion }}</td>
                                  <td class="text-center">
                                    {{ l.nro_expediente }}
                                  </td>
                                  <td class="text-center">
                                    <a class="text-primary" *ngIf="l.url_expediente && l.url_expediente.trim() !== ''"
                                      [href]="l.url_expediente" target="_blank">
                                      {{ l.fec_primera_instancia }}
                                    </a>
                                    <span *ngIf="!l.url_expediente || l.url_expediente.trim() === ''">
                                      {{ l.fec_primera_instancia }}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    <!-- <a [href]="l.url_expediente" target="_blank">{{ l.fec_segunda_instancia }}</a> -->
                                    <a class="text-primary" *ngIf="l.url_expediente && l.url_expediente.trim() !== ''"
                                      [href]="l.url_expediente" target="_blank">
                                      {{ l.fec_segunda_instancia }}
                                    </a>
                                    <span *ngIf="!l.url_expediente || l.url_expediente.trim() === ''">
                                      {{ l.fec_segunda_instancia }}
                                    </span>
                                  </td>
                                  <!-- <td class="text-center">{{ l.multa_soles }}</td> -->
                                  <td class="text-center">
                                    {{
                                    l.multa_soles
                                    ? (l.multa_soles | number: "1.2-2")
                                    : ""
                                    }}
                                  </td>
                                  <!-- <td class="text-center">{{ l.multa_dolares }}</td> -->
                                  <td class="text-center">
                                    {{
                                    l.multa_dolares
                                    ? (l.multa_dolares | number: "1.2-2")
                                    : ""
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="infraccionesLaborales?.length <= 0">
                                <tr>
                                  <td colspan="7">
                                    No se encontraron registros.
                                  </td>
                                </tr>
                              </tbody>

                              <div *ngIf="
                                  infraccionesLaborales.length > 0 && !verCifras
                                " class="col-sm-12 p-0 mt-4 mb-4">
                                <div class="d-flex justify-content-left">
                                  <ngb-pagination [collectionSize]="
                                      infraccionesLaboralesCollectionSize
                                    " [(page)]="infraccionesLaboralesPage" [pageSize]="infraccionesLaboralesPageSize"
                                    [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
                                    (pageChange)="refreshData(2)">
                                    <ng-template ngbPaginationFirst>Primero</ng-template>
                                    <ng-template ngbPaginationLast>Último</ng-template>
                                    <ng-template ngbPaginationPrevious>
                                      < </ng-template>
                                        <ng-template ngbPaginationNext>></ng-template>
                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{
                                          page
                                          }}</ng-template>
                                  </ngb-pagination>
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <a class="link-nivel-3" href="https://convoca.pe/deepdata/metodologia" target="_blank"><i
                        class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      Indicador de incumplimiento</a>
                  </div>

                  <!--Multas perdonadas-->
                  <div class="jpg-contentarticle tab-pane fade" id="tab03">
                    <div *ngIf="
                        _usuarioService.isPremium() &&
                        multasPerdonadas?.length > 0
                      ">
                      <div class="col-md-12 text-right">
                        <br />
                        <a href="javascript:void(0);" (click)="descargarResultados(3)"
                          class="btn btn-dark text-uppercase font-weight-bold px-5">
                          <i class="fa fa-download"></i> Descargar</a>
                      </div>
                    </div>

                    <div class="row">
                      <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="table-responsive">
                            <table *ngIf="multasPerdonadas" class="table table-striped jpg-tablecategories tbl-nivel3">
                              <thead>
                                <tr>
                                  <th rowspan="2" class="text-left">
                                    Nro. Expediente
                                  </th>
                                  <th rowspan="2" class="text-left">Empresa</th>
                                  <th rowspan="2" class="text-left">RUC</th>
                                  <th rowspan="2" class="text-left">
                                    Ubicación
                                  </th>
                                  <th rowspan="2" class="text-left">
                                    Lote/Unidad Ambiental
                                  </th>
                                  <th rowspan="2" class="text-left">
                                    Infracción
                                  </th>
                                  <th rowspan="2">Resolución de sancion</th>
                                  <th rowspan="2">
                                    Resolución de reconsideración
                                  </th>
                                  <th rowspan="2">Resolución de apelación</th>
                                  <th colspan="6" class="text-center">
                                    Monto perdonado
                                  </th>
                                </tr>
                                <tr>
                                  <th>Monto básico (S/)</th>
                                  <th>Monto máximo (S/)</th>
                                  <th>Monto básico ($)</th>
                                  <th>Monto máximo ($)</th>
                                  <th>Monto básico (UIT)</th>
                                  <th>Monto máximo (UIT)</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="multasPerdonadas?.length > 0">
                                <tr *ngFor="let m of multasPerdonadas">
                                  <td class="text-left">
                                    {{ m.nro_expediente }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.nombre_empresa }}
                                  </td>
                                  <td class="text-left">{{ m.ruc }}</td>
                                  <td class="text-left">
                                    {{
                                    m.region +
                                    ", " +
                                    m.provincia +
                                    ", " +
                                    m.distrito
                                    }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.unidad_minera }}
                                  </td>
                                  <td class="text-left">{{ m.infraccion }}</td>
                                  <td class="text-center text-primary">
                                    <a [href]="m.url_sancion" target="_blank">{{
                                      m.fec_sancion
                                      }}</a>
                                  </td>
                                  <td class="text-center text-primary">
                                    <a [href]="m.url_reconsideracion" target="_blank">{{ m.fec_reconsideracion }}</a>
                                  </td>
                                  <td class="text-center text-primary">
                                    <a [href]="m.url_apelacion" target="_blank">{{ m.fec_apelacion }}</a>
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_basico_soles }}
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_maximo_soles }}
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_basico_dolares }}
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_maximo_dolares }}
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_basico_uit }}
                                  </td>
                                  <td class="text-center">
                                    {{ m.monto_maximo_uit }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="multasPerdonadas?.length <= 0">
                                <tr>
                                  <td colspan="16">
                                    No se encontraron registros.
                                  </td>
                                </tr>
                              </tbody>

                              <div *ngIf="
                                  multasPerdonadas.length > 0 && !verCifras
                                " class="col-sm-12 p-0 mt-4 mb-4">
                                <div class="d-flex justify-content-left">
                                  <ngb-pagination [collectionSize]="
                                      multasPerdonadasCollectionSize
                                    " [(page)]="multasPerdonadasPage" [pageSize]="multasPerdonadasPageSize"
                                    [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
                                    (pageChange)="refreshData(3)">
                                    <ng-template ngbPaginationFirst>Primero</ng-template>
                                    <ng-template ngbPaginationLast>Último</ng-template>
                                    <ng-template ngbPaginationPrevious>
                                      < </ng-template>
                                        <ng-template ngbPaginationNext>></ng-template>
                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{
                                          page
                                          }}</ng-template>
                                  </ngb-pagination>
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#multasperdonadas">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Indicador de incumplimiento
                                        </button> -->

                    <a class="link-nivel-3" href="https://convoca.pe/deepdata/metodologia" target="_blank"><i
                        class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      Indicador de incumplimiento</a>
                  </div>

                  <!--Multas judicializadas-->
                  <div class="jpg-contentarticle tab-pane fade" [ngClass]="indicador == 4 ? 'active show' : ''"
                    id="tab04">
                    <div *ngIf="
                        _usuarioService.isPremium() &&
                        multasPerdonadas?.length > 0
                      ">
                      <div class="col-md-12 text-right">
                        <br />
                        <a href="javascript:void(0);" (click)="descargarResultados(3)"
                          class="btn btn-dark text-uppercase font-weight-bold px-5">
                          <i class="fa fa-download"></i> Descargar</a>
                      </div>
                    </div>

                    <div class="row">
                      <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="table-responsive">
                            <table *ngIf="multasPerdonadas" class="table table-striped jpg-tablecategories tbl-nivel3">
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Resolución Impugnada
                                  </th>
                                  <th class="text-left">Fecha de sanción</th>
                                  <th class="text-left">Materia incumplida</th>
                                  <th class="text-left">
                                    Número de expediente judicial
                                  </th>
                                  <th class="text-left">
                                    Resolución impugnada
                                  </th>
                                  <th class="text-left">
                                    Monto de multa judicializada
                                  </th>
                                  <th class="text-left">
                                    Monto de multa judicializada $
                                  </th>
                                  <th class="text-left">Estado del proceso</th>
                                  <th class="text-left">
                                    Resultado del proceso
                                  </th>
                                </tr>
                              </thead>
                              <tbody *ngIf="multasJudicializadas?.length > 0">
                                <tr *ngFor="let m of multasJudicializadas">
                                  <td class="text-left">
                                    {{ m.resolucion_impugnada }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.fecha_sancion }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.materia_incumplida }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.expediente_judicial }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.resolucion_impugnada }}
                                  </td>
                                  <td class="text-left">
                                    {{
                                    formatdatanumber(
                                    m.multa_judicializada_soles
                                    ? m.multa_judicializada_soles
                                    : 0
                                    )
                                    }}
                                  </td>
                                  <td class="text-left">
                                    {{
                                    formatdatanumber(
                                    m.multa_judicializada_dolares
                                    ? m.multa_judicializada_dolares
                                    : 0
                                    )
                                    }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.estado_procesal }}
                                  </td>
                                  <td class="text-left">
                                    {{ m.resultado_del_proceso }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="multasJudicializadas?.length <= 0">
                                <tr>
                                  <td colspan="16">
                                    No se encontraron registros.
                                  </td>
                                </tr>
                              </tbody>

                              <div *ngIf="
                                  multasJudicializadas.length > 0 && !verCifras
                                " class="col-sm-12 p-0 mt-4 mb-4">
                                <div class="d-flex justify-content-left">
                                  <ngb-pagination [collectionSize]="
                                      multasJudicializadasCollectionSize
                                    " [(page)]="multasJudicializadasPage" [pageSize]="multasJudicializadasPageSize"
                                    [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
                                    (pageChange)="refreshData(4)">
                                    <ng-template ngbPaginationFirst>Primero</ng-template>
                                    <ng-template ngbPaginationLast>Último</ng-template>
                                    <ng-template ngbPaginationPrevious>
                                      < </ng-template>
                                        <ng-template ngbPaginationNext>></ng-template>
                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{
                                          page
                                          }}</ng-template>
                                  </ngb-pagination>
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#multasperdonadas">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Indicador de incumplimiento
                                        </button> -->

                    <a class="link-nivel-3" href="https://convoca.pe/deepdata/metodologia" target="_blank"><i
                        class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      Indicador de incumplimiento</a>
                  </div>
                </div>

                <!--Tabs infranctions End-->
              </div>
            </div>
          </div>
        </div>
        <!--Company Details End-->
      </div>
    </div>

    <!-- Modal infracciones laborales -->
    <div class="modal fade" id="infraccioneslaborales" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              Indicador de incumplimiento de normas
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa muy
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/orange.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa frecuentemente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    frecuentemente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    medianamente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa poco infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa poco
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/gray.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa no infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa no
                    infractora cuando...</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal infracciones ambientales -->
    <div class="modal fade" id="infraccionesambientales" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              Indicador de incumplimiento
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa muy
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/orange.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa frecuentemente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    frecuentemente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    medianamente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa poco infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa poco
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/gray.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa no infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa no
                    infractora cuando...</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal multas perdonadas -->
    <div class="modal fade" id="multasperdonadas" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              Indicador de incumplimiento
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa muy
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/orange.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa frecuentemente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    frecuentemente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa
                    medianamente infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa poco infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa poco
                    infractora cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/gray.png" alt="" class="icon icon-semaforo-1 mr-2 my-1" />
                <div class="mr-2">
                  <small><strong>Empresa no infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa no
                    infractora cuando...</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Semaforo de empresa End-->
  </main>
</div>
<!--Main End-->