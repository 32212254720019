<div *ngIf="inputCarouselInfo.data.length>0" [id]="inputCarouselInfo.metadata.id" class="carousel slide"
  data-ride="carousel">
  <ul class="d-md-none carousel-indicators">
    <li *ngFor="let item of inputCarouselInfo.data; let i = index"
      [attr.data-target]="'#' + inputCarouselInfo.metadata.id" [attr.data-slide-to]="i" [ngClass]="{'active': i === 0}">
    </li>
  </ul>
  <div class="carousel-inner">
    <div *ngFor="let item of inputCarouselInfo.data; let i = index" [ngClass]="{'active': i === 0}"
      class="carousel-item">
      <a [href]="item.link_articulo" class="d-block" target="_blank">
        <div class="carousel-item-container">
          <img [src]="item.link_imagen" class="carousel-image" [alt]="item.titulo">
        </div>
        <div
          class="d-none d-md-block carousel-caption col-sm-9 col-md-9 col-lg-7 offset-lg-2 col-xl-5 offset-xl-3 text-left pl-0 ">
          <h2 class="title-reportaje p-1 pl-3 text-uppercase">{{ item.titulo }}</h2>
          <p class="p-reportaje p-1 pl-3">{{ item.descripcion }}</p>
        </div>
        <div class="d-md-none carousel-caption d-flex align-items-center justify-content-center">
          <h3 class="text-left text-white m-0">
            {{ item.titulo }}
          </h3>
        </div>
      </a>
    </div>
  </div>
  <a class="d-none d-md-flex align-items-center carousel-control-prev" [href]="inputCarouselInfo.metadata.href"
    data-slide="prev">
    <img id="nameLeft" [src]="srcLeft" (mouseover)="srcLeft = srcLeftOver" (mouseout)="srcLeft = srcLeftActive">
  </a>
  <a class="d-none d-md-flex align-items-center carousel-control-next" [href]="inputCarouselInfo.metadata.href"
    data-slide="next">
    <img id="nameRight" [src]="srcRight" (mouseover)="srcRight=srcRightOver" (mouseout)="srcRight=srcRightActive">
  </a>
</div>
