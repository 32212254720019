import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pago-result',
  templateUrl: './pago-result.component.html',
  styleUrls: ['./pago-result.component.css']
})
export class PagoResultComponent implements OnInit {

  processSuccessful: boolean;

  constructor( public _router: Router,
               public route: ActivatedRoute ) { 

    this.getResultFromPayme(this.route);

  }

  ngOnInit(): void {
  }

  getResultFromPayme(route: ActivatedRoute){

    route.queryParams.subscribe(params => {
      try {

        if(params["success"]){

          let response = params["success"];
          console.log('params',response);

          if( response === 'true' ){
            this.processSuccessful = true;
          }else{
            this.processSuccessful = false;
          }
        }else{
          console.log('no hay nada');
          this.processSuccessful = false;
        }

      } catch (error) {
        console.error(error);
      }
    });

  }

}
