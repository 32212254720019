import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';


@Directive({
  selector: '[showForRoles]'
})
export class ShowForRolesDirective {

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
  ) { }

  @Input() set showForRoles(role: string) {
    const isGranted: boolean = this.userRoles.includes(role) || false;
    if (isGranted) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  private get userRoles(): string[] {
    const userRoles: string[] = JSON.parse(localStorage.getItem('roles') || '');
    return userRoles;
  }
}
