<div class="container my-3">
  <div class="row">
    <div class="col-md-6 mb-4" *ngFor="let articulo of articulos">
      <div class="card h-100 border-0 shadow">
        <img [src]="articulo.link_imagen" class="card-img-top h-50" [alt]="articulo.titulo" />
        <div class="card-body">
          <h5 class="card-title">
            <a class="" [href]="articulo.link_articulo" target="_blank">
              {{articulo.titulo}}
            </a>
          </h5>
          <p class="card-text text-justify">
            {{articulo.descripcion}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pagination" class="d-flex justify-content-center">
    <ngb-pagination [collectionSize]="pagination.total" [(page)]="pagination.current_page"
      [pageSize]="pagination.per_page" [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
      (pageChange)="onPageChange($event)">
      <ng-template ngbPaginationFirst>Primero</ng-template>
      <ng-template ngbPaginationLast>Último</ng-template>
      <ng-template ngbPaginationPrevious>&lt;</ng-template>
      <ng-template ngbPaginationNext>&gt;</ng-template>
      <ng-template ngbPaginationEllipsis>...</ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>