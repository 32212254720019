<div class="select-add-option">
  <div class="form-select" (click)="toggleDropdown()">
    {{ selectedOption ? getDisplayValue(selectedOption) : inputPlaceholder }}
  </div>
  <div class="select-menu" [class.show]="showDropdown">
    <input #searchInput type="text" class="form-control" [(ngModel)]="inputValue" (ngModelChange)="onInputChange()"
      (keyup.enter)="onEnterPress()" placeholder="Busque o agregue una nueva opción" minlength="10" maxlength="255">
    <div class="options-container">
      <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center">
        <div class="spinner-border spinner-border-sm text-dark" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>
      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="filteredOptions.length > 0">
          <div *ngFor="let option of filteredOptions" class="option" (click)="selectOption(option)"
            [class.selected]="option === selectedOption">
            {{ getDisplayValue(option) }}
          </div>
        </ng-container>
        <div *ngIf="filteredOptions.length === 0 && inputValue.trim()" class="option new-option"
          (click)="addNewOption()">
          Agregue "{{ inputValue }}" como nueva opción
        </div>
      </ng-container>
    </div>
  </div>
</div>
