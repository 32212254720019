<div class="modal-header">
  <h4 class="modal-title">{{ inputTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" class="needs-validation" novalidate>
  <div class="modal-body">
    <div *ngFor="let field of inputFormFields">
      <div [ngSwitch]="field.key">
        <div *ngSwitchCase="'link_imagen'">
          <label [for]="field.key" class="form-control-label">Imagen</label>
          <div class="row mb-3">
            <div class="col-md-6 align-self-center">
              <div class="bg-img-preview text-center rounded p-2">
                <img *ngIf="previewImagen; else noImg" [src]="previewImagen" [alt]="previewImagen" class="img-fluid"
                  width="200">
                <ng-template #noImg>
                  <img src="../../../../assets/img/no-img.png" alt="No hay imagen" class="img-fluid" width="100">
                </ng-template>
              </div>
            </div>
            <div *ngIf="field.isFileImagen" class="col-md-6 align-self-center">
              <div FileDragNDrop class="d-flex flex-column justify-content-center align-items-center dropzone rounded"
                (filesChangeEmiter)="onFileChangeDragNDrop($event)">
                <input #inputImgHtml type="file" [name]="field.key" [id]="field.key" class="d-none"
                  (change)="onFileChangeInput($event)" accept="image/*" />
                <label [for]="field.key" class="mt-3 mb-2">
                  <span class="btn btn-secondary btn-sm me-1"> Seleccione la imagen </span> o suéltela aquí!
                </label>
                <small class="text-muted mb-2">
                  <ng-container *ngIf="uploadedFile; else notSelected">
                    Imagen seleccionada:
                    <span class="fw-bold">{{ uploadedFile.name }}</span>
                    <a role="button" class="btn-remove-file mx-2" (click)="removeFile()"><i class="fa fa-times"></i></a>
                  </ng-container>
                  <ng-template #notSelected>
                    No se ha seleccionado una imagen
                  </ng-template>
                </small>
              </div>
              <div *ngIf="submitAttempt &&inputIsImgRequired&&!uploadedFile" class="invalid-field">
                El campo es requerido
              </div>
            </div>
          </div>
          <div *ngIf="!field.isFileImagen" class="form-group">
            <input [id]="field.key" type="text" class="form-control"
              [ngClass]="{ 'is-invalid': isFieldRequired(field.key) }" [formControlName]="field.key">
            <div *ngIf="isFieldRequired(field.key)" class="invalid-feedback">
              El campo es requerido
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'descripcion'" class="form-group">
          <label [for]="field.key" class="form-control-label">{{ field.label | titlecase }}</label>
          <textarea [id]="field.key" class="form-control" [ngClass]="{ 'is-invalid': isFieldRequired(field.key) }"
            [formControlName]="field.key" rows="3"></textarea>
          <div *ngIf="isFieldRequired(field.key)" class="invalid-feedback">
            El campo es requerido
          </div>
        </div>
        <div *ngSwitchCase="'caracteristicas'" class="form-group">
          <label [for]="field.key" class="form-control-label">{{ field.label | titlecase }}</label>
          <st-editor [id]="field.key" [formControlName]="field.key" [config]="editorConfig"
            class="st-editor-content"></st-editor>
        </div>
        <div *ngSwitchCase="'publicado'" class="form-group">
          <label [for]="field.key" class="form-control-label">{{ field.label }}</label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" [name]="field.key" id="isPublic" [formControlName]="field.key"
              [value]="true">
            <label class="form-check-label" for="isPublic"> Sí </label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" [name]="field.key" id="noPublic" [formControlName]="field.key"
              [value]="false">
            <label class="form-check-label" for="noPublic"> No </label>
          </div>
        </div>
        <div *ngSwitchCase="'color'" class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text p-3" id="color-hexadecimal"
              [ngStyle]="{ 'background-color': form.get(field.key).value, 'border-color': form.get(field.key).value}"></span>
          </div>
          <input type="text" class="form-control" [formControlName]="field.key" maxlength="7"
            pattern="^#?([0-9A-Fa-f]{6})$" aria-describedby="color-hexadecimal">
        </div>
        <div *ngSwitchDefault class="form-group">
          <ng-container *ngIf="field.type !== 'date'">
            <label [for]="field.key" class="form-control-label">{{ field.label | titlecase }}</label>
            <input [id]="field.key" type="text" class="form-control"
              [ngClass]="{ 'is-invalid': isFieldRequired(field.key) }" [formControlName]="field.key">
            <div *ngIf="isFieldRequired(field.key)" class="invalid-feedback">
              El campo es requerido
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-custom-red-2" (click)="activeModal.dismiss()">Cerrar</button>
    <button type="button" class="btn btn-custom-primary" (click)="onSubmit()">{{inputTitleBtn}}</button>
  </div>
</form>
