import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ExceptionService } from './util/exception-request.service';
import { IContentArticles, IContentCarousel, IContentHome, IContentMemberships } from '../interfaces';
import { AuthService } from './auth.service';
import { UtilsService } from './util/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CmsSeccionesService {

  private readonly homePath: string = `${environment.base_url}/crud-title/main-titles`;
  private readonly carouselPath: string = `${environment.base_url}/crud-carousel/carousels`;
  private readonly membershipsPath: string = `${environment.base_url}/membresia`;
  private readonly articlesPath: string = `${environment.base_url}/crud-article/articles`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly exceptionService: ExceptionService,
    private readonly authService: AuthService,
    private readonly utilsService: UtilsService
  ) { }

  public getContentHome(): Observable<any> {
    return this.httpClient.get(this.homePath).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentHome(contentHome: IContentHome): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    const { titulo } = contentHome;
    return this.httpClient.put(`${this.homePath}/${contentHome.id}`, { titulo }, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getContentCarousel(): Observable<any> {
    return this.httpClient.get(this.carouselPath).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public createContentCarousel(contentCarousel: IContentCarousel): Observable<any> {
    return this.httpClient.post(this.carouselPath, { contentCarousel }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentCarousel(contentCarousel: IContentCarousel): Observable<any> {
    return this.httpClient.put(this.carouselPath, { contentCarousel }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getContentArticles(pagination?: any): Observable<any> {
    const queryParams = this.utilsService.generateParams(pagination);
    return this.httpClient.get(this.articlesPath, { params: queryParams }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentArticles(contentArticles: IContentArticles): Observable<any> {
    return this.httpClient.put(this.articlesPath, { contentArticles }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getContentMemberships(): Observable<any> {
    return this.httpClient.get(this.membershipsPath).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentMemberships(contentMemberships: IContentMemberships): Observable<any> {
    return this.httpClient.put(this.membershipsPath, { contentMemberships }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }
}
