import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filtrosIndustrias: any = {};
  isEmpty: boolean = true;

  constructor() { }

  setFiltrosIndustrias(filtros:any){
    this.filtrosIndustrias = filtros;
    this.isEmpty = false;
  }

  clearFiltrosIndustrias(){
    this.filtrosIndustrias = {};
    this.isEmpty = true;
  }

}
