<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor" style="background-color: white; height: 70vh;">
    <div class="jpg-haslayout">
  
      <!-- Busqeuda de empresas Listing Start-->
      <div *ngIf="processSuccessful" class="jpg-haslayout">
        <div class="container text-center mt-5 ">
            <div class="card d-flex justify-content-center align-content-center">
                <div class="card-body">
                  <h2>
                    <img style="height: 2.5rem;" src="../../../../assets/icons/checked.svg"> ¡Felicidades!
                    </h2>
                  <h2> El proceso de pago se realizó con éxito </h2>
                  <br>
                  <!-- <p class="card-text"></p> -->
                  <a href="#" class="btn btn-success" [routerLink]='["/industrias-extractivas"]'>Ir a la plataforma</a>
                </div>
            </div>            
        </div>
      </div>
      <!-- Busqeuda de empresas Listing End-->

      <!-- Busqeuda de empresas Listing Start-->
      <div *ngIf="!processSuccessful" class="jpg-haslayout">
        <div class="container text-center mt-5 ">
            <div class="card d-flex justify-content-center align-content-center">
                <div class="card-body">
                  <h2> <img style="height: 2.5rem;" src="../../../../assets/icons/cancel.svg"> Hubo un error</h2>
                  <h2> Error al concluir con el proceso de pago </h2>
                  <br>
                  <!-- <p class="card-text">Puedes contactarnos al siguiente correo: </p> -->
                  <a href="#" class="btn btn-danger" [routerLink]='["/"]'>Regresar al inicio</a>
                </div>
            </div>            
        </div>
      </div>
      <!-- Busqeuda de empresas Listing End-->
  
    </div>
  </main>