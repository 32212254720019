import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CruceDatos } from '../../../models/cruce-datos.model';
import { AlertService } from '../../../services/util/alert.service';
import * as FileSaver from 'file-saver';  
import { DataEmpresaService } from '../../../services/data-empresa.service';

@Component({
  selector: 'app-busqueda-masiva-datos-encontrados',
  templateUrl: './busqueda-masiva-datos-encontrados.component.html',
  styleUrls: ['./busqueda-masiva-datos-encontrados.component.css']
})
export class BusquedaMasivaDatosEncontradosComponent implements OnInit {

  cruceDatosResult: CruceDatos= new CruceDatos();
  //infraccionesEmpresaConvoca: InfraccionLaboralEmpresaConvoca[] = [];

  // PARA EL FILTRADO
  ambientales: boolean = true;
  laborales: boolean = true;
  multas: boolean = true;
  derrames: boolean = true;
  accidentes: boolean = true;
  estructura: boolean = true;
  representantes: boolean = true;
  proyectos: boolean = true;
  permisos: boolean = true;

  formatosDescarga: any[] = [
    {id:'Archivo de excel',value:1},
    {id:'Archivo PDF',value:2}
  ];
  selectedFormato: any;

  constructor( public router: Router,
               public route: ActivatedRoute,
               public _alertService: AlertService,
               public _dataEmpresaService:DataEmpresaService
              ) {
                this.getResultadosFromCruce(this.route);
               }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.selectedFormato = this.formatosDescarga[0];
  }

  getResultadosFromCruce(route: ActivatedRoute){
    
    route.queryParams.subscribe(async params => {
      try {
        if(params["resultados"]){

          this.cruceDatosResult = JSON.parse(params["resultados"]);
          //this.infraccionesEmpresaConvoca = this.cruceDatosResult.infracciones_laborales_empresa_convoca;

          // console.log(this.cruceDatosResult);
          
          this._alertService.closeAlert();
        }
      } catch (error) {
        console.error(error);
      }
    });

  }
  
  verBusquedaResultados(){
    this.router.navigate(["/busqueda-masiva"]);
  }

  descargarResultados(){

    let filtros = this.getFiltros();

    if(this.selectedFormato.value === 1){
      this._dataEmpresaService.getCruceEmpresaDownloadFile(this.cruceDatosResult.rucs, filtros).subscribe(
        result=>{
          this.exportExelFile(result, 'resultados-busqueda-masiva');
        }
      );
    }else{
      this._dataEmpresaService.getCruceEmpresaDownloadFilePDF(this.cruceDatosResult.rucs, filtros).subscribe(
        result=>{
          this.exportPDFFile(result, 'resultados-busqueda-masiva');
        },
        error=>{
          console.log('error',error);
        }
      );
    }


  }

  exportExelFile(data, filename) {
    const blobValue = new Blob([data['body']], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
     FileSaver.saveAs(blobValue, filename + '.xlsx');
  }

  exportPDFFile(data, filename) {
    const blobValue = new Blob([data['body']], {
        type: 'application/pdf'
    });
     FileSaver.saveAs(blobValue, filename + '.pdf');
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  downloadFile2(response: any, fileName?: string) {
    const blob = new Blob([response.body], { type: response.headers.get('content-type') });
    fileName = fileName || response.headers.get('content-disposition').split(';')[0];
    //const file = new File([blob], fileName, { response.headers.get('content-type') });
    //saveAs(file);
  }

  getFiltros(){
    
    let resultFiltro = [];
    
    if(this.ambientales){
      resultFiltro.push('fac_infracciones_ambientales');
    }
    if(this.laborales){
      resultFiltro.push('fac_infracciones_laborales');
    }
    if(this.multas){
      resultFiltro.push('fac_multas_perdonadas');
    }
    if(this.derrames){
      resultFiltro.push('fac_informacion_derrames');
    }
    if(this.accidentes){
      resultFiltro.push('fac_informacion_accidentes');
    }
    if(this.estructura){
      resultFiltro.push('fac_estructura');
    }
    if(this.representantes){
      resultFiltro.push('fac_representantes');
    }
    if(this.proyectos){
      resultFiltro.push('fac_informacion_proyectos');
    }
    if(this.permisos){
      resultFiltro.push('fac_informacion_permisos');
    }
    
    return resultFiltro;
    
  }


}
