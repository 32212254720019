import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { forkJoin, OperatorFunction, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { Empresa } from '../../../models/empresa.model';
import { DataEmpresaService } from '../../../services/data-empresa.service';

import * as Mapboxgl from 'mapbox-gl';
import { AlertService } from '../../../services/util/alert.service';
import { DetalleEmpresa } from '../../../models/detalle-empresa.model';
import { NotificacionService } from '../../../services/notificacion.service';
import { FilterService } from '../../../services/util/filter.service';
import { ShareSocialMediaService } from '../../../services/util/share-social-media.service';
import { Representante } from '../../../models/representante.model';
import { MapDataService } from '../../../services/map-data.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ITableHeaders } from 'src/app/interfaces';
import { UtilsService } from 'src/app/services/util/utils.service';

const colors: any = {
  accidentelab: '#89D4DA', //verdeazulado - Accidente laboral
  laboral: '#8192A7', //gris - Infracciones laborales
  ambiental: '#AAC885', //verde - Infracciones Ambientales
  derrames: '#B06585', //vino
  pasivos: '#93A2D7', //moradoplomo
  permisos: '#89C1D8', //azul-1
  proyectos: '#BAA599', //marrón
  accidentes: '#9E8CC6', //morado
  white: '#ffffff', // blanco - para textos
};

@Component({
  selector: 'app-detalle-empresa',
  templateUrl: './detalle-empresa.component.html',
  styleUrls: ['./detalle-empresa.component.css'],
})
export class DetalleEmpresaComponent implements OnInit {
  empresa: Empresa = new Empresa();
  rucFromIndustrias: string = '';
  representantes: Representante[] = [];
  estructura: any;

  public headersRepresentantes: ITableHeaders[] = [];
  public headersEstructura: ITableHeaders[] = [];
  public datosEstructura: any[] = []; 

  cboRegiones: any[] = [];
  cboAnios: any[] = [];
  cboActividadEconomica: any[] = [];

  selectedRegion: any;
  selectedAnio: any;
  selectedActividad: any;
  organizacion: string = '';

  // PARA EL MAPA
  mapa: Mapboxgl.Map;

  mapOptions: any = {
    htmlcontainer: 'mapa-mapbox',
    mapaSrc: '../assets/resources/peru-dpto.json',
    theme: this._mapService.mapThemes.LIGHT_THEME,
    initialCoords: [-74.854733, -9.180939], // CENTRO DE PERÚ
    initialZoom: 4.35,
    // initialZoom: 3,
    maxZoom: 9.5,
    minZoom: 4.3,
    scrollZoom: true,
    doubleClickZoom: false,
    fillColor: '#acacac', //#627BC1
    // ESTILOS PARA EL MARCADO DEL MAPA
    fillColorOpacity: 0.4,
    fillColorOpacityHover: 0.3,
    lineColor: '#b6b6b6',
    lineWidth: 1,
    // ESTILOS PARA LOS MARCADORES DEL MAPA
    markerSize: 120,
    markerColorOpacity: 0.8,
    markerStrokeColorOpacity: 0.5,
    pixelRatio: 2, // 1-bigger -> 2..3..4.. smaller
  };

  cboTipoInfraccion: any[] = [
    { id: 'TODOS', value: '' },
    { id: 'INFRACCIONES AMBIENTALES', value: 'ambiental' },
    { id: 'INFRACCIONES LABORALES', value: 'laboral' },
    // {id:'ACCIDENTES LABORALES',value:'acc'},
    { id: 'DERRAMES', value: 'Derrames' },
    { id: 'PASIVOS', value: 'Pasivos' },
    { id: 'ACCIDENTES', value: 'Accidentes' },
    { id: 'PERMISOS DE AGUA', value: 'Permisos de agua' },
    { id: 'PROYECTOS', value: 'Proyectos' },
    // 'TODOS','INFRACCIONES AMBIENTALES','INFRACCIONES LABORALES','ACCIDENTES LABORALES'
  ];

  selectedTipoInfraccion: any;

  filtros: any = {
    ruc: '',
    region: '',
    sectorEconomico: '',
    nombreEmpresa: '',
    page: '',
    size: 10,
    tipoInfraccion: '',
  };

  pointsMapa: any[] = [];
  pointsMapaAdicional: any[] = [];

  pointsToRender: any[] = [];
  pointsByColor: any = {};

  // PARA DETALLE EMPRESA
  detalleEmpresa: DetalleEmpresa = new DetalleEmpresa();

  // PARA NOTIFICACIONES
  notifIsActive: boolean = false;

  isAllowed: boolean = false;

  // Mapa mobile control
  mapaMobile: boolean = false;

  // Search empresa
  searching = false;
  searchFailed = false;

  private readonly REPRESENTANTES_ORDER: string[] = ['cargo', 'nombre', 'documentoIdentidad'];
  
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _dataEmpresaService: DataEmpresaService,
    public _mapService: MapDataService,
    public _notitificacionService: NotificacionService,
    public _alertService: AlertService,
    public _filterService: FilterService,
    public _shareSocialMedia: ShareSocialMediaService,
    public _usuarioService: UsuarioService,
    private readonly utilsService: UtilsService
  ) {
    this._dataEmpresaService.validateUserPublic();
    this._notitificacionService.validateUserPublic();

    if (this._usuarioService.perfilUsuario) {
      // PARA EVALUAR SU MEMBRESÍA
      this.isAllowed = true;
      this.getEmpresaFromIndustrias(this.route);
      this.getHeadersForFilter();
    } else {
      // SI ES USUARIO SIN REGISTRO
      if (this._usuarioService.isWithoutRegister()) {
        this._alertService
          .alertInfo(
            'Regístrate',
            'Regístrate para obtener una Membresía Gratuita y accede a todas las funcionalidades básicas.',
            'Registrarse'
          )
          .then((resp) => {
            if (resp.value) {
              this.router.navigate(['/registro']);
            }
          });
      } else {
        this.isAllowed = true;
        this.getEmpresaFromIndustrias(this.route);
        this.getHeadersForFilter();
      }
    }

    if (screen.width <= 991) {
      this.mapaMobile = true;
    }
  }

  // PARA EL SEARCH EMPRESA
  search: OperatorFunction<string, readonly { name }[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._dataEmpresaService.getEmpresasInput(term, 6).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  formatMatches = (value: any) => value.NombreEmpresa || '';

  selectedItem(value) {
    this.organizacion = value.item.NombreEmpresa;
    this.realizarBusqueda();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  getEmpresaFromIndustrias(route: ActivatedRoute) {
    route.queryParams.subscribe(async (params) => {
      try {
        if (params['ruc']) {
          this.rucFromIndustrias = params['ruc'];
          this.filtros.ruc = this.rucFromIndustrias;

          await this.getDetalleEmpresa(this.rucFromIndustrias);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  // PARA FILTROS DINÁMICOS
  limpiarFiltros(redirect: boolean = false) {
    this.filtros.region = '';
    this.filtros.sectorEconomico = '';
    this.filtros.nombreEmpresa = '';
    this.filtros.agno = '';
    this.filtros.page = '';

    if (redirect) {
      this.filtros.ruc = '';
      this.filtros.tipoInfraccion = 'ambiental';
      this.realizarBusqueda();
    }
  }

  getHeadersForFilter() {
    this._dataEmpresaService.getHeadersForFilter().subscribe((resp) => {
      if (resp?.success) {
        this.cboRegiones = resp.headers.Region.sort();
        this.cboRegiones.unshift('TODOS');

        this.cboAnios = resp.headers.Agno.sort();
        this.cboAnios.reverse();
        this.cboAnios.unshift('TODOS');

        this.cboActividadEconomica = resp.headers.SectorEconomico.sort().filter(
          (sector) => sector !== ''
        );
        this.cboActividadEconomica.unshift('TODOS');

        this.selectedRegion = this.cboRegiones[0];
        this.selectedAnio = this.cboAnios[0];
        this.selectedActividad = this.cboActividadEconomica[0];

        this.selectedTipoInfraccion = this.cboTipoInfraccion[0];
      }
    });
  }

  realizarBusqueda() {
    this.filtros.ruc = '';

    if (this.selectedRegion && this.selectedRegion != 'TODOS') {
      this.filtros.region = this.selectedRegion;
    } else {
      this.filtros.region = '';
    }

    if (this.selectedAnio && this.selectedAnio != 'TODOS') {
      this.filtros.agno = this.selectedAnio;
    } else {
      this.filtros.agno = '';
    }

    if (this.selectedActividad && this.selectedActividad != 'TODOS') {
      this.filtros.sectorEconomico = this.selectedActividad.trim();
    } else {
      this.filtros.sectorEconomico = '';
    }

    if (this.organizacion && this.organizacion != '') {
      this.filtros.nombreEmpresa = this.organizacion
        .trim()
        .replace(/\s\s+/g, ' ');
    } else {
      this.filtros.nombreEmpresa = '';
    }

    if (this.filtros) {
      this._filterService.setFiltrosIndustrias(this.filtros);
    }
    this.router.navigate(['/industrias-extractivas']);
  }

  // FOR MAP
  getPointsForMap(requestFromCbo: boolean = false) {
    if (requestFromCbo) {
      this._alertService.alertLoading('Cargando mapa');
      this.filtros.tipoInfraccion = this.selectedTipoInfraccion.value;
    }

    const infraccionesMapa$ = this._dataEmpresaService.getInfoForMap(
      this.filtros
    );
    const adicionalMapa$ = this._dataEmpresaService.getInfoAdicionalForMap(
      this.filtros
    );
    const allrequests = forkJoin(infraccionesMapa$, adicionalMapa$);

    allrequests.subscribe((results) => {
      this.pointsMapa = results[0].empresas;
      this.pointsMapaAdicional = results[1].empresas;
      this.pointsByColor = {};

      this.pointsByColor.arrayPointsLaborales = this.pointsMapa.filter(
        (x) => x.TipoInfraccion === 'laboral'
      );
      this.pointsByColor.arrayPointsAmbientales = this.pointsMapa.filter(
        (x) => x.TipoInfraccion === 'ambiental'
      );
      this.pointsByColor.arrayPointsPasivos = this.pointsMapaAdicional.filter(
        (x) => x.Tipo === 'Pasivos'
      );
      this.pointsByColor.arrayPointsProyectos = this.pointsMapaAdicional.filter(
        (x) => x.Tipo === 'Proyectos'
      );
      this.pointsByColor.arrayPointsAccidentes =
        this.pointsMapaAdicional.filter((x) => x.Tipo === 'Accidentes');
      this.pointsByColor.arrayPointsDerrames = this.pointsMapaAdicional.filter(
        (x) => x.Tipo === 'Derrames'
      );
      this.pointsByColor.arrayPointsPermisos = this.pointsMapaAdicional.filter(
        (x) => x.Tipo === 'Permisos de agua'
      );

      this.iniciarMapaCluster(this.pointsByColor);
      // CERRANDO MODAL
      if (requestFromCbo) {
        this._alertService.closeAlert();
      }
    });
  }

  // PARA EL MAPA CLUSTER
  iniciarMapaCluster(pointsByColor, coordenadasFit = null) {
    (this.mapOptions.theme = this._mapService.mapThemes.LIGHT_THEME),
      (this.mapa = this._mapService.cluster_initNewMap(this.mapOptions));

    this.mapa.on('load', () => {
      // DELIEANDO MAPA DEL PERÚ
      this._mapService.standar_mapWithLinesHover(this.mapa, this.mapOptions);

      // INFRACCIONES LABORALES
      let arrayLaborales = this._mapService.formatData(
        pointsByColor.arrayPointsLaborales,
        'arrayPointsLaborales',
        1
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayLaborales,
        'arrayPointsLaborales',
        colors.laboral,
        colors.white,
        coordenadasFit
      );

      // INFRACCIONES AMBIENTALES
      let arrayAmbientales = this._mapService.formatData(
        pointsByColor.arrayPointsAmbientales,
        'arrayPointsAmbientales',
        1
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayAmbientales,
        'arrayPointsAmbientales',
        colors.ambiental,
        colors.white
      );

      // PASIVOS
      let arrayPasivos = this._mapService.formatData(
        pointsByColor.arrayPointsPasivos,
        'arrayPointsPasivos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayPasivos,
        'arrayPointsPasivos',
        colors.pasivos,
        colors.white
      );

      // PROYECTOS
      let arrayProyectos = this._mapService.formatData(
        pointsByColor.arrayPointsProyectos,
        'arrayPointsProyectos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayProyectos,
        'arrayPointsProyectos',
        colors.proyectos,
        colors.white
      );

      // ACCIDENTES
      let arrayAccidentes = this._mapService.formatData(
        pointsByColor.arrayPointsAccidentes,
        'arrayPointsAccidentes',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayAccidentes,
        'arrayPointsAccidentes',
        colors.accidentes,
        colors.white
      );

      // DERRAMES
      let arrayDerrames = this._mapService.formatData(
        pointsByColor.arrayPointsDerrames,
        'arrayPointsDerrames',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayDerrames,
        'arrayPointsDerrames',
        colors.derrames,
        colors.white
      );

      // PERMISOS
      let arrayPermisos = this._mapService.formatData(
        pointsByColor.arrayPointsPermisos,
        'arrayPointsPermisos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayPermisos,
        'arrayPointsPermisos',
        colors.permisos,
        colors.white
      );

      if (this.empresa) {
        this._alertService.closeAlert();
      }
      this.limpiarFiltros();
    });
  }

  // PARA EL DETALLE DE LA EMPRESA
  getDetalleEmpresaOld(ruc: string) {
    this._dataEmpresaService.getDetalleEmpresaByRuc(ruc).subscribe((resp) => {
      if (resp?.success) {
        this.detalleEmpresa.empresa = resp.empresa;
        this.detalleEmpresa.infraccionesLaborales = resp.infraccionesLaborales;

        // OBTENGO LOS PUNTOS DEL MAPA
        this.getPointsForMap();
      }
    });
  }

  getDetalleEmpresa(ruc: string) {
    this._dataEmpresaService.getEmpresasByRuc(ruc).subscribe((resp) => {
      if (resp.success && resp.empresas) {
        this.empresa = resp.empresas[0];
        // OBTENER REPRESENTANTES
        this.verRepresentantes();
        this.verEstructura();
        // OBTENGO LOS PUNTOS DEL MAPA
        this.getPointsForMap();
      }
    });
  }

  // PARA RECIBIR NOTIFICACIONES
  recibirNotificaciones() {
    if (
      this._usuarioService.perfilUsuario?.codigo_membresia === 'FREE' ||
      !this._usuarioService.perfilUsuario?.codigo_membresia
    ) {
      this._alertService
        .alertInfo(
          'Descubre más funcionalidades',
          'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.',
          'Ver planes'
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/planes']);
          }
        });
    } else {
      if (this.notifIsActive) {
        this._alertService.alertLoading(
          'Desactivando recibir notificaciones...'
        );
      } else {
        this._alertService.alertLoading('Activando recibir notificaciones...');
      }

      this._notitificacionService
        .regitrarNotificacionEmpresa(this.rucFromIndustrias)
        .subscribe((resp) => {
          if (resp.success) {
            this.notifIsActive = !this.notifIsActive;
            if (this.notifIsActive) {
              this._alertService.alertSuccess(
                'Activado',
                'Empezará a recibir notificaciones sobre esta empresa'
              );
            } else {
              this._alertService.alertSuccess(
                'Desactivado',
                'Se dejará de recibir notificaciones sobre esta empresa'
              );
            }
          }
        });
    }
  }

  verDetalleSemaforo(tab: number) {
    this._alertService.alertLoading(`Cargando infracciones...`);

    let navigationExtras: NavigationExtras = {};
    if (this.rucFromIndustrias) {
      navigationExtras = {
        queryParams: {
          ruc: this.rucFromIndustrias,
          indicador: tab,
        },
        skipLocationChange: false,
      };
    }

    this.router.navigate(['/detalle-semaforo'], navigationExtras);
  }

  verDetalleAdicional(tab: number) {
    this._alertService.alertLoading(`Cargando infracciones...`);

    let navigationExtras: NavigationExtras = {};
    if (this.rucFromIndustrias) {
      navigationExtras = {
        queryParams: {
          ruc: this.rucFromIndustrias,
          indicador: tab,
        },
        skipLocationChange: false,
      };
    }

    this.router.navigate(['/detalle-adicional'], navigationExtras);
  }

  // PARA REPRESENTANTES
  verRepresentantes() {
    this._dataEmpresaService
    .getRepresentantesEmpresaByRuc(this.empresa.Ruc)
    .subscribe((resp) => {
      if (resp.success) {
        this.representantes = resp.empresas[this.empresa.Ruc].representantes;

        if (this.representantes.length > 0) {
          this.headersRepresentantes = this.utilsService.getHeaders(this.representantes[0], this.REPRESENTANTES_ORDER);
        }
      }
    });
}
  // PARA ESTRUCTURA
   verEstructura() {
     this._dataEmpresaService
    .getDetalleEmpresaByRucFromTabla(this.empresa.Ruc, 'fac_estructura')
    .subscribe((resp) => {
      if (resp.success) {
        this.estructura = resp.empresas[0];
        this.datosEstructura = [{
          casa_matriz: this.estructura.casa_matriz,
          pais: this.estructura.pais,
          NombreEmpresa: this.empresa.NombreEmpresa,
          SectorEconomico: this.empresa.SectorEconomico
        }];

        this.headersEstructura = this.utilsService.getHeaders(this.datosEstructura[0]); 
      }
    });
}

  shareFacebook() {
    this._shareSocialMedia.shareFacebook('https://deepdata.convoca.pe/');
  }

  shareTwitter() {
    this._shareSocialMedia.shareTwitter('https://deepdata.convoca.pe/');
  }

  shareLinkedin() {
    this._shareSocialMedia.shareLinkedin(window.location.href);
  }
}
