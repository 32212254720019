import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractForm } from '../../../core/class/forms.abstract';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITableHeaders } from '../../../interfaces';
import { EditorButton, EditorConfig, EditorInput, ExecCommand, Separator, ToolbarItem, ToolbarItemType } from 'ngx-simple-text-editor';

export const SEPARATOR: Separator = { type: ToolbarItemType.Separator };
export const BOLD_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.bold, icon: 'fa fa-bold', title: 'Negrita' };
export const ITALIC_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.italic, icon: 'fa fa-italic', title: 'Cursiva' };
export const UNDERLINE_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.underline, icon: 'fa fa-underline', title: 'Subrayado' };
export const STRIKE_THROUGH_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.strikeThrough, icon: 'fa fa-strikethrough', title: 'Tachado' };
export const JUSTIFY_LEFT_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.justifyLeft, icon: 'fa fa-align-left', title: 'Alinear a la izquierda' };
export const JUSTIFY_CENTER_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.justifyCenter, icon: 'fa fa-align-center', title: 'Centrado' };
export const JUSTIFY_RIGHT_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.justifyRight, icon: 'fa fa-align-right', title: 'Alinear a la derecha' };
export const JUSTIFY_FULL_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.justifyFull, icon: 'fa fa-align-justify', title: 'Justificado' };
export const ORDERED_LIST_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.insertOrderedList, icon: 'fa fa-list-ol', title: 'Ordenar lista' };
export const UNORDERED_LIST_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.insertUnorderedList, icon: 'fa fa-list-ul', title: 'Desordenar lista' };
export const LINK_INPUT: EditorInput = { type: ToolbarItemType.Input, command: ExecCommand.createLink, icon: 'fa fa-link', text: 'Crear enlace', title: 'Crear enlace', label: 'Crear enlace' };
export const UNLINK_BUTTON: EditorButton = { type: ToolbarItemType.Button, command: ExecCommand.unlink, icon: 'fa fa-unlink', title: 'Eliminar enlace' };

export const ST_BUTTONS: ToolbarItem[] = [BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON, STRIKE_THROUGH_BUTTON, SEPARATOR, JUSTIFY_LEFT_BUTTON,
  JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, SEPARATOR, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON, SEPARATOR,
  LINK_INPUT, UNLINK_BUTTON];

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.css']
})
export class EditModalComponent extends AbstractForm implements OnInit {

  @Input() inputTitle: string = '';
  @Input() inputTitleBtn: string = '';
  @Input() inputData: any = {};
  @Input() inputFormFields: ITableHeaders[] = [];
  @Input() inputFieldValidators: { [key: string]: any[] } = {};
  @Input() inputIsImgRequired: boolean = false;

  public editorConfig: EditorConfig = {
    placeholder: 'Añadir caracteristícas...',
    buttons: ST_BUTTONS,
  };
  public uploadedFile: File | null = null;
  public previewImagen: ArrayBuffer | string = '';

  @ViewChild('inputImgHtml') inputImgHtml!: ElementRef<HTMLInputElement>;

  constructor(
    formBuilder: FormBuilder,
    public readonly activeModal: NgbActiveModal
  ) {
    super(formBuilder);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.valuesChanges();
    if (this.inputData.link_imagen) {
      this.previewImagen = this.inputData.link_imagen;
    }
  }

  public initForm(): void {
    const formControls = this.inputFormFields.reduce((controls, field) => {
      if (!field.isFileImagen) {
        if (field.type === 'array') {
          // Inicializamos el FormArray sin hardcodear las claves de los objetos
          controls[field.key] = this.superFormBuilder.array(
            (this.inputData[field.key] || []).map(item => {
              const groupControls = {};
              // Iteramos sobre las claves de cada objeto `item`
              Object.keys(item).forEach(key => {
                groupControls[key] = this.superFormBuilder.control((key === 'precio' || key === 'precio_antes') ? +item[key] || 0.00 : item[key] || '', []); // Creamos un control dinámico para cada clave
              });
              return this.superFormBuilder.group(groupControls); // Creamos un FormGroup dinámico con los controles generados
            })
          );
        } else {
          // Inicializa un FormControl normal
          controls[field.key] = this.superFormBuilder.control(
            this.getProcessedValue(field, this.inputData[field.key]),
            this.inputFieldValidators[field.key] || []
          );
        }
      }
      return controls;
    }, {});
    this.form = this.superFormBuilder.group(formControls);
    this.form.get('id')?.disable();
  }

  public getFormArray(key: string): FormArray {
    return this.form.get(key) as FormArray;
  }

  public objectKeys(group: any): string[] {
    return Object.keys(group).filter(key => {
      return key !== 'id_plan' && key !== 'precio_antes' && key !== 'igv';
    });
  }

  public getFormGroup(formArrayName: string, index: number): FormGroup {
    return (this.form.get(formArrayName) as FormArray).at(index) as FormGroup;
  }

  public onlyAllowNumbers(event: KeyboardEvent): boolean {
    const char = event.key;
    if (/[\d.]/.test(char) || event.key === 'Backspace') {
      return true;
    }
    event.preventDefault();
    return false;
  }

  public validateDecimal(event: any): void {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    const match = value.match(/^(\d+(\.\d{0,2})?)?/);
    event.target.value = match ? match[0] : '';
  }

  public formatPrice(event: any, group: FormGroup, controlKey: string): void {
    const value = event.target.value;
    if (value) {
      const formattedValue = parseFloat(value).toFixed(2);
      group.get(controlKey)?.setValue(+formattedValue, { emitEvent: false });
      event.target.value = +formattedValue;
    }
  }

  private getProcessedValue(field: any, value: any): any {
    if (field.type === 'boolean') {
      return value === true || value === 'true';
    }
    if (field.type === 'date') {
      const nowDate: Date = new Date();
      nowDate.setUTCHours(0, 0, 0, 0);
      const formattedDate = nowDate.toISOString();
      return formattedDate;
    }
    return value !== undefined ? value : '';
  }

  private valuesChanges(): void {
    const linkImagen: FormControl = this.form.get('link_imagen') as FormControl;
    if (linkImagen) {
      linkImagen.valueChanges.subscribe((linkImagen: string) => {
        this.previewImagen = linkImagen;
      });
    }
  }

  public onFileChangeDragNDrop(file: File) {
    if (file) {
      this.readFile(file);
    }
  }

  public onFileChangeInput(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.readFile(input.files[0]);
    }
  }

  public readFile(file: File) {
    if (file) {
      this.uploadedFile = file;
      const reader = new FileReader();
      reader.onload = () => this.previewImagen = reader.result || '';
      reader.readAsDataURL(this.uploadedFile);
    }
  }

  public removeFile(): void {
    this.uploadedFile = null;
    this.previewImagen = '';
    this.inputImgHtml.nativeElement.value = '';
  }

  public onSubmit(): void {
    this.submitAttempt = true;
    if (this.form.invalid || (this.inputIsImgRequired && !this.uploadedFile)) {
      return;
    }
    if (this.uploadedFile) {
      const formData = new FormData();
      Object.keys(this.form.getRawValue()).forEach(key => {
        formData.append(key, this.form.get(key)?.value);
      });
      formData.append('image', this.uploadedFile);
      this.activeModal.close(formData);
    } else {
      this.activeModal.close(this.form.getRawValue());
    }
    this.submitAttempt = false;
  }
}
