<!--Filter Results Strart-->
<div class="d-none d-lg-block jpg-haslayout">
  <div class="jpg-categoryslidercontent">
    <div class="row">
      <div class="col-12 col-md pb-3 pb-lg-0 d-flex align-items-center">
        <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%;">
          <!-- <h3>Actividad económica</h3> -->
          <h2 style="margin: 0;
              font-size: 18px;
              font-weight: 400;
              line-height: 20px;">Filtrar resultados:</h2>
        </div>
      </div>
      <div class="col-12 col-md border pb-3 pb-lg-0">
        <figure>
          <i class="fa fa-industry"></i>
        </figure>
        <div class="jpg-cattitle">
          <h3>Actividad económica</h3>
          <div class="form-group mb-0">
            <span class="jpg-select">
              <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad"
                (ngModelChange)="realizarBusqueda()">
                <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                  {{ actividad }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md border pb-3 pb-lg-0">
        <figure>
          <i class="fa fa-building-o"></i>
        </figure>
        <div class="jpg-cattitle">
          <h3>Empresa</h3>
          <div class="form-group mb-0">

            <!-- <input type="text" placeholder="Empresa" id="organizacion" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()" (input)="filterEmpresas($event.target.value)"> -->

            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>

            <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
              [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
              [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
              (keydown.enter)="realizarBusqueda()" />
            <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
            <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>

          </div>
        </div>
      </div>
      <div class="col-12 col-md border pb-3 pb-lg-0">
        <figure>
          <i class="fa fa-map-marker"></i>
        </figure>
        <div class="jpg-cattitle">
          <h3>Región</h3>
          <div class="form-group mb-0">
            <span class="jpg-select">
              <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion" (ngModelChange)="realizarBusqueda()">
                <option *ngFor="let region of cboRegiones" [ngValue]="region">
                  {{ region }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md border pb-3 pb-lg-0">
        <figure>
          <i class="fa fa-calendar-o"></i>
        </figure>
        <div class="jpg-cattitle">
          <h3>Año</h3>
          <div class="form-group mb-0">
            <span class="jpg-select">
              <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" (ngModelChange)="realizarBusqueda()">
                <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                  {{ anio }}
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md p-3 pt-lg-1 pb-lg-0">
        <div class="jpg-cattitle btn-limpiar">
          <button type="button" class="btn btn-dark text-uppercase font-weight-bold"
            (click)="limpiarFiltros()">Limpiar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Filter Results End-->


<!-- BANNER MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white banner-mobile">Descubre a las empresas mineras y de hidrocarburos en
          el Perú</h2>
        <p class="text-center text-white">Inicio / Búsquedas / Empresas</p>
      </div>
    </div>
  </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-12">
      <div class="row card p-4" style="background-color: #ececec">

        <div class="col-12 text-left p-0">
          <h4>Realiza tu búsqueda:</h4>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-building-o"></i> Empresa</label>
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>
            <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
              [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
              [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
              (keydown.enter)="realizarBusqueda()" />
            <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>
          </div>
          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-industry"></i> Actividad
              económica</label>
            <select id="cboActividad" class="w-100" name="cboActividad" [(ngModel)]="selectedActividad"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                {{ actividad }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-map-marker"></i> Región</label>
            <select id="cboRegion" class="w-100" name="cboRegion" [(ngModel)]="selectedRegion"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let region of cboRegiones" [ngValue]="region">
                {{ region }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-calendar-o"></i> Año</label>
            <select id="cboAnio" class="w-100" name="cboAnio" [(ngModel)]="selectedAnio"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                {{ anio }}
              </option>
            </select>
          </div>

          <div class="form-group d-flex justify-content-between mt-2">
            <button type="button" class="btn btn-dark text-uppercase w-100 mr-2"
              (click)="limpiarFiltros()">Limpiar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="empresa" class="d-block d-lg-none container-fluid card" style="background-color: #f9f8f8">
  <div class="row p-4">
    <div class="col-8 text-left p-0 pr-2">
      <h5>{{ empresa.NombreEmpresa }}</h5>
      <span class="text-success font-weight-bold">
        <i class="fa fa-building"></i> {{ empresa.Estado }}
      </span>
      <span>
        RUC: {{ empresa.Ruc }}
      </span>
    </div>
    <div class="col-4 text-left p-0">
      <p class="mb-1">Inicio de actividades:</p>
      <span>{{ empresa.InicioActividades }}</span>
    </div>
  </div>
  <div class="row p-4">
    <div class="col-12 text-left p-0">
      <h5>>> Indicadores</h5>
      <select class="w-100" id="cboIndicadores" name="cboIndicadores" [(ngModel)]="selectedIndicador">
        <option *ngFor="let indicador of indicadoresEmpresa" [ngValue]="indicador">
          {{ indicador.title }}
        </option>
      </select>
    </div>
  </div>

</div>

<!-- CARDS REPLACING TABLE -->
<ng-container *ngFor="let detalle of detalleEmpresaTables">
  <div *ngIf="selectedIndicador?.id === detalle.id" class="d-block d-lg-none">
    <!-- MAIN -->
    <div *ngIf="detalle.data.length > 0 && !detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: .8rem;">
        <a class="text-dark d-block" href="javascript:void(0)">
          <div class="row pt-3 pl-0 pr-0">
            <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
              <ng-container *ngFor="let h of detalle.headers">
                <div *ngIf="verHeaderValuesDetalleEmpresa(h,detalle.tableName)" class="col-5 p-1 pr-3">
                  <p class="my-2">{{ h.label }}</p>
                </div>
              </ng-container>
            </div>
          </div>

          <div *ngFor="let d of detalle.data" class="row p-0 div-card-row">
            <div class="col-12 font-weight-bold d-flex align-items-center">
              <ng-container *ngFor="let h of detalle.headers">
                <div *ngIf="verHeaderValuesDetalleEmpresa(h,detalle.tableName)" class="col-5 p-1 pr-3">
                  <p class="my-3">{{ d[h.key] }}</p>
                </div>
              </ng-container>
              <div class="col-2 p-1 pr-3 text-right">
                <button style="background: none; color: #dc3545;" (click)="verDetalleRow(d)">
                  <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- NO ROWS -->
    <div *ngIf="detalle.data.length <= 0" class="mb-4">
      <div class="container-fluid card p-3" style="font-size: .8rem;">
        <p class="mb-0 text-center"><b>No se encontraron registros con los criterios ingresados.</b></p>
      </div>
    </div>

    <!-- PAGINATION -->
    <div *ngIf="detalle.data.length > 0" class="col-sm-12 p-0 mt-4 mb-4">
      <div class="d-flex justify-content-center">
        <ngb-pagination [collectionSize]="detalle.collectionSize" [(page)]="paginationSettings[detalle.tableName].page"
          [pageSize]="paginationSettings[detalle.tableName].size" [ellipses]="false" [maxSize]="3"
          [boundaryLinks]="true" [rotate]="true" (pageChange)="getDetallesEmpresaFromTablaPaginated(detalle.tableName)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>&lt;</ng-template>
          <ng-template ngbPaginationNext>&gt;</ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

    <!-- DETALLE -->
    <div *ngIf="detalle.data.length > 0 && detailRow" class="border-bottom mb-4">
      <div class="container-fluid" style="font-size: .8rem;">
        <div class="row pt-3 pl-0 pr-0">
          <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
            <div class="col-2 p-1 pr-3 text-left">
              <button style="background: none; color: #dc3545;" (click)="volverListaDetalle()">
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-10 p-1 pr-3">
              <div class="row">
                <ng-container *ngFor="let h of detalle.headers">
                  <div *ngIf="verHeaderValuesDetalleEmpresa(h,detalle.tableName)" class="col-6 p-1 pr-3">
                    <p class="my-2">{{ h.label }}</p>
                  </div>
                </ng-container>
              </div>
              <div class="row">
                <ng-container *ngFor="let h of detalle.headers">
                  <div *ngIf="verHeaderValuesDetalleEmpresa(h,detalle.tableName)" class="col-6 p-1 pr-3">
                    <p class="my-2">{{ selectedRowDetail[h.key] }}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let h of detalle.headers" class="row p-0 div-card-row">
          <div *ngIf="verCamposDetalleEmpresa(h, detalle.tableName)"
            class="col-12 font-weight-bold d-flex align-items-center">
            <!-- Header Column -->
            <div class="col-2"></div>
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="my-3">{{ h.label }}</p>
            </div>
            <!-- Value Column -->
            <div class="col-5 p-1 pr-3 aling-self-content">
              <p class="my-3">
                <ng-container *ngIf="h.key === 'resolucion'; else normalValue">
                  <a class="text-primary"
                    *ngIf="selectedRowDetail.url_resolucion && selectedRowDetail.url_resolucion.trim() !== ''"
                    [href]="selectedRowDetail.url_resolucion" target="_blank">
                    {{ selectedRowDetail.fec_resolucion }}
                  </a>
                  <span *ngIf="!selectedRowDetail.url_resolucion || selectedRowDetail.url_resolucion.trim() === ''">
                    {{ selectedRowDetail.fec_resolucion }}
                  </span>
                </ng-container>
                <ng-template #normalValue>
                  {{ selectedRowDetail[h.key] }}
                </ng-template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DESCARGAR -->
    <div class="container-fluid">
      <div class="row pr-4 pl-4 pb-4">
        <div *ngIf="_usuarioService.isPremium() && detalle.data?.length > 0" class="col-12 col-sm-6 d-flex">
          <a href="javascript:void(0);" (click)="descargarResultadosAll(detalle.tableName)"
            class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2">
            <i class="fa fa-download"></i> Descargar</a>
        </div>
        <div class="col-12 col-sm-6 align-self-center">
          <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
            class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- CARDS REPLACING TABLE -->

<!--Main Start-->
<div class="d-none d-lg-block">
  <main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
    <!-- Semaforo de empresa Start-->
    <div class="jpg-haslayout">
      <div class="container-fluid">
        <!--Company Details Start-->
        <div class="row mb-3">
          <div class="col-xs-12 col-sm-12 col-md-12 float-left">
            <div class="card border-0 jpg-bgwhite jpg-haslayout">
              <div class="card-body">
                <div class="row align-items-center">
                  <div *ngIf="empresa" class="col">
                    <h2>{{ empresa.NombreEmpresa }}</h2>
                    <ul class="jpg-userlisting-breadcrumb jpg-userlisting-breadcrumbvtwo">
                      <li>
                        <span class="text-success font-weight-bold">
                          <i class="fa fa-building"></i>{{ empresa.Estado }}</span>
                      </li>
                      <li><span>
                          <i class="fa fa-list-alt"></i>
                          RUC: {{ empresa.Ruc }}</span>
                      </li>
                      <li>
                        <span><i class="fa fa-calendar-o"></i> INICIO DE
                          ACTIVIDADES: {{ empresa.InicioActividades }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-auto align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
                      class="btn btn-danger text-uppercase font-weight-bold">Regresar</a>
                  </div>
                </div>
                <div class="jpg-widgettitle mt-2 w-100"></div>
                <!--Select infranctions Start-->
                <div class="mx-4">
                  <div class="row justify-content-between">
                    <div class="col-md-3">
                      <h5>Indicadores</h5>
                      <select class="w-100" id="cboIndicadores" name="cboIndicadores" [(ngModel)]="selectedIndicador">
                        <option *ngFor="let indicador of indicadoresEmpresa" [ngValue]="indicador">
                          {{ indicador.title }}
                        </option>
                      </select>
                    </div>
                    <div *ngIf="_usuarioService.isPremium()" class="col-md-3 text-right align-self-end">
                      <div *ngFor="let detalle of detalleEmpresaTables">
                        <a *ngIf="selectedIndicador?.id === detalle.id && detalle.data?.length > 0" role="button"
                          (click)="descargarResultadosAll(detalle.tableName)"
                          class="btn btn-dark text-white text-uppercase font-weight-bold px-5"> <i
                            class="fa fa-download"></i>
                          Descargar</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Select infranctions Start-->
                <div class="jpg-haslayout jpg-haslayout-gray">
                  <div *ngFor="let detalle of detalleEmpresaTables" class="row">
                    <div *ngIf="selectedIndicador?.id === detalle.id"
                      class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                      <div class="col-12 col-sm-12 col-md-12 ">
                        <div class="table-responsive">
                          <table class="table table-striped jpg-tablecategories  tbl-nivel3">
                            <thead>
                              <tr *ngIf="detalle.headers.length>0">
                                <th *ngFor="let h of detalle.headers" class="text-center">{{h.label}}</th>
                              </tr>
                            </thead>
                            <tbody *ngIf="detalle.data.length>0">
                              <tr *ngFor="let d of detalle.data">
                                <td *ngFor="let h of detalle.headers" class="text-center">
                                  <ng-container
                                    *ngIf="detalle.id === 3 && h.key === 'url_resolucion'; else check_ubicacion">
                                    <ng-container *ngIf="d.url_resolucion?.trim(); else empty_url">
                                      <a [href]="d.url_resolucion" target="_blank" class="text-primary">
                                        {{ d[h.key] }}
                                      </a>
                                    </ng-container>
                                    <ng-template #empty_url>
                                      <span>{{ d[h.key] }}</span>
                                    </ng-template>
                                  </ng-container>
                                  <ng-template #check_ubicacion>
                                    <ng-container
                                      *ngIf="(detalle.id === 4 && h.key === 'ubicacion'); else other_content">
                                      <span>{{ d.region + ', ' + d.provincia + ', ' + d.distrito }}</span>
                                    </ng-container>
                                  </ng-template>
                                  <ng-template #other_content>
                                    {{ d[h.key] }}
                                  </ng-template>
                                </td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="detalle.data.length <= 0">
                              <tr>
                                <td colspan="12">No se encontraron registros.</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div *ngIf="detalle.data.length > 0" class="d-flex justify-content-center my-3">
                          <ngb-pagination [collectionSize]="detalle.collectionSize"
                            [(page)]="paginationSettings[detalle.tableName].page"
                            [pageSize]="paginationSettings[detalle.tableName].size" [ellipses]="false" [maxSize]="5"
                            [boundaryLinks]="true" [rotate]="true"
                            (pageChange)="getDetallesEmpresaFromTablaPaginated(detalle.tableName)">
                            <ng-template ngbPaginationFirst>Primero</ng-template>
                            <ng-template ngbPaginationLast>Último</ng-template>
                            <ng-template ngbPaginationPrevious>&lt;</ng-template>
                            <ng-template ngbPaginationNext>&gt;</ng-template>
                            <ng-template ngbPaginationEllipsis>...</ng-template>
                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                          </ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Select infranctions End-->
              </div>
            </div>
          </div>
        </div>
        <!--Company Details End-->
      </div>
    </div>

    <!-- Modal infracciones laborales -->
    <div class="modal fade" id="infraccioneslaborales" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
              de normas
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa poco infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa poco infractora
                    cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa medianamente infractora
                    cuando...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Según nuestra metodología, se consideró a una empresa muy infractora
                    cuando...</small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal infracciones ambientales -->
    <div class="modal fade" id="infraccionesambientales" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
              de normas
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa libre de infracciones</strong><br />
                    Una empresa libre de infracciones es una empresa que...</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Una empresa libre de infracciones es una empresa que... </small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Una empresa libre de infracciones es una empresa que... </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal multas perdonadas -->
    <div class="modal fade" id="multasperdonadas" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div class="btn-info-semaforo m-3">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
              de normas
            </div>

            <div class="m-4">
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa libre de infracciones</strong><br />
                    Una empresa libre de infracciones es una empresa que... lorem ipsum
                    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    lorem ipsum lorem ipsum.</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-4">
                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa medianamente infractora</strong><br />
                    Una empresa libre de infracciones es una empresa que... lorem ipsum
                    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    lorem ipsum lorem ipsum.</small>
                </div>
              </div>
              <div class="d-flex align-self-center align-items-start mb-3">
                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                <div class="mr-2">
                  <small><strong>Empresa muy infractora</strong><br />
                    Una empresa libre de infracciones es una empresa que... lorem ipsum
                    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                    lorem ipsum lorem ipsum.</small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Semaforo de empresa End-->
  </main>
</div>
<!--Main End-->
