<form [formGroup]="form">
  <div class="form-group form-inline justify-content-between align-items-center">
    <div class="input-group w-50">
      <input type="text" class="form-control " placeholder="Búsqueda de texto completo, ingrese una palabra clave..."
        formControlName="searchTerm" maxlength="255">
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
      </div>
    </div>

    <button *ngIf="inputActions && inputActions.create" type="button" class="btn btn-create"
      (click)="inputActions.create.handler(null)">
      <i [class]="inputActions.create.icon" aria-hidden="true"></i> {{inputActions.create.label}}
    </button>
  </div>
  <div class="table-responsive mb-3">
    <table class="table table-borderless jpg-table" [ngClass]="{'table-hover': !inputIsLoading}">
      <thead class="thead-main">
        <tr *ngIf="inputHeaders.length>0">
          <ng-container *ngFor="let header of inputHeaders">
            <th *ngIf="header.key === 'index'; else withoutIndexHeaders" scope="col"
              class="text-center align-middle col-id">
              {{header.label}}
            </th>
            <ng-template #withoutIndexHeaders>
              <th *ngIf="header.key === 'id'; else othersHeaders" scope="col" class="text-center align-middle col-id">
                {{header.label}}
              </th>
              <ng-template #othersHeaders>
                <th [sortTable]="header.sortable ? header.key : ''" (sort)="header.sortable ? onSortData($event) : null"
                  scope="col" class="text-left align-middle col-fields"
                  [ngClass]="{'disabled-header': !header.sortable}">
                  {{ header.label }}
                </th>
              </ng-template>
            </ng-template>
          </ng-container>
          <th *ngIf="inputActions" scope="col" class="text-center align-middle"> Acciones </th>
        </tr>
      </thead>
      <tbody class="tbody-main position-relative">
        <ng-container *ngIf="data.length > 0 || paginatedData.length > 0">
          <tr class="item-data"
            *ngFor="let row of paginatedData.length > 0 ? paginatedData : data; let indexData = index;">
            <ng-container *ngFor="let header of inputHeaders">
              <td *ngIf="header.key === 'index'; else withoutIndex" scope="col" class="text-center align-middle">
                {{ calculatedIndex + (indexData + 1) }}
              </td>
              <ng-template #withoutIndex>
                <td *ngIf="header.key === 'id'; else othersFields" scope="col" class="text-center align-middle">
                  {{ row[header.key] }}
                </td>
                <ng-template #othersFields>
                  <td [ngSwitch]="header.key" scope="col" class="text-left align-middle">
                    <div *ngSwitchCase="'link_imagen'" class="bg-img-preview text-center rounded p-2">
                      <img *ngIf="row[header.key]; else noImg" [src]="row[header.key]" [alt]=" row[header.key]"
                        class="img-fluid align-self-center" width="100">
                      <ng-template #noImg>
                        <img src="../../../../assets/img/no-img.png" alt="No hay imagen" class="img-fluid img-thumbnail"
                          width="100">
                      </ng-template>
                    </div>
                    <a *ngSwitchCase="'link_articulo'" role="link" [href]="row[header.key]" target="_blank"
                      rel="noopener noreferrer">{{row[header.key]}}</a>
                    <div *ngSwitchCase="'planes'">
                      <div *ngFor="let plan of row[header.key]" class="border rounded px-2 py-1 mb-1">
                        <small>
                          <strong>Plan</strong>
                          {{ plan.nombre }} - ${{ plan.precio || '0.00' }} - {{ plan.descuento || '0' }}%
                        </small>
                        <br>
                      </div>
                    </div>
                    <div *ngSwitchCase="'caracteristicas'" [innerHTML]="row[header.key] | safeHtml"></div>
                    <div *ngSwitchCase="'publicado'" class="text-center">
                      {{ row[header.key] ? 'Si': 'No' }}
                    </div>
                    <span *ngSwitchCase="'color'" class="badge p-2 w-75 text-uppercase"
                      [ngStyle]="{ 'background-color': row[header.key] }">
                      {{ row[header.key] }}
                    </span>
                    <span *ngSwitchCase="'status'" class="badge" [class.bg-warning]="row[header.key] === 'pendiente'"
                      [class.bg-info]="row[header.key] === 'procesando'"
                      [class.bg-success]="row[header.key] === 'completado'"
                      [class.bg-danger]="row[header.key] === 'fallido'" placement="right"
                      [ngbTooltip]="row.errorMessage">
                      {{row[header.key] | titlecase }}
                    </span>
                    <span *ngSwitchCase="'size'">
                      {{row[header.key]| readablebytes}}
                    </span>
                    <span *ngSwitchCase="'createdAt'">
                      {{ row[header.key] | date : "dd/MM/yyyy" }}
                    </span>
                    <ng-container *ngSwitchDefault>
                      {{ row[header.key] || '--' }}
                    </ng-container>
                  </td>
                </ng-template>
              </ng-template>
            </ng-container>
            <td *ngIf="inputActions" class="text-center align-middle">
              <a *ngIf="inputActions.edit" role="button" class="btn-edit mx-2" data-toggle="tooltip"
                data-placement="bottom" [title]="inputActions.edit.label" (click)="inputActions.edit.handler(row)">
                <i [class]="inputActions.edit.icon" aria-hidden="true"></i>
              </a>
              <a *ngIf="inputActions.detail" role="button" class="btn-detail mx-2" data-toggle="tooltip"
                data-placement="bottom" [title]="inputActions.detail.label" (click)="inputActions.detail.handler(row)">
                <i [class]="inputActions.detail.icon" aria-hidden="true"></i>
              </a>
              <a *ngIf="inputActions.delete" role="button" class="btn-delete mx-2" data-toggle="tooltip"
                data-placement="bottom" [title]="inputActions.delete.label" (click)="inputActions.delete.handler(row)">
                <i [class]="inputActions.delete.icon" aria-hidden="true"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="!inputIsLoading && data.length === 0 && paginatedData.length === 0">
          <td [attr.colspan]="inputHeaders.length+1" class="text-center align-middle">
            <i class="bi bi-exclamation-triangle-fill text-warning"></i> No hay datos disponibles
          </td>
        </tr>
        <tr *ngIf="inputIsLoading">
          <td [attr.colspan]="inputHeaders.length + 1" class="text-center align-middle py-5">
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Cargando...</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <div *ngIf="inputPagination?.per_page" class="text-start select-page">
      <span class="jpg-select">
        <select formControlName="pageSize" id="perPage" class="w-100">
          <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
        </select>
      </span>
    </div>
    <div class="mx-auto">
      <ngb-pagination *ngIf="inputPagination; else paginationFE" [collectionSize]="inputPagination.total"
        [(page)]="inputPagination.current_page" [pageSize]="inputPagination?.per_page || 10" [ellipses]="false"
        [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="emitPageChange($event)">
        <ng-template ngbPaginationFirst>Primero</ng-template>
        <ng-template ngbPaginationLast>Último</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>

      <ng-template #paginationFE>
        <ngb-pagination [collectionSize]="data.length" [(page)]="page" [pageSize]="form.get('pageSize').value"
          [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="onPageChange($event)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>&lt;</ng-template>
          <ng-template ngbPaginationNext>&gt;</ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </ng-template>
    </div>
  </div>
</form>
