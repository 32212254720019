<div class="jpg-wrapper jpg-haslayout">
  <!-- Content Wrapper Start -->
  <div class="jpg-contentwrapper">
    <app-topbar></app-topbar>

    <router-outlet></router-outlet>

    <app-footer></app-footer>
  </div>
  <!-- Content Wrapper End -->
</div>
<!-- Wrapper end -->

<div class="float-button">
  <a href="https://wa.me/+51999001132/" target="_blank">
    <img class="float-button-img" src="/assets/img/whatsapp_button.png" alt="" />
  </a>
</div>