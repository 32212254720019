import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SortEvent } from '../../directives/sortable.directive';
import { AbstractForm } from '../../../core/class/forms.abstract';
import { FormBuilder } from '@angular/forms';
import { ITableActions, ITableHeaders } from '../../../interfaces';

@Component({
  selector: 'app-table-sorteable',
  templateUrl: './table-sorteable.component.html',
  styleUrls: ['./table-sorteable.component.css']
})
export class TableSorteableComponent extends AbstractForm implements OnInit, OnChanges {

  @Input() inputHeaders: ITableHeaders[] = [];
  @Input() inputData: any[] = [];
  @Input() inputActions: ITableActions | null = null;
  @Input() inputPagination: any = null;
  @Output() outputPageChange = new EventEmitter<number>();
  @Output() outputPageSizeChange: EventEmitter<any> = new EventEmitter<any>();

  public pageSizes: number[] = [5, 10, 20, 50];
  public data: any[] = [];
  public sortColumn: string = '';
  public sortDirection: string = '';

  constructor(
    formBuilder: FormBuilder,
  ) {
    super(formBuilder);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.valuesChanges();
    if (this.inputData && this.inputData.length > 0) {
      this.data = [...this.inputData];
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData'] && !changes['inputData'].firstChange && changes['inputData'].currentValue && changes['inputData'].currentValue.length > 0) {
      this.data.length = 0;
      this.data = [...changes.inputData.currentValue];
    }
  }

  public initForm(): void {
    this.form = this.superFormBuilder.group({
      searchTerm: [null],
      pageSize: [10]
    });
  }

  private valuesChanges(): void {
    this.form.get('searchTerm')?.valueChanges.subscribe((term: string) => {
      this.onSearch(term);
    });
    this.form.get('pageSize')?.valueChanges.subscribe((pageSize: number) => {
      this.outputPageSizeChange.emit({ page: 1, pageSize });
    });
  }

  public onSort({ column, direction }: SortEvent): void {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.sortData();
  }

  public sortData(): void {
    if (this.sortDirection === '' || this.sortColumn === '') {
      this.data = [...this.inputData];
    } else {
      this.data = [...this.data].sort((a, b) => {
        const res = a[this.sortColumn] < b[this.sortColumn] ? -1 : a[this.sortColumn] > b[this.sortColumn] ? 1 : 0;
        return this.sortDirection === 'asc' ? res : -res;
      });
    }
  }

  public emitPageChange(page: number): void {
    this.outputPageChange.emit(page);
  }

  public onSearch(term: string): void {
    const searchTerm = term.trim().toLowerCase();
    if (searchTerm) {
      this.data = this.inputData.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(searchTerm)
        )
      );
    } else {
      this.data = [...this.inputData];
    }
  }

}
