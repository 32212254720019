<div class="container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white">Metodología</h2>
        <p class="text-center text-white">Inicio / Metodología</p>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <img src="../../../assets/buttons/arrow-custom.png" alt="" class="sm-width-image arrow-hero" />
      </div>
    </div>
  </div>
</div>
<br />
<div class="container reportajes">
  <div class="row">
    <div class="col-sm col-sm-12 col-md-12 ml-3 mr-3 mt-4 mb-3">
      <h2>Una nueva plataforma digital</h2>
    </div>
    <div class="col-sm-12">
      <p class="text-justify pr-2 pl-2">
        <b>Convoca.pe</b> pone a disposición de los ciudadanos la plataforma
        digital más ambiciosa que ha creado: <b>‘Convoca Deep Data’</b>, que
        reúne información relevante sobre las industrias extractivas en el Perú
        como resultado del procesamiento de más de 2.4 millones de datos y el
        análisis a diversas escalas: desde información que se remonta a cien
        años atrás hasta un indicador para medir el nivel de incumplimiento de
        las normas ambientales y laborales de las empresas de la gran minería e
        hidrocarburos en los últimos 15 años.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm col-sm-12 col-md-12 mt-2 mb-2">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <h4>Únete a</h4>
        </div>
        <div>
          <img src="../../../assets/img/logo2.png" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img src="../../../assets/img/metodologia-line.jpg" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img src="../../../assets/img/metologia-person.jpg" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12 mt-4">
      <div class="d-flex align-items-center justify-content-center">
        <div class="col-sm-12 mt-4 text-center p-0">
          <a role="button" [routerLink]="['/auth/registro']"><img src="../../../assets/buttons/registrate.png"
              class="img-fluid" alt="..." /></a>
        </div>
      </div>
    </div>
  </div>
</div>

<br /><br />

<div class="container-fluid">
  <div class="row planes-hero">
    <div class="col d-block align-self-center planes-hero-text">
      <div class="text-center">
        <h2 class="text-center text-white">Conoce nuestros planes</h2>
        <br />
        <a class="btn btn-danger btn-custom-red-2 pl-4 pr-4 pt-2 pb-2" role="button" [routerLink]="['/planes']">Ver
          planes</a>
      </div>
    </div>
  </div>
</div>

<div class="container reportajes">
  <br />

  <div class="row">
    <!-- import by shared -->
    <!-- <app-carousel></app-carousel> -->
    <!--  -->
  </div>

  <div class="row">
    <div class="col-sm col-sm-12 col-md-12 mt-2 mb-2">
      <div class="d-flex align-items-center justify-content-center">
        <div class="text-center pb-0 pt-5 pl-5 pr-5">
          <h2 class="text-secondary">Más de 2.4 millones de Datos</h2>
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img src="../../../assets/img/metodologia-hands.png" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 mt-5 p-4">
      <p class="text-justify">
        El indicador fue construido como un semáforo de alertas para medir el
        grado de incumplimiento por compañía a partir del número de infracciones
        y el nivel de gravedad. El primero paso fue organizar, limpiar y
        analizar la información de los más de 2 mil expedientes abiertos desde
        2004 hasta enero de 2020 en materia ambiental a las compañías mineras y
        petroleras y que están en manos del Organismo de Evaluación y
        Fiscalización Ambiental (OEFA). En forma simultánea se hizo lo mismo con
        los procesos sancionadores que aparecen en el registro de la
        Superintendencia Nacional de Fiscalización Laboral (Sunafil) desde 2014
        hasta 2019.
      </p>
      <p class="text-justify">
        El segundo paso fue establecer el indicador tomando en cuenta el número
        de infracciones firmes o confirmadas por la entidad reguladora y el
        nivel de gravedad de cada infracción, según las propias normas aplicadas
        por OEFA y SUNAFIL. Según el Reglamento de Supervisión de OEFA las
        infracciones contra la normas de protección ambiental están divididas
        por 'incumplimiento leve' y las que generan un riesgo significativo son
        calificadas como 'incumplimiento trascendente' (graves y muy graves).
        Asimismo, las infracciones laborales, sancionadas por SUNAFIL, están
        calificadas como leve, grave y muy grave.
      </p>
      <p class="text-justify">
        El tercer paso fue establecer un valor ponderado mediante métodos
        estadísticos que resumen el número de infracciones y el nivel de
        gravedad. Para el caso de las infracciones trascendentes en materia
        ambiental se estableció el doble de peso en relación a las leves y en el
        caso de las infracciones laborales se hizo la misma operación.
      </p>
      <p class="text-justify">
        El cuarto paso fue agrupar la lista de más de 200 compañías infractoras
        en cuatro percentiles (25% cada una) para establecer un ranking por
        nivel y colores.
      </p>
    </div>
  </div>
</div>

<div class="container-fluid">
  <img src="../../../assets/img/metodologia/grafico-correcto.jpg" class="mx-auto d-block" />
</div>

<div class="container reportajes">
  <div class="row">
    <div class="col-sm-12 mt-4 p-4">
      <p class="text-justify">
        En forma adicional se identificó con el color gris a las compañías que
        no aparecían en los registros de OEFA y SUNAFIL con procesos de sanción
        pero que sí figuraban en otras bases de datos de la plataforma: permisos
        de agua, proyectos, derrames de petróleo, accidentes, pasivos
        ambientales. En algunos casos son compañías que están en suspensión
        temporal, baja de oficio o baja definitiva, según la Superintendencia
        Nacional de Aduanas y de Administración Tributaria (SUNAT). Por ello, en
        la herramienta también pueden conocer el estado de cada empresa, el
        inicio de sus actividades, sus representantes legales y su estructura
        empresarial.
      </p>
      <p class="text-justify">
        Para establecer el indicador de incumplimiento no se tomó en cuenta el
        monto de las multas impuestas a las empresas debido a los cambios
        normativos que se realizaron en los últimos años en el Perú respecto a
        la sanción económica impuesta a las empresas. En julio de 2014, durante
        el gobierno de Ollanta Humala, se aprobó el artículo 19 de la ley 30230,
        conocida como el 'Paquetazo ambiental', que perdonó y redujo multas
        millonarias a las compañías de las industrias extractivas. El artículo
        fue derogado en abril de 2017 por una iniciativa del Congreso que
        recogía las revelaciones periodísticas que fueron publicadas por
        Convoca.pe sobre los millones que dejaba de cobrar el Estado. Sin
        embargo, los efectos de ese cambio normativo han permanecido aún hasta
        el 2019 debido a que existían procesos de sanción en curso cuando se
        derogó la norma. Una situación similar ocurrió con las multas de las
        infracciones laborales en los últimos seis años debido a que se
        redujeron sanciones económicas millonarias gracias a la Ley 30222, que
        también fue aprobada en el régimen de Humala para promover la inversión
        privada.
      </p>

      <div class="col-sm col-sm-12 col-md-12 mt-2 mb-2">
        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center p-4">
            <h2 class="text-secondary">
              Ranking de más de 200 compañías infractoras
            </h2>
          </div>
        </div>
      </div>

      <p class="text-justify">
        Es importante señalar que la plataforma incluye la información de todos
        los procesos de sanción que OEFA y SUNAFIL ha hecho público. En forma
        adicional, nuestro equipo ha construido una base de datos propia para
        establecer los montos de las multas no cobradas tras la revisión de
        miles de resoluciones de sanción.
      </p>
      <p class="text-justify">
        Si bien el periodo de análisis de los procesos de sanción que aparecen
        en el registro de OEFA van desde 2004 hasta enero de 2020, se ha tomado
        en cuenta como año de la infracción, la fecha de la resolución que
        establece la sanción firme, no el inicio del proceso. Por ello, en la
        plataforma hay infracciones ambientales que datan desde 2011, año en que
        OEFA empezó a resolver los casos en la última instancia. Esta entidad
        recién asumió en 2010 las competencias para fiscalizar la actividad
        minera en materia ambiental, antes estaba en manos del Organismo
        Supervisor de la Inversión en Energía y Minería (Osinergmin). Para el
        caso de las infracciones en temas laborales también se consideró el
        mismo criterio. SUNAFIL fue creada en abril de 2014.
      </p>

      <div class="col-sm-12 col-md-12 mb-5 mt-5">
        <div class="row">
          <div class="col d-block justify-content-center align-items-center">
            <div class="d-flex align-items-center justify-content-center">
              <img class="img-fluid semaforo-cirlce" src="../../../assets/img/red.png" alt="" />
            </div>
            <h6 class="text-center mt-3 text-danger">Rojo: Muy infractora</h6>
          </div>
          <div class="col d-block">
            <div class="d-flex align-items-center justify-content-center">
              <img class="img-fluid semaforo-cirlce" src="../../../assets/img/orange-2.png" alt="" />
            </div>
            <h6 class="text-center mt-3" style="color: #f28d59">
              Naranja: Frecuentemente infractora
            </h6>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col d-block">
            <div class="d-flex align-items-center justify-content-center">
              <img class="img-fluid semaforo-cirlce" src="../../../assets/img/yellow.png" alt="" />
            </div>
            <h6 class="text-center mt-3" style="color: #fddb7e">
              Amarillo: Medianamente infractora
            </h6>
          </div>
          <div class="col d-block">
            <div class="d-flex align-items-center justify-content-center">
              <img class="img-fluid semaforo-cirlce" src="../../../assets/img/green.png" alt="" />
            </div>
            <h6 class="text-center mt-3" style="color: #00cc8d">
              Verde: Poco infractora
            </h6>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col d-block">
            <div class="d-flex align-items-center justify-content-center">
              <img class="img-fluid semaforo-cirlce" src="../../../assets/img/gray-2.png" alt="" />
            </div>
            <h6 class="text-center mt-3" style="color: #a09998">
              Gris: Sin procesos de sanción
            </h6>
          </div>
        </div>
      </div>

      <p class="text-justify">
        La metodología para establecer el indicador de incumplimiento fue
        construida por un equipo multidisciplinario: los periodistas de
        investigación y analistas de datos de Convoca.pe, Milagros Salazar
        Herrera y Luis Enrique Pérez, y la científica de datos y estadística
        María Elena Maguiña.
      </p>
      <p class="text-justify">
        Para el procesamiento, limpieza y análisis de los otros miles de datos
        que aparecen en la plataforma, se tomó en cuenta información clave que
        el Estado genera y que resulta relevante para perfilar a las compañías.
        Accedimos a la información de las páginas oficiales de diversas
        instituciones y realizamos más de veinte pedidos de información a las
        entidades púbicas. Aquí la lista de las bases de datos que consideramos
        en esta etapa:
      </p>
      <p class="text-justify">
        Pasivos ambientales en minería (2006-2020). Fuente: Ministerio de
        Energía y Minas (MINEM). Pasivos ambientales en hidrocarburos
        (2013-2019). Fuente: Ministerio de Energía y Minas (MINEM). Derrames de
        petróleo (2011-Julio de 2020). Fuente: Organismo de Evaluación y
        Fiscalización Ambiental (OEFA). Accidentes en hidrocarburos (2011-2015).
        Fuente: Ministerio de Energía y Minas (MINEM). Accidentes en minería
        (2006-2020). Fuente: Ministerio de Energía y Minas (MINEM). Montos de
        préstamos del programa Reactiva Perú (Mayo, 2020). Fuente: Fuente:
        Ministerio de Economía y Finanzas (MEF) Número de trabajadores
        contagiados por COVID-19, según lo reportado por las empresas al
        Ministerio de Energía y Minas (Julio, 2020). Fuente: Ministerio de
        Energía y Minas (MINEM). Permisos de agua. Fuente: Autoridad Nacional de
        Agua (1928-Marzo de 2020). Proyectos mineros (2006-2020). Fuente:
        Ministerio de Energía y Minas (MINEM). Contratos de hidrocarburos de
        exploración y explotación (Abril, 2020). Fuente:Ministerio de Energía y
        Minas (MINEM). Representantes legales de las compañías. Fuente:
        Superintendencia Nacional de Administración Tributaria (SUNAT). Lista de
        empresas subsidiarias y casa matriz. Fuente: Superintendencia Nacional
        de Administración Tributaria (SUNAT), páginas web de las empresas y
        bolsa de valores de Lima.
      </p>
    </div>
  </div>
</div>

<br />

<div class="container-fluid">
  <img src="../../../assets/img/metodologia-mineria.jpg" class="mx-auto d-block" />
</div>

<br />

<div class="container reportajes">
  <div class="row">
    <div class="col-sm-12 p-4">
      <p class="text-justify">
        Para realizar la limpieza, organización y análisis de los datos
        utilizamos diversos programas: desde las tablas dinámicas del Excel,
        pasando por el software de procesamiento estadístico R para generar los
        cálculos del indicador de incumplimiento y SQL para el cruce de la
        información de las diversas tablas.
      </p>
      <p class="text-justify">
        La información procesada se complementó con los datos de las coordenadas
        de la ubicación de las operaciones de las empresas a nivel nacional para
        georeferenciarlas y visualizarlas en mapas interactivos generados con la
        herramienta MapBox.
      </p>

      <div class="col-sm col-sm-12 col-md-12 mt-2 mb-2">
        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center p-4">
            <h2 class="text-secondary">
              Visualización de todas las operaciones de empresas mineras y de
              hidrocarburos en el Perú
            </h2>
          </div>
        </div>
      </div>

      <p class="text-justify">
        La información de la plataforma se continuará actualizando en forma
        permanente y también se añadirán nuevas bases de datos como el registro
        de multas perdonadas por empresa, que ha sido construido por nuestro
        equipo a partir de miles de resoluciones de sanción.
      </p>
      <p class="text-justify">
        La herramienta se mejorará en forma constante y se añadirán nuevas
        funcionalidades a partir de las recomendaciones que los usuarios nos
        pueden enviar a <b>deepdataconvoca&#64;gmail.com</b>.
      </p>
      <p class="text-justify">
        <b>
          Te invitamos a ser parte de nuestra comunidad para que Convoca.pe
          continúe haciendo periodismo de investigación de alto impacto público.
          Buscamos ser sostenibles con tu aporte, nuestro compromiso con la
          verdad y la independencia editorial.
        </b>
      </p>
      <br /><br />
    </div>
  </div>
</div>

<div class="container reportajes">
  <div class="row">
    <div class="col-sm col-sm-12 col-md-12 mt-2 mb-2">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <h4>Únete a</h4>
        </div>
        <div>
          <img src="../../../assets/img/logo2.png" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img src="../../../assets/img/metodologia-line.jpg" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <img src="../../../assets/img/metologia-person.jpg" class="sm-width-image" alt="Logo DeepData" />
        </div>
      </div>
    </div>
    <div class="col-sm col-sm-12 col-md-12 mt-4">
      <div class="d-flex align-items-center justify-content-center">
        <div class="mr-3">
          <!-- <a class="btn btn-outline-primary">Regístrate</a> -->
        </div>
        <div class="col-sm-12 mt-4 text-center p-0">
          <a role="button" [routerLink]="['/auth/registro']"><img src="../../../assets/buttons/registrate.png"
              class="img-fluid" alt="..." /></a>
        </div>
      </div>
    </div>
  </div>
</div>

<br /><br />

<div class="container-fluid">
  <div class="row planes-hero">
    <div class="col d-block align-self-center planes-hero-text">
      <div class="text-center">
        <h2 class="text-center text-white">Conoce nuestros planes</h2>
        <br />
        <a class="btn btn-danger btn-custom-red-2 pl-4 pr-4 pt-2 pb-2" role="button" [routerLink]="['/planes']">Ver
          planes</a>
      </div>
    </div>
  </div>
</div>