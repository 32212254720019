import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { forkJoin, OperatorFunction, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { AlertService } from '../../../services/util/alert.service';
import { DataEmpresaService } from '../../../services/data-empresa.service';
import { FilterService } from '../../../services/util/filter.service';

import { Empresa } from '../../../models/empresa.model';
import { UsuarioService } from '../../../services/usuario.service';


@Component({
  selector: 'app-detalle-adicional',
  templateUrl: './detalle-adicional.component.html',
  styleUrls: ['./detalle-adicional.component.css'],
})
export class DetalleAdicionalComponent implements OnInit {
  rucFromDetalle: string;
  empresa: Empresa = new Empresa();

  isAllowed: boolean = false;

  // PARA FILTROS
  filtros: any = {
    ruc: '',
    region: '',
    sectorEconomico: '',
    nombreEmpresa: '',
    page: '',
    size: 10,
  };

  cboRegiones: any[] = [];
  cboAnios: any[] = [];
  cboActividadEconomica: any[] = [];

  selectedRegion: any;
  selectedAnio: any;
  selectedActividad: any;
  organizacion: string = '';

  infracciones: any[] = [];

  public indicadoresEmpresa: { id: number; value: number; name: string; headers: any[]; title: string; }[] = [];
  public detalleEmpresaTables: { id: number; tableName: string; headers: any[]; data: any[]; collectionSize: number }[] = [];
  public paginationSettings: { [key: string]: { page: number; size: number } } = {};

  indicador: number = 1;

  selectedIndicador: any;
  detailRow: boolean = false;
  selectedRowDetail: any = null;

  // Search empresa
  searching = false;
  searchFailed = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private _dataEmpresaService: DataEmpresaService,
    private _alertService: AlertService,
    private _filterService: FilterService,
    public _usuarioService: UsuarioService
  ) {
    this._dataEmpresaService.validateUserPublic();
    if (this._usuarioService.perfilUsuario) {
      // PARA EVALUAR SU MEMBRESÍA
      this.isAllowed = true;
      this.getRucFromDetalleEmpresa();
      this.getHeadersForFilter();
    } else {
      // SI ES USUARIO SIN REGISTRO
      if (this._usuarioService.isWithoutRegister()) {
        this._alertService.alertInfo(
          'Regístrate',
          'Regístrate para obtener una Membresía Gratuita y accede a todas las funcionalidades básicas.',
          'Registrarse'
        ).then((resp) => {
          if (resp.value) {
            this.router.navigate(['/auth/registro']);
          }
        });
      } else {
        this.isAllowed = true;
        this.getRucFromDetalleEmpresa();
        this.getHeadersForFilter();
      }
    }
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  // PARA EL SEARCH EMPRESA
  search: OperatorFunction<string, readonly { name }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._dataEmpresaService.getEmpresasInput(term, 6).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  formatMatches = (value: any) => value.NombreEmpresa || '';

  selectedItem(value) {
    this.organizacion = value.item.NombreEmpresa;
    this.realizarBusqueda();
  }

  public checkUsuario(): void {
    // SI ES USUARIO FREE
    if (
      this._usuarioService.perfilUsuario?.codigo_membresia === 'FREE' ||
      !this._usuarioService.perfilUsuario?.codigo_membresia
    ) {
      this._alertService.alertInfo(
        'Descubre más funcionalidades',
        'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.',
        'Ver planes'
      ).then((resp) => {
        if (resp.value) {
          this.router.navigate(['/planes']);
        }
      });
    } else {
      this.isAllowed = true;
      this.getRucFromDetalleEmpresa();
      this.getHeadersForFilter();
    }
  }

  public getRucFromDetalleEmpresa(): void {
    this.route.queryParams.subscribe(async (params) => {
      try {
        if (params['ruc']) {
          this.rucFromDetalle = params['ruc'];
          this.getDetalleEmpresa(this.rucFromDetalle);
          this.getIndicadoresEmpresa(this.rucFromDetalle);
          if (params['indicador']) {
            this.indicador = parseInt(params['indicador']);
          } else {
            this.indicador = 1;
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  // PARA FILTROS DINÁMICOS
  public limpiarFiltros(redirect: boolean = false): void {
    this.filtros.ruc = '';
    this.filtros.region = '';
    this.filtros.sectorEconomico = '';
    this.filtros.nombreEmpresa = '';
    this.filtros.agno = '';
    this.filtros.page = 1;

    if (redirect) this.realizarBusqueda();
  }

  public getHeadersForFilter(): void {
    this._dataEmpresaService.getHeadersForFilter().subscribe((resp) => {
      if (resp?.success) {
        this.cboRegiones = resp.headers.Region.sort();
        this.cboRegiones.unshift('TODOS');
        // this.cboRegiones = resp.headers.Region;
        this.cboAnios = resp.headers.Agno.sort();
        this.cboAnios.reverse();
        this.cboAnios.unshift('TODOS');

        this.cboActividadEconomica = resp.headers.SectorEconomico.sort();
        this.cboActividadEconomica.unshift('TODOS');

        this.selectedRegion = this.cboRegiones[0];
        this.selectedAnio = this.cboAnios[0];
        this.selectedActividad = this.cboActividadEconomica[0];
      }
      // this._alertService.closeAlert();
    });
  }

  // PARA EMPRESA
  public getDetalleEmpresa(ruc: string): void {
    this._dataEmpresaService.getEmpresasByRuc(ruc).subscribe((resp) => {
      if (resp.success && resp.empresas) {
        this.empresa = resp.empresas[0];
      }
    });
  }

  // GET INDICADORES DE EMPRESA
  public getIndicadoresEmpresa(ruc: string): void {
    this._dataEmpresaService.getIndicadoresEmpresa(ruc).subscribe((resp) => {
      this.indicadoresEmpresa = resp.data;
      if (this.isAllowed) this.getDetallesEmpresaFromTabla();
    });
  }

  // GET INFORMACION DE TABLAS
  public getDetallesEmpresaFromTabla(): void {
    this.selectedIndicador = this.indicadoresEmpresa.find((indicator) => indicator.id === this.indicador);
    this.indicadoresEmpresa.forEach((table) => {
      if (!this.paginationSettings[table.name]) {
        this.paginationSettings[table.name] = { page: 1, size: 10 };
      }
    });

    const requests$ = this.indicadoresEmpresa.map((table) =>
      this._dataEmpresaService.getDetalleEmpresaByRucFromTablaPaginated(
        this.rucFromDetalle,
        table.name,
        this.paginationSettings[table.name].page,
        this.paginationSettings[table.name].size
      ).pipe(
        map(response => ({
          id: table.id,
          tableName: table.name,
          headers: table.headers,
          data: response.empresas || [],
          collectionSize: response.registros || 0
        }))
      ));

    this._alertService.alertLoading(`Cargando información...`);
    forkJoin(requests$).subscribe((results) => {
      results.forEach((result: any) => {
        this.detalleEmpresaTables.push(result);
      });
      this._alertService.closeAlert();
    });
  }

  // GET PAGINADO DE TABLAS
  public getDetallesEmpresaFromTablaPaginated(tableName: string): void {
    const { page, size } = this.paginationSettings[tableName];
    this._alertService.alertLoading(`Cargando información...`);
    this._dataEmpresaService
      .getDetalleEmpresaByRucFromTablaPaginated(this.rucFromDetalle, tableName, page, size)
      .subscribe((response: any) => {
        const updatedTable = this.detalleEmpresaTables.find((t) => t.tableName === tableName);
        if (updatedTable) {
          updatedTable.data = response?.empresas || [];
          updatedTable.collectionSize = response?.registros || 0;
        }
        this._alertService.closeAlert();
      });
  }

  public realizarBusqueda(): void {
    this.filtros.ruc = '';

    if (this.selectedRegion && this.selectedRegion != 'TODOS') {
      this.filtros.region = this.selectedRegion;
    } else {
      this.filtros.region = '';
    }

    if (this.selectedAnio && this.selectedAnio != 'TODOS') {
      this.filtros.agno = this.selectedAnio;
    } else {
      this.filtros.agno = '';
    }

    if (this.selectedActividad && this.selectedActividad != 'TODOS') {
      this.filtros.sectorEconomico = this.selectedActividad.trim();
    } else {
      this.filtros.sectorEconomico = '';
    }

    if (this.organizacion && this.organizacion != '') {
      this.filtros.nombreEmpresa = this.organizacion
        .trim()
        .replace(/\s\s+/g, ' ');
    } else {
      this.filtros.nombreEmpresa = '';
    }

    let navigationExtras: NavigationExtras = {};
    if (this.filtros) {
      this._filterService.setFiltrosIndustrias(this.filtros);

      // navigationExtras = {

      //   queryParams: {
      //       "filtros": JSON.stringify(this.filtros)
      //   },
      //   skipLocationChange: false
      // };
    }
    // this.router.navigate(["/industrias-extractivas"],navigationExtras);
    this.router.navigate(['/industrias-extractivas']);
  }

  public verDetalleEmpresa(): void {
    this._alertService.alertLoading(`Cargando información...`);

    let navigationExtras: NavigationExtras = {};
    if (this.rucFromDetalle) {
      navigationExtras = {
        queryParams: {
          //"empresa": JSON.stringify(empresa)
          ruc: this.rucFromDetalle,
        },
        skipLocationChange: false,
      };
    }

    this.router.navigate(['/detalle-empresa'], navigationExtras);
  }

  // PARA DESCARGAR DE ARCHIVO
  public descargarResultadosAll(tableName: string): void {
    this._dataEmpresaService.getDetalleEmpresaByRucFromTablaDescargar(this.rucFromDetalle, tableName)
      .subscribe((result) => {
        const fileName: string = `${this.rucFromDetalle}-${tableName.replace(/^fac_/, '').replace(/_/g, '-')}`;
        this.exportExelFile(result, fileName);
      });
  }

  // PARA EXPORTAR A EXCEL
  public exportExelFile(data, filename: string): void {
    const blobValue = new Blob([data['body']], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blobValue, filename + '.xlsx');
  }

  // PARA VER EL DETALL DE UNA FILA (MOBILE)
  public verDetalleRow(object): void {
    this.selectedRowDetail = object;
    this.detailRow = true;

    window.scrollTo(0, 1000);
  }

  // PARA VOLVER A LA LISTA DE DETALLES
  public volverListaDetalle(): void {
    this.selectedRowDetail = null;
    this.detailRow = false;
  }

  // VALIDAR VER HEADER Y VALUES
  public verHeaderValuesDetalleEmpresa(header: any, tableName: string): boolean {
    switch (tableName) {
      case 'fac_informacion_proyectos':
        if (header.key === 'unidad_ambiental' || header.key === 'tipo_proyecto') return true;
        break;
      case 'fac_informacion_derrames':
        if (header.key === 'lote' || header.key === 'descripcion') return true;
        break;
      case 'fac_informacion_permisos':
        if (header.key === 'volumen_m3' || header.key === 'clase_derecho') return true;
        break;
      case 'fac_informacion_accidentes':
        if (header.key === 'lote' || header.key === 'nivel_gravedad') return true;
        break;
      default:
        if (header.key === 'ruc') return true;
        break;
    }
    return false;
  }

  // VER DETALLE DE UNA FILA
  public verCamposDetalleEmpresa(header: any, tableName: string): boolean {
    switch (tableName) {
      case 'fac_informacion_proyectos':
        if (header.key === 'unidad_ambiental' || header.key === 'tipo_proyecto') return false;
        break;
      case 'fac_informacion_derrames':
        if (header.key === 'lote' || header.key === 'descripcion') return false;
        break;
      case 'fac_informacion_permisos':
        if (header.key === 'volumen_m3' || header.key === 'clase_derecho') return false;
        break;
      case 'fac_informacion_accidentes':
        if (header.key === 'lote' || header.key === 'nivel_gravedad') return false;
        break;
      default:
        if (header.key === 'ruc') return false;
        break;
    }
    return this.selectedRowDetail[header.key] !== undefined && this.selectedRowDetail[header.key] !== null;
  }

}
