<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Necesitamos saber que no eres un robot</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <form [formGroup]="formaCaptcha" (ngSubmit)="enviarCaptcha()">
        <div class="form-row">
            <div class="form-group col-md-12 d-flex justify-content-center mb-0">
                <re-captcha formControlName="recaptchaReactive" name="recaptchaReactive"></re-captcha>
                <!-- <div class="g-recaptcha" data-sitekey="6LcDEMoZAAAAAL1zYADrCQpJ0MBJ9GR115ElDjvr"></div> -->
            </div>
            <div class="form-group col-md-12 d-flex justify-content-center mt-1 mb-0" *ngIf="!flagFormaValida">
                <span class="text-danger">Debes de llenar el captcha.</span>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cerrar</button> -->
    <button type="button" class="btn btn-success" (click)="enviarCaptcha()">Enviar captcha</button>
</div>