// MODULES
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../shared/shared.module';

// COMPONENTES
import { PagesComponent } from './pages.component';

import { PlanesComponent } from './planes/planes.component';
import { PagoResultComponent } from './pagos/pago-result/pago-result.component';
import { OnlyDesktopComponent } from './only-desktop/only-desktop.component';

import { IndustriasExtractivasComponent, NgbdSortableHeader } from './search/industrias-extractivas/industrias-extractivas.component';
import { DetalleEmpresaComponent } from './search/detalle-empresa/detalle-empresa.component';
import { DetalleSemaforoComponent } from './search/detalle-semaforo/detalle-semaforo.component';
import { BusquedaMasivaComponent } from './search/busqueda-masiva/busqueda-masiva.component';
import { BusquedaMasivaDatosEncontradosComponent } from './search/busqueda-masiva-datos-encontrados/busqueda-masiva-datos-encontrados.component';
import { DetalleAdicionalComponent } from './search/detalle-adicional/detalle-adicional.component';

@NgModule({
  declarations: [
    PagesComponent,
    PlanesComponent,
    PagoResultComponent,
    DetalleAdicionalComponent,
    OnlyDesktopComponent,

    IndustriasExtractivasComponent,
    NgbdSortableHeader,
    DetalleEmpresaComponent,
    DetalleSemaforoComponent,
    BusquedaMasivaComponent,
    BusquedaMasivaDatosEncontradosComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
  ],
  exports: [
    //IndustriasExtractivasComponent,
  ],
  bootstrap: [IndustriasExtractivasComponent]
})
export class PagesModule { }
