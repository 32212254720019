// MODULES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';

// COMPONENTS
import { CarouselComponent } from './components/carousel/carousel.component';
import { TableSorteableComponent } from './components/table-sorteable/table-sorteable.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SortTableHeaderDirective } from './directives/sortable.directive';
import { EditModalComponent } from './components/edit-modal/edit-modal.component';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { ShowForRolesDirective } from './directives/show-for-roles.directive';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReadablebytesPipe } from './pipes/readablebytes.pipe';
import { SelectionComponent } from './components/selection/selection.component';

@NgModule({
  declarations: [
    CarouselComponent,
    TableSorteableComponent,
    EditModalComponent,
    SelectionComponent,

    SortTableHeaderDirective,
    FileDragNDropDirective,
    ShowForRolesDirective,
    SafeHtmlPipe,
    ReadablebytesPipe,

  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgxSimpleTextEditorModule,
    RouterModule,
    HttpClientModule,
  ],
  exports: [
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgxSimpleTextEditorModule,
    RouterModule,
    HttpClientModule,

    CarouselComponent,
    TableSorteableComponent,
    EditModalComponent,
    SelectionComponent,

    SortTableHeaderDirective,
    FileDragNDropDirective,
    ShowForRolesDirective,
    SafeHtmlPipe,

    ReadablebytesPipe
  ]
})
export class SharedModule { }
