import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { forkJoin, OperatorFunction, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';

import * as FileSaver from 'file-saver';

import { AlertService } from '../../../services/util/alert.service';
import { DataEmpresaService } from '../../../services/data-empresa.service';
import { FilterService } from '../../../services/util/filter.service';

import { Empresa } from '../../../models/empresa.model';
import { UsuarioService } from '../../../services/usuario.service';


@Component({
  selector: 'app-detalle-semaforo',
  templateUrl: './detalle-semaforo.component.html',
  styleUrls: ['./detalle-semaforo.component.css'],
})
export class DetalleSemaforoComponent implements OnInit {
  rucFromDetalle: string;
  empresa: Empresa = new Empresa();

  isAllowed: boolean = false;

  // PARA FILTROS
  filtros: any = {
    ruc: '',
    region: '',
    sectorEconomico: '',
    nombreEmpresa: '',
    page: '',
    size: 10,
  };

  cboRegiones: any[] = [];
  cboAnios: any[] = [];
  cboActividadEconomica: any[] = [];

  selectedRegion: any;
  selectedAnio: any;
  selectedActividad: any;
  organizacion: string = '';

  infracciones: any[] = [];

  infraccionesAmbientales: any[] = [];
  infraccionesLaborales: any[] = [];
  multasPerdonadas: any[] = [];
  multasJudicializadas: any[] = [];

  infraccionesAmbientalesPaginated: any[] = [];
  infraccionesAmbientalesPage = 1;
  infraccionesAmbientalesPageSize = 10;
  infraccionesAmbientalesCollectionSize = this.infraccionesAmbientales.length;

  infraccionesLaboralesPaginated: any[] = [];
  infraccionesLaboralesPage = 1;
  infraccionesLaboralesPageSize = 10;
  infraccionesLaboralesCollectionSize = this.infraccionesLaborales.length;

  multasPerdonadasPaginated: any[] = [];
  multasPerdonadasPage = 1;
  multasPerdonadasPageSize = 10;
  multasPerdonadasCollectionSize = this.multasPerdonadas.length;

  multasJudicializadasPaginated: any[] = [];
  multasJudicializadasPage = 1;
  multasJudicializadasPageSize = 10;
  multasJudicializadasCollectionSize = this.multasJudicializadas.length;

  indicador: any;

  /*Para el mobile*/
  cboIndicadores: any[] = [
    { id: 'Infracciones ambientales', value: '1' },
    { id: 'Infracciones laborales', value: '2' },
    // { id: 'Multas perdonadas', value: '3' },
    { id: 'Multas judicializadas', value: '4' },
  ];

  selectedIndicador: any;
  detailRow: boolean = false;
  selectedRowDetail: any = null;

  // Search empresa
  searching = false;
  searchFailed = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _dataEmpresaService: DataEmpresaService,
    public _alertService: AlertService,
    public _filterService: FilterService,
    public _usuarioService: UsuarioService
  ) {
    this._dataEmpresaService.validateUserPublic();

    if (this._usuarioService.perfilUsuario) {
      // PARA EVALUAR SU MEMBRESÍA
      this.isAllowed = true;
      this.getRucFromDetalleEmpresa(this.route);
      this.getHeadersForFilter();
    } else {
      // SI ES USUARIO SIN REGISTRO
      if (this._usuarioService.isWithoutRegister()) {
        this._alertService
          .alertInfo(
            'Regístrate',
            'Regístrate para obtener una Membresía Gratuita y accede a todas las funcionalidades básicas.',
            'Registrarse'
          )
          .then((resp) => {
            if (resp.value) {
              this.router.navigate(['/auth/registro']);
            }
          });
      } else {
        this.isAllowed = true;
        this.getRucFromDetalleEmpresa(this.route);
        this.getHeadersForFilter();
      }
    }
  }

  // PARA EL SEARCH EMPRESA
  search: OperatorFunction<string, readonly { name }[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._dataEmpresaService.getEmpresasInput(term, 6).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  formatMatches = (value: any) => value.NombreEmpresa || '';

  selectedItem(value) {
    this.organizacion = value.item.NombreEmpresa;
    this.realizarBusqueda();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if (this.isAllowed) this.getDetalleEmpresaFromTabla(this.rucFromDetalle);
  }

  checkUsuario() {
    // SI ES USUARIO FREE
    if (
      this._usuarioService.perfilUsuario?.codigo_membresia === 'FREE' ||
      !this._usuarioService.perfilUsuario?.codigo_membresia
    ) {
      this._alertService
        .alertInfo(
          'Descubre más funcionalidades',
          'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.',
          'Ver planes'
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/planes']);
          }
        });
    } else {
      this.isAllowed = true;
      this.getRucFromDetalleEmpresa(this.route);
      this.getHeadersForFilter();
    }
  }

  getRucFromDetalleEmpresa(route: ActivatedRoute) {
    route.queryParams.subscribe(async (params) => {
      try {
        if (params['ruc']) {
          this.rucFromDetalle = params['ruc'];
          this.getDetalleEmpresa(this.rucFromDetalle);

          this.indicador = params['indicador'];

          // PARA OCULTAR MULTAS PERDONADAS
          // if (this.indicador != 1 && this.indicador != 2 && this.indicador != 4) {
          //   this.indicador = 1;
          // }

          this.selectedIndicador = this.cboIndicadores[this.indicador - 1];

          for (let i = 0; i < this.cboIndicadores.length; i++) {
            if (this.indicador == this.cboIndicadores[i].value) {
              this.selectedIndicador = this.cboIndicadores[i];
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  // PARA FILTROS DINÁMICOS
  limpiarFiltros(redirect: boolean = false) {
    this.filtros.ruc = '';
    this.filtros.region = '';
    this.filtros.sectorEconomico = '';
    this.filtros.nombreEmpresa = '';
    this.filtros.agno = '';
    this.filtros.page = 1;

    if (redirect) {
      this.filtros.ruc = '';
      this.filtros.tipoInfraccion = 'ambiental';
      this.realizarBusqueda();
    }
  }

  getHeadersForFilter() {
    this._dataEmpresaService.getHeadersForFilter().subscribe((resp) => {
      if (resp?.success) {
        this.cboRegiones = resp.headers.Region.sort();
        this.cboRegiones.unshift('TODOS');
        // this.cboRegiones = resp.headers.Region;
        this.cboAnios = resp.headers.Agno.sort();
        this.cboAnios.reverse();
        this.cboAnios.unshift('TODOS');

        this.cboActividadEconomica = resp.headers.SectorEconomico.sort();
        this.cboActividadEconomica.unshift('TODOS');

        this.selectedRegion = this.cboRegiones[0];
        this.selectedAnio = this.cboAnios[0];
        this.selectedActividad = this.cboActividadEconomica[0];
      }
      // this._alertService.closeAlert();
    });
  }

  // PARA EMPRESA
  getDetalleEmpresa(ruc: string) {
    this._dataEmpresaService.getEmpresasByRuc(ruc).subscribe((resp) => {
      if (resp.success && resp.empresas) {
        this.empresa = resp.empresas[0];
      }
    });
  }

  // GET INFORMACION DE TABLAS
  getDetalleEmpresaFromTabla(ruc: string) {
    const infraccionesAmbientales$ =
      this._dataEmpresaService.getDetalleEmpresaByRucFromTablaPaginated(
        ruc,
        'fac_infracciones_ambientales',
        this.infraccionesAmbientalesPage,
        this.infraccionesAmbientalesPageSize
      );
    const infraccionesLaborales$ =
      this._dataEmpresaService.getDetalleEmpresaByRucFromTablaPaginated(
        ruc,
        'fac_infracciones_laborales',
        this.infraccionesLaboralesPage,
        this.infraccionesLaboralesPageSize
      );
    const multasPerdonadas$ =
      this._dataEmpresaService.getDetalleEmpresaByRucFromTablaPaginated(
        ruc,
        'fac_multas_perdonadas',
        this.multasPerdonadasPage,
        this.multasPerdonadasPageSize
      );
    const multasJudicializadas$ =
      this._dataEmpresaService.getDetalleEmpresaByRucFromTablaPaginated(
        ruc,
        'fac_multas_laborales_judicial',
        this.multasJudicializadasPage,
        this.multasJudicializadasPageSize
      );

    const allrequests = forkJoin(
      infraccionesAmbientales$,
      infraccionesLaborales$,
      multasPerdonadas$,
      multasJudicializadas$
    );

    allrequests.subscribe((results) => {
      this.infraccionesAmbientales = results[0]?.empresas;
      this.infraccionesAmbientalesPaginated = this.infraccionesAmbientales;
      this.infraccionesAmbientalesCollectionSize = results[0]?.registros;

      this.infraccionesLaborales = results[1]?.empresas;
      this.infraccionesLaboralesPaginated = this.infraccionesLaborales;
      this.infraccionesLaboralesCollectionSize = results[1]?.registros;

      this.multasPerdonadas = results[2]?.empresas;
      this.multasPerdonadasPaginated = this.multasPerdonadas;
      this.multasPerdonadasCollectionSize = results[2]?.registros;

      this.multasJudicializadas = results[3]?.empresas;
      this.multasJudicializadasPaginated = this.multasJudicializadas;
      this.multasJudicializadasCollectionSize = results[3]?.registros;

      this._alertService.closeAlert();
    });
  }

  refreshData(indicador) {
    let tabla = '';
    let pagina = 0;
    let limite = 10;

    switch (indicador) {
      case 1:
        tabla = 'fac_infracciones_ambientales';
        pagina = this.infraccionesAmbientalesPage;
        limite = this.infraccionesAmbientalesPageSize;
        break;
      case 2:
        tabla = 'fac_infracciones_laborales';
        pagina = this.infraccionesLaboralesPage;
        limite = this.infraccionesLaboralesPageSize;
        break;
      case 3:
        tabla = 'fac_multas_perdonadas';
        pagina = this.multasPerdonadasPage;
        limite = this.multasPerdonadasPageSize;
        break;
      case 4:
        tabla = 'fac_multas_laborales_judicial';
        pagina = this.multasJudicializadasPage;
        limite = this.multasJudicializadasPageSize;
        break;
      default:
        break;
    }

    this._alertService.alertLoading(`Cargando información...`);

    this._dataEmpresaService
      .getDetalleEmpresaByRucFromTablaPaginated(
        this.rucFromDetalle,
        tabla,
        pagina,
        limite
      )
      .subscribe((results: any) => {
        switch (indicador) {
          case 1:
            this.infraccionesAmbientales = results?.empresas;
            this.infraccionesAmbientalesPaginated =
              this.infraccionesAmbientales;
            this.infraccionesAmbientalesCollectionSize = results?.registros;
            break;
          case 2:
            this.infraccionesLaborales = results?.empresas;
            this.infraccionesLaboralesPaginated = this.infraccionesLaborales;
            this.infraccionesLaboralesCollectionSize = results?.registros;
            break;
          case 3:
            this.multasPerdonadas = results?.empresas;
            this.multasPerdonadasPaginated = this.multasPerdonadas;
            this.multasPerdonadasCollectionSize = results?.registros;
            break;
          case 4:
            this.multasJudicializadas = results?.empresas;
            this.multasJudicializadasPaginated = this.multasJudicializadas;
            this.multasJudicializadasCollectionSize = results?.registros;
            break;
          default:
            break;
        }

        this._alertService.closeAlert();
      });
    window.scrollTo(0, 0);
  }

  // PARA DETALLE SEMÁFORO - YA NO SE USA
  getDetalleSemaforo() {
    // this._alertService.alertLoading('Cargando mapa');

    if (this.rucFromDetalle) {
      this._dataEmpresaService
        .getInfoForMapByRuc(this.rucFromDetalle)
        .subscribe(async (resp) => {
          this.infracciones = resp.empresas;

          this._alertService.closeAlert();
        });
    }
  }

  realizarBusqueda() {
    this.filtros.ruc = '';

    if (this.selectedRegion && this.selectedRegion != 'TODOS') {
      this.filtros.region = this.selectedRegion;
    } else {
      this.filtros.region = '';
    }

    if (this.selectedAnio && this.selectedAnio != 'TODOS') {
      this.filtros.agno = this.selectedAnio;
    } else {
      this.filtros.agno = '';
    }

    if (this.selectedActividad && this.selectedActividad != 'TODOS') {
      this.filtros.sectorEconomico = this.selectedActividad.trim();
    } else {
      this.filtros.sectorEconomico = '';
    }

    if (this.organizacion && this.organizacion != '') {
      this.filtros.nombreEmpresa = this.organizacion
        .trim()
        .replace(/\s\s+/g, ' ');
    } else {
      this.filtros.nombreEmpresa = '';
    }

    let navigationExtras: NavigationExtras = {};
    if (this.filtros) {
      this._filterService.setFiltrosIndustrias(this.filtros);

      // navigationExtras = {

      //   queryParams: {
      //       "filtros": JSON.stringify(this.filtros)
      //   },
      //   skipLocationChange: false
      // };
    }
    // this.router.navigate(["/industrias-extractivas"],navigationExtras);
    this.router.navigate(['/industrias-extractivas']);
  }

  verDetalleEmpresa() {
    this._alertService.alertLoading(`Cargando información...`);

    let navigationExtras: NavigationExtras = {};
    if (this.rucFromDetalle) {
      navigationExtras = {
        queryParams: {
          //"empresa": JSON.stringify(empresa)
          ruc: this.rucFromDetalle,
        },
        skipLocationChange: false,
      };
    }

    this.router.navigate(['/detalle-empresa'], navigationExtras);
  }

  formatdatanumber(data: number = 0) {
    const numberFormat1 = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
    });
    return numberFormat1.format(data);
  }

  // PARA DESCARGAR DE ARCHIVO
  descargarResultados(indicador) {
    let table = '';
    let nameFile = '';
    if (indicador === 1) {
      table = 'fac_infracciones_ambientales';
      nameFile = this.rucFromDetalle + '-infracciones-ambientales';
    } else if (indicador === 2) {
      table = 'fac_infracciones_laborales';
      nameFile = this.rucFromDetalle + '-infracciones-laborales';
    } else if (indicador === 3) {
      table = 'fac_multas_perdonadas';
      nameFile = this.rucFromDetalle + '-multas-perdonadas';
    } else if (indicador === 4) {
      table = 'fac_multas_laborales_judicial';
      nameFile = this.rucFromDetalle + '-multas-judicializadas';
    } else {
      table = 'fac_infracciones_ambientales';
    }

    this._dataEmpresaService
      .getDetalleEmpresaByRucFromTablaDescargar(this.rucFromDetalle, table)
      .subscribe((result) => {
        this.exportExelFile(result, nameFile);
      });
  }

  exportExelFile(data, filename) {
    const blobValue = new Blob([data['body']], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blobValue, filename + '.xlsx');
  }

  // PARA VER EL DETALL DE UNA FILA (MOBILE)
  verDetalleRow(object) {
    this.selectedRowDetail = object;
    this.detailRow = true;

    window.scrollTo(0, 1000);
  }

  volverListaDetalle() {
    this.selectedRowDetail = null;
    this.detailRow = false;
  }

  //VALIDACION DE URL
  public validarUrl(url: string): boolean {
    const urlPattern = /^(http|https):\/\//;
    return urlPattern.test(url);
  }

}
