// ng-router to generate
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';




import { IndustriasExtractivasComponent } from './search/industrias-extractivas/industrias-extractivas.component';
import { DetalleEmpresaComponent } from './search/detalle-empresa/detalle-empresa.component';
import { DetalleSemaforoComponent } from './search/detalle-semaforo/detalle-semaforo.component';
import { BusquedaMasivaComponent } from './search/busqueda-masiva/busqueda-masiva.component';
import { BusquedaMasivaDatosEncontradosComponent } from './search/busqueda-masiva-datos-encontrados/busqueda-masiva-datos-encontrados.component';

import { PlanesComponent } from './planes/planes.component';
import { PagoResultComponent } from './pagos/pago-result/pago-result.component';
import { DetalleAdicionalComponent } from './search/detalle-adicional/detalle-adicional.component';
import { OnlyDesktopComponent } from './only-desktop/only-desktop.component';
import { MetodologiasComponent } from '../modules/main/pages/metodologias/metodologias.component';


const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: '', component: IndustriasExtractivasComponent },
      { path: 'only-desktop', component: OnlyDesktopComponent },
      { path: 'planes', component: PlanesComponent },
      { path: 'pago/result', component: PagoResultComponent },
      { path: 'industrias-extractivas', component: IndustriasExtractivasComponent },
      { path: 'detalle-empresa', component: DetalleEmpresaComponent },
      { path: 'detalle-semaforo', component: DetalleSemaforoComponent },
      { path: 'detalle-adicional', component: DetalleAdicionalComponent },
      { path: 'busqueda-masiva', component: BusquedaMasivaComponent },
      { path: 'busqueda-masiva-datos', component: BusquedaMasivaDatosEncontradosComponent },
      { path: 'metodologias', component: MetodologiasComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
