<!--Inner Home Banner Start-->
<div class="d-none d-lg-block">
  <div class="jpg-haslayout banner-extractivas">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <div class="jpg-innerbannercontent">
            <div class="jpg-title">
              <h2 class="hero-title">
                Conoce a las empresas mineras y de hidrocarburos en el Perú
              </h2>
            </div>
          </div>
          <div class="breadcum row d-flex justify-content-center align-items-center">
            <a href="https://convoca.pe/newslettercomunidatos" target="_blank">
              <p class="text-center text-white mb-0 mx-2">Newsletter</p>
            </a>
            <p class="mb-0 text-white">|</p>
            <a href="" [routerLink]="" (click)="shareFacebook()">
              <img class="mx-2" src="../../../../assets/icons/fb-icon.png" />
            </a>
            <a href="" [routerLink]="" (click)="shareTwitter()">
              <img class="mx-2" src="../../../../assets/icons/twitter-icon.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-block d-lg-none container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white banner-mobile">
          Conoce a las empresas mineras y de hidrocarburos en el Perú
        </h2>
        <div class="d-flex justify-content-center align-items-center">
          <a href="https://convoca.pe/newslettercomunidatos" target="_blank">
            <p class="text-center text-white mb-0">Newsletter</p>
          </a>
          <p class="mb-0 text-white mx-2">|</p>
          <a href="" [routerLink]="" (click)="shareFacebook()">
            <img class="mx-2" src="../../../../assets/icons/fb-icon.png" />
          </a>
          <a href="" [routerLink]="" (click)="shareTwitter()">
            <img class="mx-2" src="../../../../assets/icons/twitter-icon.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Home Banner End-->

<!-- FILTERS DESKTOP -->
<div class="d-none d-lg-block jpg-haslayout">
  <div class="">
    <!-- CARGA MASIVA BANNER-->
    <div class="card border-0 mr-0 ml-0 mb-3 ">
      <div class="card-body p-0 ">
        <div class="carga-masiva-banner d-flex justify-content-center align-items-center">
          <p class="mb-0 mx-2 text-white cm-banner-title">
            Cruza tu base de datos
          </p>
          <button type="button" class="btn btn-danger cm-button mx-1 text-uppercase font-weight-bold"
            (click)="busquedaMasivaRedirect()">
            BÚSQUEDA MASIVA
          </button>
        </div>
      </div>
    </div>
    <div class="jpg-categoryslidercontent">
      <div class="row">
        <div class="col-12 col-lg-8 d-flex justify-content-between align-items-center">
          <div class="jpg-cattitle mx-3">
            <h3 class="mb-2">
              <img src="../../../../assets/icons/economy-activity-icon.png" class="mr-2" />Actividad económica
            </h3>
            <span class="jpg-select">
              <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad" class="w-100">
                <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                  {{ actividad }}
                </option>
              </select>
            </span>
          </div>

          <div class="jpg-cattitle mx-3">
            <h3 class="mb-2">
              <img src="../../../../assets/icons/empresa.png" class="mr-2" />Empresa
            </h3>
            <div class="form-group mb-0">
              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
              </ng-template>
              <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
                [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
                [resultFormatter]="formatMatches" [inputFormatter]="formatMatches"
                (selectItem)="selectedItem($event)" />
              <div class="invalid-feedback" *ngIf="searchFailed">
                No encontramos coincidencias
              </div>
            </div>
          </div>

          <div class="jpg-cattitle mx-3">
            <h3>
              <img src="../../../../assets/icons/map-icon.png" class="mr-2" />Región
            </h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion">
                  <option *ngFor="let region of cboRegiones" [ngValue]="region">
                    {{ region }}
                  </option>
                </select>
              </span>
            </div>
          </div>

          <div class="jpg-cattitle mx-3">
            <h3>
              <img src="../../../../assets/icons/calendar-icon.png" class="mr-2" />
              Año
            </h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio">
                  <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                    {{ anio }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md pl-4 pb-4 d-flex justify-content-start align-items-end">
          <button type="button" class="btn btn-dark filter-button text-uppercase font-weight-bold"
            (click)="realizarBusqueda()">
            BUSCAR
          </button>
        </div>
        <div class="col-12 col-md py-4 d-flex align-items-center">
          <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%">
            <h2 class="filter-title">Filtrar resultados:</h2>
            <a href="" [routerLink]="" (click)="limpiarFiltros()" class="red-link">Limpiar filtros</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-12">
      <div class="row filters-mobile-buttons">
        <div class="col-6 p-0 py-3 d-flex justify-content-center filters-mobile-button">
          <a href="" [routerLink]="" (click)="openMobileFilterModal(mobileFilter)"
            class="w-100 link-filter d-flex align-items-center justify-content-center"><img
              src="../../../../assets/icons/filter.png" class="mr-2" />
            Filtrar por
          </a>
        </div>
        <div class="col-6 p-0 py-3 d-flex justify-content-center filters-mobile-button">
          <a href="" [routerLink]="" (click)="showOrderButtons()"
            class="w-100 link-filter d-flex align-items-center justify-content-center" #toggleOrdenamientoMenu>
            <img src="../../../../assets/icons/arrows.png" class="mr-2" />
            Ordenar por
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <div *ngIf="showMobileOrderButtons" #ordenamientoMenu class="ordenamiento-container">
        <div class="ordenamiento-mobile-button w-50">
          <div class="col-12 p-0 py-3 d-flex justify-content-center filters-mobile-button">
            <a href="" [routerLink]="" (click)="selectOrdenamiento(0)" class="link-filter d-flex align-items-center">
              Ascendente
            </a>
          </div>
          <div class="col-12 p-0 py-3 d-flex justify-content-center filters-mobile-button">
            <a href="" [routerLink]="" (click)="selectOrdenamiento(1)" class="link-filter d-flex align-items-center">
              Descendente
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 text-center mobile-registry-number py-4">
        {{ totalRegistrosEmpresas }} resultados encontrados
      </div>
    </div>
  </div>
</div>

<!-- TABS FOR MOBILE -->
<div class="d-block d-lg-none mobile-tabs-container">
  <ul class="jpg-navarticletab jpg-navarticletabvtwo nav navbar-nav d-flex flex-row mobile-tabs">
    <li class="nav-item" *ngFor="let indicador of cboIndicadores" (click)="selectTab(indicador)">
      <a [ngClass]="selectedIndicador&&selectedIndicador.id === indicador.id ? 'active' : ''">{{ indicador.id }}
      </a>
    </li>
  </ul>
</div>

<!-- CARDS REPLACING TABLE (MOBILE) -->
<div class="d-block d-lg-none">
  <div *ngIf="empresas.length > 0 && !verCifras" class="border-bottom">
    <div *ngFor="let e of empresasPaginated" class="div-card-row">
      <div class="container-fluid pb-3" style="font-size: 0.8rem">
        <div class="row pt-3 pl-3 pr-3">
          <a class="text-dark d-block w-100" href="javascript:void(0)" (click)="verDetalleEmpresa(e)">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6 p-1 pr-3">
                  <p><b>Nombre de la empresa</b></p>
                  <div class="container-fluid d-flex align-items-baseline p-0">
                    <i class="fa fa-building-o mr-2"></i>
                    <p>{{ e.NombreEmpresa }}</p>
                  </div>
                  <div class="container-fluid p-0">
                    <span class="mr-2" *ngIf="e.ReactivaPeruFormatted && e.ReactivaPeruFormatted != 0">
                      <i class="fa fa-money"></i> S/ {{ e.ReactivaPeruFormatted }}
                    </span>
                  </div>
                  <div *ngIf="e.Covid" class="container-fluid p-0 mt-2">
                    <img src="../../../../assets/img/covid-virus.png" alt="" class="icon icon-semaforo-card mr-2" />
                    <span for="">{{ e.Covid ? e.Covid : "-" }}</span>
                  </div>
                </div>
                <div class="col-6 p-1">
                  <!-- DINAMIC CARDS -->
                  <!-- INFRACCIONES -->
                  <div *ngIf="selectedIndicador.value === '1'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-6 p-1">
                          <p class="mb-2">
                            <b>Indicador <br />ambiental</b>
                          </p>
                          <div class="">
                            <img *ngIf="filtros.agno && e.IndInfAmbSemaforo" class="icon-table" [src]="
                                '../../../../assets/img/' +
                                e.IndInfAmbSemaforo +
                                '.png'
                              " />
                            <img *ngIf="!filtros.agno && e.IndInfAmbAccSemaforo" class="icon-table" [src]="
                                '../../../../assets/img/' +
                                e.IndInfAmbAccSemaforo +
                                '.png'
                              " />
                          </div>
                        </div>
                        <div class="col-6 p-1">
                          <p class="mb-2">
                            <b>Indicador <br />laboral</b>
                          </p>
                          <div class="">
                            <img *ngIf="filtros.agno && e.IndInfLabSemaforo" class="icon-table" [src]="
                                '../../../../assets/img/' +
                                e.IndInfLabSemaforo +
                                '.png'
                              " />
                            <img *ngIf="!filtros.agno && e.IndInfLabAccSemaforo" class="icon-table" [src]="
                                '../../../../assets/img/' +
                                e.IndInfLabAccSemaforo +
                                '.png'
                              " />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2 mt-2">
                              <b>N° Infracciones ambientales</b>
                            </p>
                            <span>{{ e.IndInfAmb }}</span>
                          </div>
                        </div>
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2 mt-2">
                              <b>N° Infracciones laborales</b>
                            </p>
                            <span *ngIf="filtros.agno">{{ e.IndInfLab }}</span>
                            <span *ngIf="!filtros.agno">{{
                              e.IndInfLabAcc
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MULTAS PERDONADAS -->
                  <div *ngIf="selectedIndicador.value === '2'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-6 p-1">
                          <p class="mb-2">
                            <b>N° <br />Infracciones</b>
                          </p>
                          <div class="">
                            <span *ngIf="filtros.agno">{{ e.IndInfAmb }}</span>
                            <span *ngIf="!filtros.agno">{{
                              e.IndInfLabAcc
                              }}</span>
                          </div>
                        </div>
                        <div class="col-6 p-1">
                          <p class="mb-2"><b>Multa impuesta en UIT</b></p>
                          <span>{{ e.MultasPerdonadasUIT }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2"><b>Multa impuesta en $</b></p>
                            <span>{{ e.MultasPerdonadasDolares }}</span>
                          </div>
                        </div>
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2"><b>Multa impuesta en S/.</b></p>
                            <span>{{ e.MultasPerdonadas }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- MULTAS JUDICIALIZADAS -->
                  <div *ngIf="selectedIndicador.value === '3'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12 p-1">
                          <p class="mb-2"><b>N° Multas judicializadas</b></p>
                          <div class="">
                            <span>{{ e.multas_laborales_judicial }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2 mt-2"><b>Multa en $</b></p>
                            <span>{{
                              formatdatanumber(
                              e.monto_multa_judicial_dolares
                              ? e.monto_multa_judicial_dolares
                              : 0
                              )
                              }}</span>
                          </div>
                        </div>
                        <div class="col-6 p-1">
                          <div>
                            <p class="mb-2 mt-2"><b>Multa en S/.</b></p>
                            <span>{{
                              formatdatanumber(
                              e.monto_multa_judicial_soles
                              ? e.monto_multa_judicial_soles
                              : 0
                              )
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- DERRAMES -->
                  <div *ngIf="selectedIndicador.value === '4'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12 p-1">
                          <p class="mb-2 text-center">
                            <b>N° Derrames de petróleo</b>
                          </p>
                          <div class="text-center">
                            <span>{{ e.derramesacc }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ACCIDENTES -->
                  <div *ngIf="selectedIndicador.value === '5'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12 p-1">
                          <p class="mb-2 text-center"><b>N° Accidentes</b></p>
                          <div class="text-center">
                            <span>{{
                              e.accidentesacc
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- PROYECTOS -->
                  <div *ngIf="selectedIndicador.value === '6'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12 p-1">
                          <p class="mb-2 text-center"><b>N° Proyectos</b></p>
                          <div class="text-center">
                            <span *ngIf="filtros.agno">{{
                              e.nroproyectos
                              }}</span>
                            <span *ngIf="!filtros.agno">{{
                              e.nroproyectosacc
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- PERMISOS DE AGUA -->
                  <div *ngIf="selectedIndicador.value === '7'">
                    <div class="container-fluid p-0">
                      <div class="row">
                        <div class="col-12 p-1">
                          <p class="mb-2 text-center">
                            <b>N° Permisos de agua</b>
                          </p>
                          <div class="text-center">
                            <span *ngIf="filtros.agno">{{
                              e.permisosagua
                              }}</span>
                            <span *ngIf="!filtros.agno">{{
                              e.permisosaguaacc
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="empresas.length <= 0">
    <div class="container-fluid card p-3" style="font-size: 0.8rem">
      <p class="mb-0 text-center">
        <b>No se encontraron registros con los criterios ingresados.</b>
      </p>
    </div>
  </div>

  <div *ngIf="empresas.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4">
    <div class="d-flex justify-content-center">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [ellipses]="false"
        [maxSize]="3" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshEmpresasPagination()">
        <ng-template ngbPaginationFirst>Primero</ng-template>
        <ng-template ngbPaginationLast>Último</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>

  <!-- DOWNLOAD BUTTON (MOBILE) -->
  <div class="d-block d-lg-none container-fluid pb-4">
    <div class="row pt-4 pl-4 pr-4">
      <div *ngIf="empresas.length > 0" class="col-md-12">
        <a href="javascript:void(0);" (click)="descargarResultados()"
          class="btn btn-dark text-uppercase font-weight-bold px-5 w-100">
          <i class="fa fa-download"></i> Descargar</a>
      </div>
    </div>
  </div>

  <div *ngIf="empresas.length > 0 && !verCifras" class="col-sm-12 p-4">
    <div style="font-size: 0.77rem">
      <p>
        Fuentes: Organismo de Evaluación y Fiscalización Ambiental (OEFA),
        Superintendencia Nacional de Fiscalización Laboral (SUNAFIL), Ministerio
        de Energía y Minas (MINEM), Ministerio de Economía y Finanzas (MEF),
        Autoridad Nacional del Agua (ANA).
      </p>
      <p>
        <b>*</b> Estas son empresas que no aparecen con procesos de sanción en
        los registros de las entidades fiscalizadores respectivas. En algunos
        casos son compañías que están en suspensión temporal, baja de oficio o
        baja definitiva, según SUNAT, pero sobre las que existe en esta
        plataforma información relevante sobre permisos o actividades que regula
        el Estado.
      </p>
      <a class="metodologia-link" href="" [routerLink]="['/metodologia']">
        <p>Ver metodología</p>
      </a>
    </div>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-xs-12 col-sm-12">
        <div class="row card p-4" style="background-color: #ececec">
          <div class="col-12 text-left p-0">
            <h4>Explora en el mapa:</h4>
            <p class="mb-2">Haz zoom para encontrar lo que buscas</p>

            <div class="row">
              <div class="col-12 col-sm-6 pl-2">
                <div>
                  <img src="../../../../assets/img/circle-color-8.png" alt="Accidentes laborales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Proyectos
                    <span *ngIf="indicadoresNew?.ind_mapa_proyectos === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
                <div>
                  <img src="../../../../assets/img/circle-color-6.png" alt="Accidentes laborales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Derrames de petróleo
                    <span *ngIf="indicadoresNew?.ind_mapa_derrames === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
                <div>
                  <img src="../../../../assets/img/circle-color-1.png" alt="Infracciones ambientales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Permisos de agua
                    <span *ngIf="indicadoresNew?.ind_mapa_permisos === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
                <div>
                  <img src="../../../../assets/img/circle-color-7.png" alt="Accidentes laborales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Accidentes
                    <span *ngIf="indicadoresNew?.ind_mapa_accidentes === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-6 pl-2">
                <div>
                  <img src="../../../../assets/img/circle-color-3.png" alt="Accidentes laborales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Infracciones ambientales
                    <span *ngIf="indicadoresNew?.ind_mapa_ambientales === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
                <div>
                  <img src="../../../../assets/img/circle-color-2.png" alt="Infracciones ambientales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Infracciones laborales
                    <span *ngIf="indicadoresNew?.ind_mapa_laborales === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
                <div>
                  <img src="../../../../assets/img/circle-color-4.png" alt="Infracciones laborales"
                    class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                  <label class="lbl-leyenda mb-0">Pasivos
                    <span *ngIf="indicadoresNew?.ind_mapa_pasivos === 'NUEVO'"
                      class="badge badge-pill badge-danger ml-1">NUEVO</span>
                  </label>
                </div>
              </div>
            </div>

            <br />

            <div>
              <div class="card-body p-0 d-flex justify-content-center">
                <div class="d-flex mr-1">
                  <a class="align-self-center justify-content-center" href="/industrias-extractivas">
                    <img src="../../../../assets/icons/ubicacion.svg" alt="" class="img-fluid icon-base" /></a>
                </div>
                <div class="w-100">
                  <select id="cboTipoInfraccion" class="form-control" name="cboTipoInfraccion"
                    [(ngModel)]="selectedTipoInfraccion" (ngModelChange)="getPointsForMap(true)">
                    <option *ngFor="let tipoInfraccion of cboTipoInfraccion" [ngValue]="tipoInfraccion">
                      {{ tipoInfraccion.id }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div>
              <div class="card-body pl-0 pr-0">
                <div class="position-relative map-long">
                  <div *ngIf="mapaMobile" id="mapa-mapbox" class="echarts position-absolute"
                    style="border-radius: 1em; border: 2px solid black"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div>
          <div class="card border-0 mr-0 ml-0 mb-3 b-rad">
            <div class="card-body p-0">
              <div class="jpg-widget pb-0">
                <div class="row mt-1">
                  <div class="col-2 d-flex justify-content-end align-items-start">
                    <img src="../../../../assets/icons/covid-icon.png" />
                  </div>
                  <div class="col-9">
                    <h5>Cantidad de trabajadores reportados con COVID-19:</h5>
                    <p class="covid-info mb-2">{{ totalCovid }}*</p>
                    <p class="covid-disclaimer">
                      *Datos reportados por empresas mineras al Minem hasta el
                      de agosto de 2020
                    </p>
                  </div>
                </div>
                <div class="row mt-1 mb-4">
                  <div class="col-2 d-flex justify-content-end align-items-start">
                    <img src="../../../../assets/icons/money-icon.png" />
                  </div>
                  <div class="col-9">
                    <h5>Monto de los créditos recibidos por Reactiva Perú:</h5>
                    <p class="reactiva-info mb-2">
                      S/ {{ totalReactiva | number: "1.2-2" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- END CARDS REPLACING TABLE -->

<!-- DESKTOP VIEW-->
<div class="d-none d-lg-block">
  <main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
    <div class="jpg-haslayout">
      <!-- Busqeuda de empresas Listing Start-->
      <div class="jpg-haslayout">
        <div class="container-fluid">
          <div class="row">

            <!--Second column Start-->
            <div class="col-md-12">
              <div class="card border-0 jpg-howitwork-hold jpg-bgwhite jpg-haslayout">
                <div class="row">
                  <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                    <!-- Tabla -->
                    <div class="col-md-12">
                      <div class="table-responsive" style="overflow-x: auto;">
                        <table class="table table-striped jpg-tablecategories tbl-main">
                          <thead class="thead-main">
                            <tr>
                              <th class="text-center fit" style="width: 200px;" scope="col" sortable="NombreEmpresa"
                                (sort)="onSort($event)">
                                Empresa
                              </th>
                              <th class="text-center fit" scope="col"> Ranking</th>
                              <th class="text-center fit" style="width: 100px;" scope="col" *ngIf="!filtros.agno"
                                (click)="onSort('IndInfAmb')">
                                Posición +/-
                              </th>

                              <!-- Columna de Infracciones Ambientales -->
                              <th class="text-center fit" style="width: 150px;" scope="col">

                                <div placement="bottom" [ngbTooltip]="tipContent"
                                  tooltipClass="my-custom-tooltip-class">
                                  Infracciones Ambientales
                                  <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                    target="_blank">
                                    <span>
                                      <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                    </span>
                                  </a>
                                </div>
                              </th>

                              <!-- Columna de Infracciones Laborales -->
                              <th class="text-center fit" style="width: 150px;" scope="col">
                                <div placement="bottom" [ngbTooltip]="tipContent"
                                  tooltipClass="my-custom-tooltip-class">
                                  Infracciones Laborales
                                  <a href="https://convoca.pe/agenda-propia/loreto-las-infracciones-ambientales-de-la-petrolera-del-lote-95-donde-murieron-tres"
                                    target="_blank">
                                    <span>
                                      <img src="../../../../assets/icons/question-circle.png" alt="Icono de pregunta" />
                                    </span>
                                  </a>
                                </div>
                              </th>

                              <th class="text-center" scope="col">Derrames</th>
                              <th class="text-center" scope="col">Accidentes Laborales</th>
                              <!-- <th class="text-center" scope="col">Proyectos y Permisos de agua
                              </th> -->
                              <th class="text-center" scope="col">Multas Judicializadas</th>
                              <!-- <th class="text-center" scope="col">Desde/Hasta</th> -->
                            </tr>
                          </thead>

                          <tbody *ngIf="empresas.length > 0 && !verCifras">
                            <tr *ngFor="let e of empresasPaginated">
                              <td class="text-left" scope="row">
                                <a class="text-dark d-block" href="javascript:void(0)" (click)="verDetalleEmpresa(e)">
                                  <div>
                                    <img src="../../../../assets/icons/building.svg" class="mr-2 icon-table-build" />
                                    {{ e.NombreEmpresa }}
                                  </div>
                                  <span class="mr-2" *ngIf="e.ReactivaPeruFormatted && e.ReactivaPeruFormatted != 0">
                                    <i class="fa fa-money"
                                      [ngbTooltip]="'Montos de los préstamos otorgados a empresas extractivas por el programa Reactiva Perú hasta mayo de 2020.'"
                                      placement="right"></i>
                                    S/ {{ e.ReactivaPeruFormatted }}
                                  </span>
                                  <span *ngIf="e.Covid">
                                    <img class="icon-covid-list" src="../../../../assets/img/covid-virus.png"
                                      [ngbTooltip]="'Número de trabajadores contagiados por COVID-19, según lo reportado por las empresas extractivas al Ministerio de Energía y Minas hasta julio de 2020.'"
                                      placement="right" />
                                    {{ e.Covid ? e.Covid : "-" }}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span *ngIf="e.NotaCovid" class="mt-2">
                                    <i placement="top" [ngbTooltip]="e.NotaCovid" container="body"
                                      class="fa fa-info-circle f-icon"></i>
                                  </span>
                                </a>
                              </td>

                              <!-- Columna de Nota General -->
                              <td class="align-middle text-center">
                                <strong>{{ (e.ranking_actual) }}</strong>
                              </td>

                              <td class="align-middle">
                                <div class="text-center d-flex align-items-center justify-content-center">
                                  <i [ngClass]="getArrowDirection(e.cambio_ranking)"
                                    [ngStyle]="{'color': getArrowColor(e.cambio_ranking), 'font-size': '24px'}"></i>
                                  <strong [ngStyle]="{'color': getColorPuntos(e), 'margin-left': '8px'}">
                                    {{ getPuntosFormatted(e) }}
                                  </strong>
                                </div>
                              </td>

                              <!-- Columna de Infracciones Ambientales -->
                              <td class="align-middle text-center">
                                <div class="mb-3">
                                  <div class="circle"
                                    [ngStyle]="{'background-color':getInfraccionesColor(e.IndInfAmbSemaforo)}">
                                    <strong>{{ e.IndInfAmb }}</strong>
                                  </div>
                                </div>
                              </td>

                              <!-- Columna de Infracciones Laborales -->
                              <td class="align-middle text-center">
                                <div class="mb-3">
                                  <div class="circle"
                                    [ngStyle]="{'background-color':getInfraccionesColor(e.IndInfLabSemaforo)}">
                                    <strong>{{ e.IndInfLab }}</strong>
                                  </div>
                                </div>
                              </td>

                              <!-- Columna de Derrames -->
                              <td class="align-middle text-center">
                                <div class="mb-3">
                                  <strong>{{ e.derramesacc }}</strong>
                                </div>
                              </td>

                              <!-- Columna de Accidentes -->
                              <td class="align-middle text-center">
                                <div class="mb-3">
                                  <strong>{{ e.accidentesacc }}</strong>
                                </div>
                              </td>

                              <!-- Columna combinada de Proyectos y Permisos -->
                              <!-- <td class="align-middle">
                                <div class="mb-3">
                                  <div style="display: flex; justify-content: space-between;">
                                    <span>Proyectos: </span><strong>{{
                                      e.nroproyectosacc }}</strong>
                                  </div>
                                  <div style="display: flex; justify-content: space-between;">
                                    <span>Permisos de Agua: </span><strong [ngStyle]="{'float': 'right'}">{{
                                      e.permisosaguaacc }}</strong>
                                  </div>
                                </div>
                              </td> -->

                              <!-- Columna combinada de Multas -->
                              <td class="align-middle">
                                <div class="mb-3">
                                  <div style="display: flex; justify-content: space-between;">
                                    <span>N° Multas: </span><strong [ngStyle]="{'float': 'right'}">{{
                                      e.multas_laborales_judicial}}</strong>
                                  </div>
                                  <div style="display: flex; justify-content: space-between;">
                                    <span>Multa($): </span><strong [ngStyle]="{'float': 'right'}">{{
                                      e.monto_multa_judicial_dolares }}</strong>
                                  </div>
                                  <div style="display: flex; justify-content: space-between;">
                                    <span>Multa(S/): </span><strong [ngStyle]="{'float': 'right'}">{{
                                      e.monto_multa_judicial_soles }}</strong>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>

                          <tbody *ngIf="empresas.length <= 0">
                            <tr>
                              <td colspan="12">No se encontraron registros con los criterios ingresados.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                      <!-- Paginación -->
                      <div class="d-flex justify-content-center p-2" *ngIf="empresas.length > 0 && !verCifras">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                          [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true"
                          (pageChange)="refreshEmpresasPagination()">
                          <ng-template ngbPaginationFirst>Primero</ng-template>
                          <ng-template ngbPaginationLast>Último</ng-template>
                          <ng-template ngbPaginationPrevious>&lt;</ng-template>
                          <ng-template ngbPaginationNext>&gt;</ng-template>
                          <ng-template ngbPaginationEllipsis>...</ng-template>
                          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                        </ngb-pagination>
                      </div>
                    </div>
                    <div class="col-md-12 text-right" *ngIf="empresas.length > 0">
                      <a role="button" (click)="descargarResultados()"
                        class="btn btn-custom-primary text-uppercase font-weight-bold px-5">
                        <i class="fa fa-download"></i> Descargar
                      </a>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div class="mb-3 row align-items-center">
                  <!-- Columna para el título -->
                  <div class="col-md-6">
                    <p class="m-3">
                      <span class="map-title">Explora en el mapa </span>
                      <span>(Haz zoom para encontrar lo que buscas)</span>
                    </p>
                  </div>
                  <!-- Columna para el selector  -->
                  <div class="col-md-6">
                    <select id="cboTipoInfraccion" class="form-control" name="cboTipoInfraccion"
                      [(ngModel)]="selectedTipoInfraccion" (ngModelChange)="getPointsForMap(true)">
                      <option *ngFor="let tipoInfraccion of cboTipoInfraccion" [ngValue]="tipoInfraccion">
                        {{ tipoInfraccion.id }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="card border-0">
                      <div class="card-body p-0">
                        <div class="position-relative map-long mx-3">
                          <div *ngIf="!mapaMobile" id="mapa-mapbox" class="echarts position-absolute"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row mb-3 mt-2">
                      <div class="col-md-7">
                        <div>
                          <img src="../../../../assets/img/circle-color-2.png" alt="Infracciones ambientales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Infracciones laborales
                            <span *ngIf="indicadoresNew?.ind_mapa_laborales ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                        <div>
                          <img src="../../../../assets/img/circle-color-3.png" alt="Accidentes laborales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Infracciones ambientales
                            <span *ngIf="indicadoresNew?.ind_mapa_ambientales ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                        <div>
                          <img src="../../../../assets/img/circle-color-6.png" alt="Accidentes laborales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Derrames de petróleo
                            <span *ngIf="indicadoresNew?.ind_mapa_derrames ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                        <div>
                          <img src="../../../../assets/img/circle-color-4.png" alt="Infracciones laborales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Pasivos
                            <span *ngIf="indicadoresNew?.ind_mapa_pasivos ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span></label>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div>
                          <img src="../../../../assets/img/circle-color-1.png" alt="Infracciones ambientales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Permisos de agua
                            <span *ngIf="indicadoresNew?.ind_mapa_permisos ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                        <div>
                          <img src="../../../../assets/img/circle-color-8.png" alt="Accidentes laborales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Proyectos
                            <span *ngIf="indicadoresNew?.ind_mapa_proyectos ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                        <div>
                          <img src="../../../../assets/img/circle-color-7.png" alt="Accidentes laborales"
                            class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0" />
                          <label class="lbl-leyenda mb-0">Accidentes
                            <span *ngIf="indicadoresNew?.ind_mapa_accidentes ==='NUEVO'"
                              class="badge badge-pill badge-danger ml-1">NUEVO</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Otros totales start-->
                    <div class="card border-0 b-rad">
                      <div class="card-body p-0">
                        <!-- Sección de COVID -->
                        <div class="row mt-5">
                          <div class="col-2 d-flex justify-content-end align-items-start">
                            <img src="../../../../assets/icons/covid-icon.png" />
                          </div>
                          <div class="col-9">
                            <h5>
                              Cantidad de trabajadores reportados con COVID-19:
                            </h5>
                            <p class="covid-info mb-2">{{ totalCovid }}*</p>
                            <p class="covid-disclaimer">
                              *Datos reportados por empresas mineras al Minem
                              hasta el de agosto de 2020
                            </p>
                          </div>
                        </div>
                        <!-- Sección de Reactiva Perú -->
                        <div class="row mt-1 mb-4">
                          <div class="col-2 d-flex justify-content-end align-items-start">
                            <img src="../../../../assets/icons/money-icon.png" />
                          </div>
                          <div class="col-9">
                            <h5>
                              Monto de los créditos recibidos por Reactiva Perú:
                            </h5>
                            <p class="reactiva-info mb-2">
                              S/ {{ totalReactiva | number: "1.2-2" }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="jpg-contentarticle pl-4 pr-3">
                  <p>
                    Fuentes: Organismo de Evaluación y Fiscalización
                    Ambiental (OEFA), Superintendencia Nacional de
                    Fiscalización Laboral (SUNAFIL), Ministerio de Energía y
                    Minas (MINEM), Ministerio de Economía y Finanzas (MEF),
                    Autoridad Nacional del Agua (ANA).
                  </p>
                </div>
                <div class="jpg-contentarticle pl-4 pr-3">
                  <p>
                    <b>*</b> Estas son empresas que no aparecen con procesos
                    de sanción en los registros de las entidades
                    fiscalizadores respectivas. En algunos casos son
                    compañías que están en suspensión temporal, baja de
                    oficio o baja definitiva, según SUNAT, pero sobre las
                    que existe en esta plataforma información relevante
                    sobre permisos o actividades que regula el Estado.
                  </p>
                  <a class="metodologia-link" href="" [routerLink]="['/metodologia']">
                    <p>Ver metodología</p>
                  </a>
                </div>
              </div>
            </div>
            <!--Second column End-->
          </div>
        </div>
      </div>
      <!-- Busqueda de empresas Listing End-->
    </div>
  </main>
</div>
<!--Main End-->

<!-- TOOLTIP TEMPLATE -->
<ng-template #tipContent>
  <div class="tip-container">
    <div class="tip-message m-2">
      <h2 class="tip-title">Indicador de incumplimiento (Metodología):</h2>
      <div class="d-flex my-2 align-items-center">
        <img class="mr-2 toltip-icon" src="../../../../assets/img/red.png" />
        <p class="tip-description">Muy infractora</p>
      </div>
      <div class="d-flex my-2 align-items-center">
        <img class="mr-2 toltip-icon" src="../../../../assets/img/orange.png" />
        <p class="tip-description">Frecuentemente infractora</p>
      </div>
      <div class="d-flex my-2 align-items-center">
        <img class="mr-2 toltip-icon" src="../../../../assets/img/yellow.png" />
        <p class="tip-description">Medianamente infractora</p>
      </div>
      <div class="d-flex my-2 align-items-center">
        <img class="mr-2 toltip-icon" src="../../../../assets/img/green.png" />
        <p class="tip-description">Poco infractora</p>
      </div>
      <div class="d-flex my-2 align-items-center">
        <img class="mr-2 toltip-icon" src="../../../../assets/img/gray.png" />
        <p class="tip-description">Sin infracciones*</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileFilter let-modal>
  <div class="modal-header d-flex justify-content-end pb-0">
    <a href="" [routerLink]="" (click)="modal.close()">
      <img class="mr-2 toltip-icon" src="../../../../assets/icons/close.png" />
    </a>
  </div>
  <div class="modal-body pt-0">
    <h4 class="modal-title text-center mb-3">Filtrar por:</h4>
    <div class="filter-grid">
      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/economy-activity-icon.png" class="mr-2" />Actividad económica
        </h3>
        <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad" class="w-100">
          <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
            {{ actividad }}
          </option>
        </select>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/empresa.png" class="mr-2" />Empresa
        </h3>
        <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
          [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
          [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)" />
        <div class="invalid-feedback" *ngIf="searchFailed">
          No encontramos coincidencias
        </div>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/map-icon.png" class="mr-2" />Región
        </h3>
        <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion" class="w-100">
          <option *ngFor="let region of cboRegiones" [ngValue]="region">
            {{ region }}
          </option>
        </select>
      </div>

      <div class="filter">
        <h3 class="mb-2 filter-title">
          <img src="../../../../assets/icons/calendar-icon.png" class="mr-2" />
          Año
        </h3>
        <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" class="w-100">
          <option *ngFor="let anio of cboAnios" [ngValue]="anio">
            {{ anio }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 p-0 d-flex mt-4">
      <button type="button" class="btn btn-dark filter-button text-uppercase font-weight-bold w-50"
        (click)="realizarBusqueda()">
        BUSCAR
      </button>
      <div class="w-50 d-flex justify-content-center align-items-center">
        <a href="" [routerLink]="" (click)="limpiarFiltros()" class="red-link">Limpiar filtros</a>
      </div>
    </div>
  </div>
</ng-template>