<form [formGroup]="form">
  <div class="form-group form-inline justify-content-between align-items-center">
    <div class="input-group w-50">
      <input type="text" class="form-control " placeholder="Búsqueda de texto completo, ingrese una palabra clave..."
        formControlName="searchTerm" maxlength="255">
      <div class="input-group-append">
        <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
      </div>
    </div>

    <button *ngIf="inputActions && inputActions.create" type="button" class="btn btn-create"
      (click)="inputActions.create.handler(null)">
      <i [class]="inputActions.create.icon" aria-hidden="true"></i> {{inputActions.create.label}}
    </button>
  </div>
  <div class="table-responsive mb-3">
    <table class="table table-striped table-borderless jpg-table">
      <thead class="thead-main">
        <tr *ngIf="inputHeaders.length>0">
          <ng-container *ngFor="let header of inputHeaders">
            <th *ngIf="header.key === 'id'; else othersHeaders" scope="col" class="text-center align-middle col-id">
              {{header.label}}
            </th>
            <ng-template #othersHeaders>
              <th [sortTable]="header.sortable ? header.key : ''" (sort)="header.sortable ? onSortData($event) : null"
                scope="col" class="text-left align-middle col-fields" [ngClass]="{'disabled-header': !header.sortable}">
                {{ header.label }}
              </th>
            </ng-template>
          </ng-container>
          <th *ngIf="inputActions" scope="col" class="text-center align-middle"> Acciones </th>
        </tr>
      </thead>
      <tbody *ngIf="inputData.length>0" class="tbody-main">
        <tr *ngFor="let row of getData()">
          <ng-container *ngFor="let header of inputHeaders">
            <td *ngIf="header.key === 'id'; else othersFields" scope="col" class="text-center align-middle">
              {{ row[header.key] }}
            </td>
            <ng-template #othersFields>
              <td [ngSwitch]="header.key" scope="col" class="text-left align-middle">
                <div *ngSwitchCase="'link_imagen'" class="bg-img-preview text-center rounded p-2">
                  <img *ngIf="row[header.key]; else noImg" [src]="row[header.key]" [alt]=" row[header.key]"
                    class="img-fluid align-self-center" width="250">
                  <ng-template #noImg>
                    <img src="../../../../assets/img/no-img.png" alt="No hay imagen" class="img-fluid img-thumbnail"
                      width="100">
                  </ng-template>
                </div>
                <a *ngSwitchCase="'link_articulo'" role="link" [href]="row[header.key]" target="_blank"
                  rel="noopener noreferrer">{{row[header.key]}}</a>
                <div *ngSwitchCase="'caracteristicas'" [innerHTML]="row[header.key] | safeHtml"></div>
                <div *ngSwitchCase="'publicado'" class="text-center">
                  {{ row[header.key] ? 'Si': 'No' }}
                </div>
                <span *ngSwitchCase="'color'" class="badge p-2 w-75 text-uppercase"
                  [ngStyle]="{ 'background-color': row[header.key] }">
                  {{ row[header.key] }}
                </span>
                <ng-container *ngSwitchDefault>
                  {{ row[header.key] }}
                </ng-container>
              </td>
            </ng-template>
          </ng-container>
          <td *ngIf="inputActions" class="text-center align-middle">
            <a *ngIf="inputActions.edit" role="button" class="btn-edit mr-2" data-toggle="tooltip"
              data-placement="bottom" title="Editar" (click)="inputActions.edit.handler(row)">
              <i [class]="inputActions.edit.icon" aria-hidden="true"></i>
            </a>
            <a *ngIf="inputActions.delete" role="button" class="btn-delete" data-toggle="tooltip"
              data-placement="bottom" title="Eliminar" (click)="inputActions.delete.handler(row.id)">
              <i [class]="inputActions.delete.icon" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <div class="text-start select-page">
      <span class="jpg-select">
        <select formControlName="pageSize" id="perPage" class="w-100">
          <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
        </select>
      </span>
    </div>
    <div class="mx-auto">
      <ngb-pagination *ngIf="inputPagination; else paginationFE" [collectionSize]="inputPagination.total"
        [(page)]="inputPagination.current_page" [pageSize]="inputPagination.per_page" [ellipses]="false" [maxSize]="5"
        [boundaryLinks]="true" [rotate]="true" (pageChange)="emitPageChange($event)">
        <ng-template ngbPaginationFirst>Primero</ng-template>
        <ng-template ngbPaginationLast>Último</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>

      <ng-template #paginationFE>
        <ngb-pagination [collectionSize]="data.length" [(page)]="page" [pageSize]="form.get('pageSize').value"
          [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="onPageChange($event)">
          <ng-template ngbPaginationFirst>Primero</ng-template>
          <ng-template ngbPaginationLast>Último</ng-template>
          <ng-template ngbPaginationPrevious>&lt;</ng-template>
          <ng-template ngbPaginationNext>&gt;</ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </ng-template>
    </div>
  </div>
</form>
