import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[FileDragNDrop]'
})
export class FileDragNDropDirective {

  @Output() public filesChangeEmiter: EventEmitter<File> = new EventEmitter();
  @HostBinding('class.dragover-active') isDragover = false;
  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragover = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragover = false;
  }

  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragover = false;
    const files: File[] = event.dataTransfer.files;
    if (files && files.length > 0) {
      this.filesChangeEmiter.emit(files[0]);
    }
  }
}
