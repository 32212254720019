<div *ngIf="inputCarouselInfo" [id]="inputCarouselInfo.metadata.id" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div *ngFor="let item of inputCarouselInfo.data; let i = index" [ngClass]="{'active': i === 0}"
      class="carousel-item">
      <a [href]="item.link_articulo" target="_blank">
        <img [src]="item.link_imagen" class="img-convoca" [alt]="item.titulo">
        <div class="carousel-caption col-sm-9 col-md-9 col-lg-7 offset-lg-2 col-xl-5 offset-xl-3 text-left pl-0 ">
          <h2 class="title-reportaje p-1 pl-3">{{ item.titulo }}</h2>
          <p class="p-reportaje p-1 pl-3">{{ item.descripcion }}</p>
        </div>
      </a>
    </div>
  </div>
  <a class="carousel-control-prev" [href]="inputCarouselInfo.metadata.href" data-slide="prev">
    <img id="nameLeft" [src]="srcLeft" (mouseover)="srcLeft = srcLeftOver" (mouseout)="srcLeft = srcLeftActive">
  </a>
  <a class="carousel-control-next" [href]="inputCarouselInfo.metadata.href" data-slide="next">
    <img id="nameRight" [src]="srcRight" (mouseover)="srcRight=srcRightOver" (mouseout)="srcRight=srcRightActive">
  </a>
</div>
