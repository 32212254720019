import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AlertService } from './util/alert.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

// const base_url = environment.base_url + '/usuario/notificaciones';
let base_url = "";

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private http: HttpClient,
    private authService: AuthService,
    private _alertService: AlertService,
    private router: Router
  ) {

    this.validateUserPublic();

  }

  validateUserPublic() {

    if (this.authService.token) {
      base_url = environment.base_url + '/usuario/notificaciones';
    } else {
      base_url = environment.base_url + '/public/notificaciones';
    }

  }

  obtenerNotificacionEmpresa() {

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.token
    });

    return this.http.get(`${base_url}/empresa`, { headers: headers }).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
          }
        }
      ),
      // catchError(err => {
      //   // console.log("ERROR", err);
      //   //Return an empty Observable which gets collapsed in the output
      //   return of(null);
      // })
    );
  }

  regitrarNotificacionEmpresa(ruc: string) {

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.token,
    });

    const body = new HttpParams().set('ruc', ruc);

    // let body = new URLSearchParams();
    // let body = `ruc=${ruc}`;

    return this.http.post(`${base_url}/empresa`, body, { headers: headers }).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
          }
        }
      ),
      // catchError(err => {
      //   // console.log("ERROR", err);
      //   //Return an empty Observable which gets collapsed in the output
      //   return of(null);
      // })
    );

  }

  manejarError(error: HttpErrorResponse) {

    switch (error.status) {

      case 401:

        let titulo = '';
        let subtitulo = '';

        // if(this.authService.token){
        if (this.authService.getToken()) {
          titulo = 'Su sesión ha expirado';
          subtitulo = 'Por favor vuelva a ingresar';
        } else {
          titulo = 'Disponible solo para usuarios registrados';
          subtitulo = 'Por favor ingrese con sus credenciales';
        }

        this._alertService.alertError(titulo, subtitulo, 'Ingresar').then(
          resp => {
            // console.log('resp-alert',resp);
            this.authService.logout();
            this.router.navigate(["/auth/ingreso"]);
          }
        );
        break;
      // case 403:
      //   this._usuarioService.logout();
      //   this.modalError('No tienes permiso para ver esta sección','Vuelve a ingresar por favor');
      //   break;
      default:
        this._alertService.alertError('Ups', 'Ocurrió un error. Vuelve a intentar.');
        break;
    }

    return throwError(error);
  }

}
