<!--Filter Results Strart-->
<div class="d-none d-lg-block jpg-haslayout">
  <!-- <div class="jpg-title">
    <h2>Filtrar resultados:</h2>
  </div> -->
  <div class="">
    <div class="jpg-categoryslidercontent">
      <div class="row">
        <div class="col-12 col-md pb-3 pb-lg-0 d-flex align-items-center">
          <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%;">
            <!-- <h3>Actividad económica</h3> -->
            <h2 style="margin: 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;">Filtrar resultados:</h2>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-industry"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Actividad económica</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad"
                  (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                    {{ actividad }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-building-o"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Empresa</h3>
            <div class="form-group mb-0">

              <!-- <input type="text" placeholder="Empresa" id="organizacion" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()" (input)="filterEmpresas($event.target.value)"> -->

              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
              </ng-template>

              <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed"
                [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
                [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
                (keydown.enter)="realizarBusqueda()" />
              <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
              <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>

            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-map-marker"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Región</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion"
                  (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let region of cboRegiones" [ngValue]="region">
                    {{ region }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md border pb-3 pb-lg-0">
          <figure>
            <i class="fa fa-calendar-o"></i>
          </figure>
          <div class="jpg-cattitle">
            <h3>Año</h3>
            <div class="form-group mb-0">
              <span class="jpg-select">
                <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" (ngModelChange)="realizarBusqueda()">
                  <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                    {{ anio }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md p-3 pt-lg-1 pb-lg-0">
          <div class="jpg-cattitle btn-limpiar">
            <button type="button" class="btn btn-dark text-uppercase font-weight-bold"
              (click)="limpiarFiltros()">Limpiar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Filter Results End-->

<!-- BANNER MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row metodologias-hero">
    <div class="col d-block align-self-center metodologias-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white banner-mobile">Descubre a las empresas mineras y de hidrocarburos en el Perú
        </h2>
        <p class="text-center text-white">Inicio / Búsquedas / Empresas</p>
      </div>
    </div>
  </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xs-12 col-sm-12">
      <div class="row card p-4" style="background-color: #ececec">

        <div class="col-12 text-left p-0">
          <h4>Realiza tu búsqueda:</h4>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-building-o"></i> Empresa</label>
            <!-- <input type="text" class="w-100" placeholder="Empresa" id="organizacion-mb" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()"> -->

            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
            </ng-template>

            <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
              [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa"
              [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)"
              (keydown.enter)="realizarBusqueda()" />
            <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
            <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>

          </div>
          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-industry"></i> Actividad económica</label>
            <select id="cboActividad" class="w-100" name="cboActividad" [(ngModel)]="selectedActividad"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                {{ actividad }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-map-marker"></i> Región</label>
            <select id="cboRegion" class="w-100" name="cboRegion" [(ngModel)]="selectedRegion"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let region of cboRegiones" [ngValue]="region">
                {{ region }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizacion-mb" class="bold"><i class="fa fa-calendar-o"></i> Año</label>
            <select id="cboAnio" class="w-100" name="cboAnio" [(ngModel)]="selectedAnio"
              (ngModelChange)="realizarBusqueda()">
              <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                {{ anio }}
              </option>
            </select>
          </div>

          <div class="form-group d-flex justify-content-between mt-2">
            <button type="button" class="btn btn-dark text-uppercase w-100 mr-2"
              (click)="limpiarFiltros()">Limpiar</button>
            <!-- <button type="button" class="btn btn-danger text-uppercase w-100 ml-2" [routerLink]='["/busqueda-masiva"]'>BÚSQUEDA MASIVA</button> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="empresa" class="d-block d-lg-none container-fluid card" style="background-color: #f9f8f8">

  <div class="row pt-4 pl-4 pr-4">
    <div class="col-8 text-left p-0 pr-2">
      <h5>{{ empresa.NombreEmpresa }}</h5>
      <span class="text-success font-weight-bold">
        <i class="fa fa-building"></i> {{ empresa.Estado }}
      </span>
      <span>
        RUC: {{ empresa.Ruc }}
      </span>
    </div>
    <div class="col-4 text-left p-0">
      <p class="mb-1">Inicio de actividades:</p>
      <span>{{ empresa.InicioActividades }}</span>
    </div>
  </div>

  <div class="jpg-widgettitle mt-2 w-100"></div>
  <div class="row mb-4">
    <div class="col-md-11 mx-auto justify-content-center mb-4">
      <div class="card">
        <div class="jpg-proposalsr mb-0">
          <div class="jpg-proposalsrcontent">
            <div class="row">
              <div class="col-sm-1">
                <figure>
                  <i class="fa fa-building-o"></i>
                </figure>
              </div>
              <div class="col-sm-11 representantes">
                <h4 class="m-0 pt-2 pb-3 h4-representante">Representantes legales</h4>
                <div class="table-responsive">
                  <table class="table">
                    <app-table-sorteable [inputHeaders]="headersRepresentantes"
                      [inputData]="representantes"></app-table-sorteable>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="col-md-11 mx-auto justify-content-center mb-4">
      <div class="card">
        <div class="jpg-proposalsr mb-0">
          <div class="jpg-proposalsrcontent">
            <div class="row">
              <div class="col-sm-1">
                <figure>
                  <i class="fa fa-building-o"></i>
                </figure>
              </div>
              <div *ngIf="estructura" class="col-sm-11 mx-auto representantes">
                <h4 class="m-0 pt-2 pb-3 h4-representante">Estructura empresarial</h4>
                <div class="table-responsive">
                  <table class="table">
                    <app-table-sorteable [inputHeaders]="headersEstructura"
                      [inputData]="datosEstructura"></app-table-sorteable>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row pt-2 pl-4 pr-2">
    <div class="rounded-icon">
      <figure class="alert-primary border-primary">
        <i class="fa fa-money fa-2x"></i>
      </figure>
    </div>
  </div>
  <div class="row pt-1 pl-4 pr-2">
    <div class="col-8 text-left p-0 pr-2">
      <p class="mb-0">
        <b>Monto de los créditos recibidos por Reactiva Perú:</b>
      </p>
    </div>
    <div class="col-4 text-left p-0">
      <p>{{ empresa.ReactivaPeru ? 'S/ ' + empresa.ReactivaPeru : '-' }}</p>
    </div>
  </div>

  <div class="row pt-2 pl-4 pr-2">
    <div class="rounded-icon">
      <figure class="alert-success border-success">
        <img src="../../../../assets/img/covid-virus.png" alt="" class="icon icon-semaforo-1 mr-2 ml-2">
      </figure>
    </div>
  </div>
  <div class="row pt-1 pl-4 pr-2">
    <div class="col-8 text-left p-0 pr-2">
      <p class="">
        <b>Número de personas que la empresa reportó como contagiados</b>
        &nbsp;
        <span *ngIf="empresa.NotaCovid2" class="mt-2" style="font-size: 1.2rem;"><i placement="top"
            [ngbTooltip]="'La cifra corresponde a los trabajadores que dieron positivo a COVID-19 a través de pruebas rápidas. Las compañías también registran pruebas moleculares pero estos test son realizados como prueba confirmatoria de casos positivos a pruebas rápidas.'"
            container="body" class="fa fa-info-circle f-icon"></i></span>
      </p>
    </div>
    <div class="col-4 text-left p-0">
      <p>{{ empresa.Covid }}<span style="font-size: 19px; vertical-align: top;">{{ empresa.NotaCovid2 ? '*' : ''
          }}</span></p>
    </div>
  </div>

  <div *ngIf="empresa" class="row p-4" style="background-color: #f0f0f0">
    <div class="col-12 text-left p-0">
      <div class="row">
        <div class="col-6 pl-2">
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-8.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleAdicional(1)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Proyectos </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda">{{ empresa.nroproyectosacc ? empresa.nroproyectosacc : '0' }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-6.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleAdicional(2)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Derrames </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda">{{ empresa.derramesacc ? empresa.derramesacc : '0' }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-1.png" alt="Infracciones ambientales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleAdicional(3)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Permisos de agua </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda"> {{ empresa.permisosaguaacc ? empresa.permisosaguaacc : '0' }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-7.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleAdicional(4)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Accidentes </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda">{{ empresa.accidentesacc ? empresa.accidentesacc : '0' }}</span>
            </div>
          </div>
        </div>
        <div class="col-6 pl-2">
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-3.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleSemaforo(1)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Infracciones ambientales </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda"> {{ empresa.IndInfAmb ? empresa.IndInfAmb : 0 }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-2.png" alt="Infracciones ambientales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <div>
              <a href="javascript:void(0);" (click)="verDetalleSemaforo(2)">
                <label class="lbl-leyenda mb-0 indicador-leyenda">Infracciones laborales </label>
              </a>
              &nbsp;>&nbsp;
              <span class="nindicador-leyenda"> {{ empresa.IndInfLab ? empresa.IndInfLab : 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-4" style="background-color: #ececec">
    <div class="col-12 text-left p-0">
      <h4>Explora en el mapa:</h4>
      <p class="">Haz zoom para encontrar lo que buscas</p>
      <div class="row">
        <div class="col-6 pl-2">
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-8.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Proyectos </label>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-6.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Derrames de petróleo</label>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-1.png" alt="Infracciones ambientales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Permisos de agua </label>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-7.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Accidentes </label>
          </div>
        </div>
        <div class="col-6 pl-2">
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-3.png" alt="Accidentes laborales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Infracciones ambientales</label>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-2.png" alt="Infracciones ambientales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Infracciones laborales</label>
          </div>
          <div class="d-flex align-items-center mb-2 line-h-12">
            <img src="../../../../assets/img/circle-color-4.png" alt="Infracciones ambientales"
              class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0">
            <label class="lbl-leyenda mb-0">Pasivos</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-4">
    <div class="col-12">
      <div class="card-body p-0 d-flex justify-content-center">
        <div class="d-flex mr-1"><a class="align-self-center justify-content-center" href="/industrias-extractivas">
            <img src="../../../../assets/icons/ubicacion.svg" alt="" class="img-fluid icon-base"></a>
        </div>
        <div class="w-100">
          <select id="cboTipoInfraccion" class="form-control" name="cboTipoInfraccion"
            [(ngModel)]="selectedTipoInfraccion" (ngModelChange)="getPointsForMap(true)">
            <option *ngFor="let tipoInfraccion of cboTipoInfraccion" [ngValue]="tipoInfraccion">
              {{ tipoInfraccion.id }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <div class="card-body pl-0 pr-0">
        <div class="position-relative map-long">
          <div *ngIf="mapaMobile" id="mapa-mapbox" class="echarts position-absolute"
            style="border-radius: 1em; border: 2px solid black;"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row pr-4 pl-4 pb-4">
    <div class="col-12 col-sm-6 d-flex">
      <a *ngIf="!notifIsActive" href="javascript:void(0);" style="font-size: .8rem;" (click)="recibirNotificaciones()"
        class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"><i class="fa fa-bell-o"></i> Recibir
        Notificaciones</a>
      <a *ngIf="notifIsActive" href="javascript:void(0);" style="font-size: .8rem;" (click)="recibirNotificaciones()"
        class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"><i class="fa fa-bell-slash"></i> No
        recibir Notificaciones</a>
    </div>
    <div class="col-12 col-sm-6 align-self-center">
      <a href="javascript:void(0);" (click)="limpiarFiltros(true)" style="font-size: .8rem;"
        class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
    </div>
  </div>

</div>

<!--Main Start-->
<div class="d-none d-lg-block">
  <main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
    <!-- Busqeuda de empresas Listing Start-->

    <div class="jpg-haslayout">
      <div class="container-fluid">

        <!--Copany Details Start-->
        <div *ngIf="empresa" class="row mb-3">
          <div class="col-xs-12 col-sm-12 col-md-12 float-left">
            <div class="card border-0 jpg-bgwhite jpg-haslayout">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <h2>{{ empresa.NombreEmpresa }}</h2>
                    <ul class="jpg-userlisting-breadcrumb jpg-userlisting-breadcrumbvtwo">
                      <li>
                        <span class="text-success font-weight-bold">
                          <i class="fa fa-building"></i> {{ empresa.Estado }} </span>
                      </li>
                      <li><span>
                          <i class="fa fa-list-alt"></i>
                          RUC: {{ empresa.Ruc }}</span>
                      </li>
                      <li>
                        <span><i class="fa fa-calendar-o"></i> INICIO DE
                          ACTIVIDADES: {{ empresa.InicioActividades }}</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-auto align-self-center">
                    <!-- <a href="javascript:void(0);" class="btn btn-dark text-uppercase font-weight-bold">Estructura empresarial</a> -->
                  </div>
                  <div class="col-md-auto align-self-center">
                    <a *ngIf="!notifIsActive" href="javascript:void(0);" (click)="recibirNotificaciones()"
                      class="btn btn-dark text-uppercase font-weight-bold"><i class="fa fa-bell-o"></i> Recibir
                      Notificaciones</a>
                    <a *ngIf="notifIsActive" href="javascript:void(0);" (click)="recibirNotificaciones()"
                      class="btn btn-dark text-uppercase font-weight-bold"><i class="fa fa-bell-slash"></i> Dejar de
                      recibir Notificaciones</a>
                    <a href="javascript:void(0);" (click)="limpiarFiltros(true)"
                      class="btn btn-danger text-uppercase font-weight-bold ml-2">Regresar</a>
                  </div>
                </div>

                <div class="jpg-widgettitle mt-2 w-100"></div>
                <div class="row mb-3">
                  <div class="col-md-12 mx-auto justify-content-center mb-4">
                    <div class="card jpg-proposalsrcontent">
                      <div class="card-header bg-transparent border-0 d-flex align-items-center pb-0">
                        <figure class="mb-3">
                          <i class="fa fa-building-o"></i>
                        </figure>
                        <h4 class="h4-representante "> Representantes legales</h4>
                      </div>
                      <div class=" card-body">
                        <app-table-sorteable [inputHeaders]="headersRepresentantes"
                          [inputData]="representantes"></app-table-sorteable>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mx-auto justify-content-center mb-4">
                    <div class="card jpg-proposalsrcontent">
                      <div class="card-header bg-transparent border-0 d-flex align-items-center pb-0">
                        <figure class="mb-3">
                          <i class="fa fa-building-o"></i>
                        </figure>
                        <div *ngIf="estructura">
                          <h4 class="m-0 pt-2 pb-3 h4-representante">Estructura empresarial</h4>
                        </div>
                      </div>
                      <div class=" card-body">
                        <app-table-sorteable [inputHeaders]="headersEstructura"
                          [inputData]="datosEstructura"></app-table-sorteable>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-6">
                    <div class="card ">
                      <div class="jpg-proposalsr mb-0 ">
                        <div class="jpg-proposalsrcontent">
                          <figure>
                            <i class="fa fa-industry"></i>
                          </figure>
                          <div class="jpg-title pt-2">
                            <h4>Estructura empresarial</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-4">
                    <div class="card">
                      <div class="jpg-proposalsr mb-0 ">
                        <div class="jpg-proposalsrcontent">
                          <figure>
                            <i class="fa fa-bank"></i>
                          </figure>
                          <div class="jpg-title pt-2">
                            <h4>Información financiera</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="row">
                  <div class="col-12 align-self-center">
                    <div class="card">
                      <div class="jpg-proposalsr mb-0 ">
                        <div class="jpg-proposalsrcontent">
                          <div class="row">
                            <div class="col-md-auto" *ngIf="empresa.Covid">
                              <div class="float-left ">
                                <div class="btn-dark btn-lg text-uppercase font-weight-bold">COVID-19
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" *ngIf="empresa.Covid">
                              <div class="jpg-contenthead">
                                <div class="jpg-title pt-1 ">
                                  <h2>Número de personas que la empresa <br>
                                    reportó como contagiados:
                                  </h2>
                                </div>
                                <div class=" float-left h3 text-dark mx-2 pt-2">&nbsp; {{ empresa.Covid }}<span style="font-size: 19px;
                                  vertical-align: top;">{{ empresa.NotaCovid2 ? '*' : '' }}</span>

                                  <span *ngIf="empresa.NotaCovid2" class="mt-2" style="font-size: 1.2rem;"><i
                                      placement="top"
                                      [ngbTooltip]="'La cifra corresponde a los trabajadores que dieron positivo a COVID-19 a través de pruebas rápidas. Las compañías también registran pruebas moleculares pero estos test son realizados como prueba confirmatoria de casos positivos a pruebas rápidas.'"
                                      container="body" class="fa fa-info-circle f-icon"></i></span>

                                </div>
                                <br>
                                <div *ngIf="empresa.NotaCovid2" class="jpg-title pt-1" style="height: 25px">
                                  <span style="font-size: 12px;">*{{ empresa.NotaCovid2 }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-auto"
                              *ngIf="empresa.ReactivaPeruFormatted && empresa.ReactivaPeruFormatted !== 0">
                              <div class="float-left ">
                                <div class="btn-dark btn-lg text-uppercase font-weight-bold">Reactiva Perú
                                </div>
                              </div>
                            </div>
                            <div class="col col-md-4"
                              *ngIf="empresa.ReactivaPeruFormatted && empresa.ReactivaPeruFormatted !== 0">
                              <div class="jpg-contenthead">
                                <div class="jpg-title pt-1">
                                  <h2>Monto del préstamo <br> recibido de Reactiva Perú</h2>
                                </div>
                                <div class=" float-left h3 text-dark mx-2 pt-2">&nbsp;{{ empresa.ReactivaPeruFormatted ?
                                  'S/ ' +
                                  empresa.ReactivaPeruFormatted : '-' }}</div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Copany Details End-->

        <div class="row">
          <div id="jpg-twocolumns" class="jpg-twocolumns jpg-haslayout">
            <!--First Column Start-->

            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 float-left">

              <div class="card border-0 mr-0 ml-0 mb-3">
                <div class="card-body p-0">
                  <div class="jpg-widget mb-0">
                    <div class="row">

                      <div *ngIf="empresa" class="col-md-4">

                        <!--Accordion Section Start-->
                        <ul class="jpg-experienceaccordion accordion">
                          <li>
                            <a href="javascript:void(0);" (click)="verDetalleAdicional(1)">
                              <div class="jpg-accordioninnertitle detalle-adicional-a">
                                <!-- <div class="jpg-projecttitle collapsed" data-toggle="collapse" data-target="#proyectos" aria-expanded="false">  -->
                                <div class="jpg-projecttitle collapsed" aria-expanded="false">
                                  <h3 class="text-uppercase">Proyectos</h3>
                                </div>
                                <div class="jpg-rightarea">
                                  <a href="javascript:void(0);" class="jpg-addinfo jpg-skillsaddinfo collapsed"
                                    data-toggle="collapse" data-target="#proyectos" aria-expanded="false">{{
                                    empresa.nroproyectosacc ? empresa.nroproyectosacc : '0'}}</a>
                                </div>
                              </div>
                            </a>
                            <!-- <div class="jpg-collapseexp collapse" id="proyectos"
                              aria-labelledby="accordioninnertitle" data-parent="#accordion" style="">
                              -
                            </div> -->
                          </li>
                          <!-- <li>
                            <div class="jpg-accordioninnertitle">
                              <div class="jpg-projecttitle collapsed" data-toggle="collapse"
                                data-target="#pasivosAmbientales" aria-expanded="false">
                                <h3 class="text-uppercase">Pasivos Ambientales</h3>
                              </div>
                              <div class="jpg-rightarea">
                                <a href="javascript:void(0);" class="jpg-addinfo jpg-skillsaddinfo collapsed"
                                  data-toggle="collapse" data-target="#pasivosAmbientales"
                                  aria-expanded="false">15</a>
                              </div>
                            </div>
                            <div class="jpg-collapseexp collapse" id="pasivosAmbientales"
                              aria-labelledby="accordioninnertitle1" data-parent="#accordion" style="">
                              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quisquam, expedita? Quidem,
                              minima sint totam cumque ducimus accusamus nulla, voluptas adipisci iusto perspiciatis
                              culpa velit neque molestias doloribus perferendis! Ratione, perferendis?
                            </div>
                          </li> -->
                          <li>
                            <a href="javascript:void(0);" (click)="verDetalleAdicional(2)">
                              <div class="jpg-accordioninnertitle detalle-adicional-a">
                                <div class="jpg-projecttitle collapsed" aria-expanded="false">
                                  <h3 class="text-uppercase">Derrames</h3>
                                </div>
                                <div class="jpg-rightarea">
                                  <a href="javascript:void(0);" class="jpg-addinfo jpg-skillsaddinfo collapsed"
                                    data-toggle="collapse" data-target="#conflictosSociales" aria-expanded="false">{{
                                    empresa.derramesacc ? empresa.derramesacc : '0'}}</a>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" (click)="verDetalleAdicional(3)">
                              <div class="jpg-accordioninnertitle detalle-adicional-a">
                                <div class="jpg-projecttitle collapsed" aria-expanded="false">
                                  <h3 class="text-uppercase">Permisos de Agua</h3>
                                </div>
                                <div class="jpg-rightarea">
                                  <a href="javascript:void(0);" class="jpg-addinfo jpg-skillsaddinfo collapsed"
                                    data-toggle="collapse" data-target="#permisosagua" aria-expanded="false">{{
                                    empresa.permisosaguaacc ? empresa.permisosaguaacc : '0'}}</a>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" (click)="verDetalleAdicional(4)">
                              <div class="jpg-accordioninnertitle detalle-adicional-a">
                                <div class="jpg-projecttitle collapsed" aria-expanded="false">
                                  <h3 class="text-uppercase">Accidentes</h3>
                                </div>
                                <div class="jpg-rightarea">
                                  <a href="javascript:void(0);" class="jpg-addinfo jpg-skillsaddinfo collapsed"
                                    data-toggle="collapse" data-target="#permisosagua" aria-expanded="false">{{
                                    empresa.accidentesacc ? empresa.accidentesacc : '0'}}</a>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <!--Accordion Section End-->
                        <div class="w-100"></div>
                        <!-- Redes Sociales Start-->
                        <div class="card  mr-0 ml-0 my-3 b-rad jpg-haslayout">
                          <div class="card-body p-0">
                            <div class="jpg-widget">
                              <div class="jpg-widgettitle">
                                <h2>Compartir en redes:</h2>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <ul class="jpg-socialiconssimple">
                                    <li class="jpg-facebook">
                                      <a href="javascript:void(0);" (click)="shareFacebook()">
                                        <i class="fa fa-facebook-f social-icon"></i>
                                      </a>
                                    </li>
                                    <li class="jpg-twitter">
                                      <a href="javascript:void(0);" (click)="shareTwitter()">
                                        <i class="fa fa-twitter social-icon"></i>
                                      </a>
                                    </li>
                                    <!-- <li class="jpg-linkedin">
                                      <a href="javascript:void(0);" (click)="shareLinkedin()">
                                        <i class="fa fa-linkedin-in social-icon"></i>
                                      </a>
                                    </li> -->
                                  </ul>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Redes Sociales End-->

                      </div>

                      <div class="col-md-8">

                        <!--Explora el mapa Start-->
                        <div class="jpg-widget p-0 mb-0">
                          <div class=" text-center">
                            <h3 class="font-weight-normal">Explora en el mapa</h3>
                            <p class="text-center" style="font-size: 1.2rem">Haz zoom para encontrar lo que buscas</p>
                          </div>
                          <div class="row">
                            <div class="col-md-12">

                              <!--mapBox Start-->
                              <div class="card border-0 mr-0 ml-0 mb-0">
                                <div class="card-body p-0">
                                  <div class="card mr-0 ml-0 mb-0">
                                    <div class="card-header bg-white">
                                      <div class="card border-0">
                                        <div class="card-body p-0 d-flex justify-content-center">
                                          <div class="d-flex mr-3">
                                            <a class="align-self-center justify-content-center"
                                              href="/industrias-extractivas"><img src="/assets/icons/ubicacion.svg"
                                                alt="" class="img-fluid icon-base"></a>
                                          </div>
                                          <div class="d-flex w-75">
                                            <select id="cboTipoInfraccion" class="form-control" name="cboTipoInfraccion"
                                              [(ngModel)]="selectedTipoInfraccion"
                                              (ngModelChange)="getPointsForMap(true)">
                                              <option *ngFor="let tipoInfraccion of cboTipoInfraccion"
                                                [ngValue]="tipoInfraccion">
                                                {{ tipoInfraccion.id }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-body p-0">
                                      <div class="position-relative map-long">
                                        <div *ngIf="!mapaMobile" id="mapa-mapbox" class="echarts position-absolute">
                                        </div>
                                      </div>
                                      <div class="m-2">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <div><img src="../../../../assets/img/circle-color-2.png"
                                                alt="Infracciones ambientales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Infracciones laborales</label></div>
                                            <div><img src="../../../../assets/img/circle-color-3.png"
                                                alt="Accidentes laborales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Infracciones ambientales</label></div>
                                            <div><img src="../../../../assets/img/circle-color-6.png"
                                                alt="Accidentes laborales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Derrames de petróleo</label></div>
                                            <div><img src="../../../../assets/img/circle-color-4.png"
                                                alt="Infracciones laborales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Pasivos</label></div>
                                          </div>
                                          <div class="col-md-6">
                                            <div><img src="../../../../assets/img/circle-color-1.png"
                                                alt="Infracciones ambientales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Permisos de agua</label></div>
                                            <div><img src="../../../../assets/img/circle-color-8.png"
                                                alt="Accidentes laborales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Proyectos</label></div>
                                            <div><img src="../../../../assets/img/circle-color-7.png"
                                                alt="Accidentes laborales"
                                                class="icon icon-semaforo-leyenda mr-2 ml-2 mb-0"><label
                                                class="lbl-leyenda mb-0">Accidentes</label></div>
                                          </div>
                                        </div>


                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>

                          </div>
                        </div>
                        <!--Explora el mapa End-->

                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!--Fisrt Column End-->

            <!--Second column Start-->
            <div *ngIf="empresa" class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 float-left">

              <!--Otros totales Start-->
              <div class="card border-0 mr-0 ml-0 mb-3 ">
                <div class="card-body p-0">
                  <div class="jpg-widget jpg-haslayout">
                    <div class="jpg-widgettitle text-center">
                      <h3 class="font-weight-normal">Indicador de incumplimiento</h3>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-auto align-self-center ">
                        <div class="jpg-tag jpg-widgettag ">
                          <div class="semaforo m-0 pt-1 pt-lg-2">
                            <ul class="jpg-userlisting-breadcrumb">
                              <li [ngbTooltip]="'Muy infractora'">
                                <img src="../../../../assets/img/red.png" class="icon icon-semaforo-2">
                              </li>
                              <li [ngbTooltip]="'Frecuentemente infractora'"><img
                                  src="../../../../assets/img/orange.png" class="icon icon-semaforo-2">
                              </li>
                              <li [ngbTooltip]="'Medianamente infractora'"><img src="../../../../assets/img/yellow.png"
                                  class="icon icon-semaforo-2">
                              </li>
                              <li [ngbTooltip]="'Poco infractora'"><img src="../../../../assets/img/green.png"
                                  class="icon icon-semaforo-2">
                              </li>
                              <li [ngbTooltip]="'Sin infracciones'" class="pr-0">
                                <img src="../../../../assets/img/gray.png" class="icon icon-semaforo-2">
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="jpg-haslayout">
                      <hr class="border-0 py-1">
                    </div>
                    <div class="row jpg-haslayout">
                      <div class="col-12 mb-3">
                        <div class="jpg-tag jpg-widgettag">
                          <a class="w-100 mx-0" href="javascript:void(0);" (click)="verDetalleSemaforo(1)">
                            <div class="d-flex justify-content-between">
                              <div>INFRACCIONES AMBIENTALES </div>
                              <div>
                                <span class="badge badge-pill py-1 px-2"
                                  [ngClass]="empresa.IndInfAmbSemaforo ? 'badge-' + empresa.IndInfAmbSemaforo : 'badge-dark'">{{
                                  empresa.IndInfAmb ? empresa.IndInfAmb : 0 }}</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <div class="jpg-tag jpg-widgettag">
                          <a class="w-100 mx-0 " href="javascript:void(0);" (click)="verDetalleSemaforo(2)">
                            <div class="d-flex justify-content-between">
                              <div>INFRACCIONES LABORALES</div>
                              <div>
                                <!-- <span class="badge badge-pill badge-dark py-1 px-2">{{ empresa.IndInfLabAcc ? empresa.IndInfLabAcc : 0 }}</span> -->
                                <span class="badge badge-pill py-1 px-2"
                                  [ngClass]="empresa.IndInfLabSemaforo ? 'badge-' + empresa.IndInfLabSemaforo : 'badge-dark'">
                                  {{empresa.IndInfLab ? empresa.IndInfLab : 0 }}</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>

                      <div class="col-12 mb-3">
                        <div class="jpg-tag jpg-widgettag">
                          <a class="w-100 mx-0 " href="javascript:void(0);" (click)="verDetalleSemaforo(4)">
                            <div class="d-flex justify-content-between">
                              <div style="text-transform:uppercase">Multas laborales judicializadas</div>
                              <div>
                                <!-- <span class="badge badge-pill badge-dark py-1 px-2">{{ empresa.IndInfLabAcc ? empresa.IndInfLabAcc : 0 }}</span> -->
                                <span class="badge badge-pill py-1 px-2"
                                  [ngClass]="empresa.IndInfLabSemaforo ? 'badge-' + empresa.IndInfLabSemaforo : 'badge-dark'">
                                  {{empresa.multas_laborales_judicial ? empresa.multas_laborales_judicial : 0 }}</span>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>


                      <div class="col-12 mb-3 mt-2 ml-2">
                        <div class="jpg-tag jpg-widgettag">
                          <p>Fuentes: Organismo de Evaluación y Fiscalización Ambiental (OEFA), Superintendencia
                            Nacional
                            de Fiscalización Laboral (SUNAFIL), Ministerio de Energía y Minas (MINEM), Ministerio de
                            Economía y Finanzas (MEF), Autoridad Nacional del Agua (ANA). </p>
                        </div>
                      </div>

                      <!-- <div class="col-12 mb-3">
                        <div class="jpg-tag jpg-widgettag">
                          <a class="w-100 mx-0" href="javascript:void(0);" (click)="verDetalleSemaforo()">
                            <div class="d-flex justify-content-between">
                              <div>MULTAS PERDONADAS</div>
                              <div>
                                <span class="badge badge-pill badge-dark py-1 px-2">{{ empresa.MultasPerdonadas ? empresa.MultasPerdonadas : 0 }}</span></div>
                            </div>
                          </a>
                        </div>
                      </div> -->
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <!--Second column End-->
          </div>
        </div>
      </div>
    </div>

    <!-- Busqeuda de empresas Listing End-->
  </main>
</div>
<!--Main End-->