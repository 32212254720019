// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'http://172.31.44.153',
  mapboxKey: 'pk.eyJ1IjoiZGVlcGRhdGFjb252b2NhIiwiYSI6ImNrZXlqYmFnNzBlbXYzMG1iY2ljNWVzMncifQ.Sm_dJf0FRaWuAmlY5jJVng',
  googleLoginId: '119420519620-eivt3h5ikrr7u9rb360jgv00i3hmq0tu.apps.googleusercontent.com',
  prueba: 'es solo',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
