import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  confirmButtonColor: string = '#32597a';
  cancelButtonColor: string = '#bd3331';

  constructor(public router: Router) {}

  // MODALS BÁSICOS
  alertSuccess(
    titulo: string,
    mensaje: string = '',
    btnOkText: string = 'Aceptar'
  ) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'success',
      confirmButtonText: btnOkText,
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertInfo(
    titulo: string,
    mensaje: string = '',
    btnOkText: string = 'Aceptar'
  ) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      confirmButtonText: btnOkText,
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertConfirm(
    titulo: string,
    mensaje: string = 'Esta acción no se puede revertir',
    btnOkText: string = 'Sí, proceder',
    btnNoText: string = 'No, aún no'
  ) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'question',
      showCancelButton: true,

      confirmButtonColor: this.confirmButtonColor,
      cancelButtonColor: this.cancelButtonColor,

      confirmButtonText: btnOkText,
      cancelButtonText: btnNoText,

      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertConfirmWithCustomImage(
    titulo: string,
    mensaje: string = 'Esta acción no se puede revertir',
    btnOkText: string = 'Sí, proceder',
    btnNoText: string = 'No, aún no',
    imageUrl: string = '',
    imageWidth: number = 0,
    imageHeight: number = 0
  ) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      showCancelButton: true,

      confirmButtonColor: this.confirmButtonColor,
      cancelButtonColor: this.cancelButtonColor,

      confirmButtonText: btnOkText,
      cancelButtonText: btnNoText,

      allowEscapeKey: false,
      allowOutsideClick: false,

      imageUrl: imageUrl,
      imageWidth: imageWidth,
      imageHeight: imageHeight,
    });
  }

  alertError(titulo: string, mensaje: string, btnText: string = 'Aceptar') {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'error',
      confirmButtonText: btnText,
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertLoading(titulo: string, mensaje: string = 'Por favor, espere') {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    return Swal.showLoading();
  }

  alertLoadingWithTimer(
    titulo: string,
    mensaje: string = 'Por favor, espere',
    timer: number = 2000
  ) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      timer: timer,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    return Swal.showLoading();
  }

  closeAlert() {
    Swal.close();
  }

  // MODALS CUSTOMIZADOS
  alertConfirmCustom() {
    return Swal.fire({
      title: '¿Seguro que desea iniciar el proceso?',
      html: '<h5 class="text-danger bold">Importante: Esta acción no se puede revertir</h5>',
      icon: 'warning',
      showCancelButton: true,

      confirmButtonColor: this.confirmButtonColor,
      cancelButtonColor: this.cancelButtonColor,

      confirmButtonText: 'Sí, proceder',
      cancelButtonText: 'No, aún no',

      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  verModalMultas() {
    return Swal.fire({
      title: 'Muy pronto tendrás acceso a esta información.',
      text: ' Escríbenos a deepdataconvoca@gmail.com para notificarte.',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertInfoComprarPlan() {
    return this.alertInfo(
      'Descubre más funcionalidades',
      'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.',
      'Ver planes'
    ).then((resp) => {
      if (resp.value) {
        this.router.navigate(['/planes']);
      }
    });
  }

  alertSuccessCustom(
    titulo: string,
    mensaje: string = '',
    mensaje2: string,
    btnOkText: string = 'Aceptar'
  ) {
    return Swal.fire({
      title: titulo,
      // text: mensaje,
      html: mensaje + '<br>' + mensaje2,
      icon: 'success',
      confirmButtonText: btnOkText,
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertSuccessHtml(
    titulo: string,
    mensaje: string = '',
    btnOkText: string = 'Aceptar'
  ) {
    return Swal.fire({
      title: titulo,
      // text: mensaje,
      html: mensaje,
      icon: 'success',
      confirmButtonText: btnOkText,
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertInfoCustom(
    titulo: string,
    mensaje: string = '',
    btnOkText: string = 'Aceptar',
    url: string = ''
  ) {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'info',
      confirmButtonText:
        '<a href="' + url + '" target="_blank">' + btnOkText + '</a>',
      confirmButtonColor: this.confirmButtonColor,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  alertWithoutIcon() {
    return Swal.fire({
      // title: 'Holaaaa',
      html: '<br><h3 class="mt-3">Para continuar realizando búsquedas</h3><br>',
      confirmButtonText: 'Registrate aquí',
      confirmButtonColor: '#f73636',
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }
  confirmFileTypeChange(
    titulo: string,
    mensaje: string = 'Se perderán los archivos actuales. ¿Desea continuar?',
    btnOkText: string = 'Sí, cambiar',
    btnNoText: string = 'Cancelar'
  ): Promise<any> {
    return Swal.fire({
      title: titulo,
      text: mensaje,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: this.confirmButtonColor,
      cancelButtonColor: this.cancelButtonColor,
      confirmButtonText: btnOkText,
      cancelButtonText: btnNoText,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }
}
