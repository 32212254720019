<!--Inner Home Banner Start-->
<div class="jpg-haslayout jpg-banerbusqueda">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="jpg-innerbannercontent">
          <div class="jpg-title">
            <h2>Búsqueda masiva de datos</h2>
          </div>
          <ol class="jpg-breadcrumb">
            <li><a href="/app/home.html">Inicio</a></li>
            <li><a href="#">Búsqueda de datos</a></li>
            <li class="jpg-active">Datos encontrados</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Home Banner End-->

<!--Main Start-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
  <div class="jpg-haslayout">

    <!-- Busqeuda de empresas Listing Start-->
    <div class="jpg-haslayout">
      <div class="container-fluid">

        <div class="row">
          <div id="jpg-twocolumns" class="jpg-twocolumns jpg-haslayout">
            <!--First Column Start-->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left">

              <!-- Redes Sociales Start-->
              <div class="card border-0 mr-0 ml-0 mb-3 b-rad">
                <div class="card-body p-0">
                  <div class="jpg-widget">
                    <div class="jpg-widgettitle">
                      <h3>Esto es lo que hemos encontrado.</h3>
                    </div>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-3 col-lg-2 col-xl-3">
                          <select id="verSelect" class="form-control">
                            <option>Ver tabla de datos</option>
                            <!-- <option>Ver reporte</option> -->
                          </select>
                        </div>
                        <div class="col-md-3 col-lg-2 col-xl-3">
                          <!-- <select id="formatoSelect" class="form-control">
                              <option>Archivo de excel</option>
                              <option>Archivo PDF</option>
                            </select> -->

                          <select id="formatoSelect" class="form-control" name="formatoSelect"
                            [(ngModel)]="selectedFormato">
                            <option *ngFor="let formato of formatosDescarga" [ngValue]="formato">
                              {{ formato.id }}
                            </option>
                          </select>

                        </div>
                        <div class="col-md-4 col-lg-6 col-xl-6">
                          <a role="button" (click)="descargarResultados()"
                            class="btn btn-dark text-uppercase font-weight-bold px-5"> <i class="fa fa-download"></i>
                            Descargar</a>
                          <a role="button" (click)="verBusquedaResultados()"
                            class="jpg-btn ml-3 text-uppercase font-weight-bold px-5 button-custom"> Regresar</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <!--Fisrt Column End-->
          </div>
        </div>

        <!--Filter Section Start-->
        <div class="row">
          <!--Results Colum 1 Start-->
          <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 float-left">

            <div class="card border-0 mr-0 ml-0 mb-3">
              <div class="card-body p-0">
                <div class="jpg-widget">


                  <div class="jpg-historycontent">

                    <div class="table-responsive tableScroll">
                      <table class="table table-striped jpg-tablecategories tbl-cruce">
                        <thead>
                          <tr>
                            <th class="text-center fit">RUC</th>
                            <th class="text-center fit w-25">Nombre de la empresa</th>
                            <th class="text-center fit">Inicio actividades</th>
                            <th class="text-center fit">Estado</th>
                            <th class="text-center fit">Covid</th>
                            <th class="text-center fit">Reactiva Perú</th>
                            <th class="text-center fit">Sector económico</th>
                            <th class="text-center fit">Región</th>
                            <th class="text-center">Infracciones ambientales</th>
                            <th class="text-center">Infracciones laborales</th>
                            <!-- <th class="text-center fit">Conflictos sociales</th> -->
                            <th class="text-center fit">Permisos de agua</th>
                            <th class="text-center fit">Nro. proyectos</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let e of cruceDatosResult.empresas">
                            <td class="text-center">{{ e.Ruc }}</td>
                            <td class="text-left">{{ e.NombreEmpresa }}</td>
                            <td class="text-center">{{ e.InicioActividades }}</td>
                            <td class="text-center">{{ e.Estado }}</td>
                            <td class="text-center">{{ e.Covid }}</td>
                            <td class="text-center">{{ e.ReactivaPeru }}</td>
                            <td class="text-left">{{ e.SectorEconomico }}</td>
                            <td class="text-left">{{ e.Region }}</td>
                            <!-- <td>
                                <img *ngIf="e.IndInfAmbSemaforo" class="mr-2 icon-table" [src]="'../../../../assets/img/' + e.IndInfAmbSemaforo + '.png'">
                              </td>
                               -->
                            <td class="align-middle">
                              <div class="text-center">
                                <div>
                                  <img *ngIf="e.IndInfAmbSemaforo" class="icon-table"
                                    [src]="'../../../../assets/img/' + e.IndInfAmbSemaforo + '.png'">
                                </div>
                                <div class="semaforo-nums-container">
                                  <span>{{ e.IndInfAmbAcc }}</span>
                                </div>
                              </div>
                            </td>
                            <!-- <td>
                                <img *ngIf="e.IndInfLabSemaforo" class="mr-2 icon-table" [src]="'../../../../assets/img/' + e.IndInfLabSemaforo + '.png'">
                              </td> -->
                            <td class="align-middle">
                              <div class="text-center">
                                <div>
                                  <img *ngIf="e.IndInfLabSemaforo" class="icon-table"
                                    [src]="'../../../../assets/img/' + e.IndInfLabSemaforo + '.png'">
                                </div>
                                <div class="semaforo-nums-container">
                                  <span>{{ e.IndInfAmbAcc }}</span>
                                </div>
                              </div>
                            </td>

                            <!-- <td class="text-left">{{ e.ConfictosSocAcc }}</td> -->
                            <td class="text-left">{{ e.PermisosAguaAcc }}</td>
                            <td class="text-left">{{ e.NroProyectosAcc }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <p class="p-0 m-0">&nbsp;</p>
                    <div class="form-group jpg-btnarea">
                      <a role="button" (click)="verBusquedaResultados()" class="jpg-btn">
                        <i class="fa fa-upload"></i> Subir otro
                        archivo</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!--Results Colum 1 End-->

          <!--Filters Colum 2 Sube archivo Start-->

          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 float-left">

            <div class="card border-0 mr-0 ml-0 mb-3">
              <div class="card-body p-0 pb-1">
                <div class="jpg-widget">
                  <div class="jpg-widgettitle mb-2 pb-0">
                    <h4>Filtrar</h4>
                  </div>

                  <p class="mb-0">
                    Selecciona los datos que quieres descargar
                  </p>

                  <div class="jpg-formtheme">
                    <fieldset>
                      <div class="jpg-checkboxholder">

                        <span class="jpg-checkbox">
                          <input id="ambientales" type="checkbox" name="ambientales" value="ambientales"
                            [(ngModel)]="ambientales">
                          <label for="ambientales"> Infracciones ambientales</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="laborales" type="checkbox" name="laborales" value="laborales"
                            [(ngModel)]="laborales">
                          <label for="laborales"> Infracciones laborales</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="multas" type="checkbox" name="multas" value="multas" [(ngModel)]="multas">
                          <label for="multas"> Multas Perdonadas</label>
                        </span>

                        <span class="jpg-checkbox">
                          <input id="proyectos" type="checkbox" name="proyectos" value="Proyectos"
                            [(ngModel)]="proyectos">
                          <label for="proyectos"> Proyectos</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="derrames" type="checkbox" name="derrames" value="derrames" [(ngModel)]="derrames">
                          <label for="derrames"> Derrames</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="accidentes" type="checkbox" name="accidentes" value="accidentes"
                            [(ngModel)]="accidentes">
                          <label for="accidentes">Accidentes</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="permisos" type="checkbox" name="permisos" value="permisos" [(ngModel)]="permisos">
                          <label for="permisos"> Permisos de agua</label>
                        </span>

                        <span class="jpg-checkbox">
                          <input id="representantes" type="checkbox" name="representantes" value="representantes"
                            [(ngModel)]="representantes">
                          <label for="representantes"> Representantes</label>
                        </span>
                        <span class="jpg-checkbox">
                          <input id="estructura" type="checkbox" name="estructura" value="estructura"
                            [(ngModel)]="estructura">
                          <label for="estructura"> Estructura empresarial</label>
                        </span>

                      </div>
                    </fieldset>
                  </div>


                </div>
              </div>
            </div>

          </div>

          <!--Filters Colum 2 Sube archivo End-->

        </div>
        <!--Filter Section End-->

      </div>
    </div>
    <!-- Busqeuda de empresas Listing End-->

  </div>
</main>
<!--Main End-->