import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  public isLoggedIn: boolean;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
    this.isLoggedIn = !!this.authService.getToken();
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn) {
      return true;
    }
    this.router.navigateByUrl('/auth/ingreso');
    return false;
  }
}
