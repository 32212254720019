import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-captcha',
  templateUrl: './modal-captcha.component.html',
  styleUrls: ['./modal-captcha.component.css']
})
export class ModalCaptchaComponent implements OnInit {

  // REACTIVE FORMS
  formaCaptcha: FormGroup;

  flagFormaValida = true;

  message: string;

  @Output() captchaGenerado: EventEmitter<boolean>;

  constructor(public activeModal: NgbActiveModal) {

    this.captchaGenerado = new EventEmitter();

  }

  ngOnInit(): void {

    // REACTIVE FORMS FORMAS
    this.formaCaptcha = new FormGroup({
      recaptchaReactive: new FormControl(null, Validators.required),
    });

  }

  enviarCaptcha() {

    if (this.formaCaptcha.valid) {

      this.flagFormaValida = true;
      this.activeModal.close(this.formaCaptcha.controls['recaptchaReactive'].value);

    } else {
      this.flagFormaValida = false;
    }

  }

  closeModal() {
    this.activeModal.close();
  }

}
