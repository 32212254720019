// import { InfraccionLaboralEmpresaConvoca } from "./infraccion-laboral-empresa-convoca.model";
import { CruceDatosEmpresa } from './cruce-datos-empresa.model';

export class CruceDatos {

  empresas: CruceDatosEmpresa[];
  rucs: any;

  constructor() { }

  // fac_indicadores_empresa: any;
  // fac_infracciones_mapa: any;
  // infracciones_laborales_empresa_convoca: InfraccionLaboralEmpresaConvoca[];
  // rucs: any;

}
