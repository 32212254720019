import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor() { }

  public catchBadResponse(response: any): Observable<any> {
    let errorMessage: string = '';
    if (response.error instanceof ErrorEvent) {
      errorMessage = `Error del cliente: ${response.error.message}`;
    } else {
      errorMessage = `Error del servidor: ${response.status} - ${response.message}`;
    }
    return throwError(() => errorMessage);
  }
}