import { Component, OnInit } from '@angular/core';
import { IContentArticles } from '../../interfaces';
import { CmsSeccionesService } from '../../services/cms-secciones.service';

@Component({
  selector: 'app-reportajes',
  templateUrl: './reportajes.component.html',
  styleUrls: ['./reportajes.component.css'],
})
export class ReportajesComponent implements OnInit {

  public articulos: IContentArticles[] = [];
  public pagination: any = null;

  constructor(
    private readonly cmsSeccionesService: CmsSeccionesService
  ) { }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getContentReportajes();
  }

  private getContentReportajes(pagination?: any): void {
    this.cmsSeccionesService.getContentArticles(pagination).subscribe({
      next: (resp: any) => {
        if (resp.success) {
          this.articulos = resp.response.data;
          this.pagination = resp.response.pagination;
        }
      },
      error: (error) => {
        console.error('Error al obtener el contenido del reportaje: ', error);
      },
    });
  }

  public onPageChange(page: number): void {
    this.getContentReportajes({ page });
  }
}
