import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})

export class CarouselComponent implements OnInit {
  public srcLeft: string = '../../../assets/buttons/buttons_circle_active_left.png';
  public srcLeftActive: string = '../../../assets/buttons/buttons_circle_active_left.png';
  public srcLeftOver: string = '../../../assets/buttons/buttons_circle_hover_left.png';

  public srcRight: string = '../../../assets/buttons/buttons_circle_right_active.png';
  public srcRightActive: string = '../../../assets/buttons/buttons_circle_right_active.png';
  public srcRightOver: string = '../../../assets/buttons/buttons_circle_right_hover.png';

  @Input() inputCarouselInfo: any;
  constructor() { }

  ngOnInit(): void { }

}
