<!--Inner newsletter Start-->
<section class="jpg-haslayout pt-3 pb-2 jpg-news">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h4>SUSCRÍBETE A NUESTRO NEWSLETTER</h4>
      </div>
    </div>
  </div>
</section>

<!--Inner newsletter End-->

<!--Inner Credits Start-->
<section class="d-none d-md-block jpg-haslayout pt-2 pb-2 jpg-news-2">
  <div class="container-fluid">
    <div class="d-none d-md-block col-sm-12 text-center">
      <form [formGroup]="formaNews" class="form-inline d-flex justify-content-center" (ngSubmit)="validarNews()">
        <input type="text" class="form-control-news" placeholder="Nombre y apellido" id="nombres"
          formControlName="nombres" name="nombres" />
        <input type="email" class="form-control-news" placeholder="Email" id="email2" formControlName="email"
          name="email" />
        <button type="submit" class="btn btn-danger btn-suscribete">
          Suscríbete
        </button>

        <div class="row pl-5">
          <div class="col-sm-12 text-center">
            <div class="jpg-socialiconssimple d-flex justify-content-center">
              <li class="jpg-facebook">
                <a href="javascript:void(0);" (click)="shareFacebook()">
                  <i class="fa fa-facebook-f social-icon icon-footer"></i>
                </a>
              </li>
              <li class="jpg-twitter">
                <a href="javascript:void(0);" (click)="shareTwitter()">
                  <i class="fa fa-twitter social-icon icon-footer"></i>
                </a>
              </li>
              <li class="jpg-twitter">
                <a href="javascript:void(0);" (click)="shareLinkedin()">
                  <i class="fa fa-linkedin social-icon icon-footer"></i>
                </a>
              </li>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="!flagFormaValida">
          <span class="text-white">{{ mensajeError }}</span>
        </div>
      </form>
    </div>

    <div class="row d-block d-md-none col-sm-12 text-center">
      <form [formGroup]="formaNews" class="form-inline d-flex justify-content-center" (ngSubmit)="validarNews()">
        <div class="col-sm-12">
          <input type="text" class="form-control-news" placeholder="Nombre y apellido" id="nombres2"
            formControlName="nombres" name="nombres" />
        </div>

        <div class="col-sm-12">
          <input type="email" class="form-control-news" placeholder="Email" id="email" formControlName="email"
            name="email" />
        </div>
        <br />
        <div class="col-sm-12">
          <button type="submit" class="btn btn-danger btn-suscribete">
            Suscríbete
          </button>
        </div>

        <div class="col-sm-12 mt-4">
          <div class="col-sm-12 text-center">
            <div class="jpg-socialiconssimple d-flex justify-content-center">
              <li class="jpg-facebook">
                <a href="javascript:void(0);" (click)="shareFacebook()">
                  <i class="fa fa-facebook-f social-icon icon-footer"></i>
                </a>
              </li>
              <li class="jpg-twitter">
                <a href="javascript:void(0);" (click)="shareTwitter()">
                  <i class="fa fa-twitter social-icon icon-footer"></i>
                </a>
              </li>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="!flagFormaValida">
          <span class="text-white">{{ mensajeError }}</span>
        </div>
      </form>
    </div>
  </div>

  <br />
  <div class="container">
    <!-- <div class="row">
          <div class="col-sm-12 text-center">
              <h4>Suscríbete a nuestro newsletter</h4>
          </div>
      </div> -->
    <div class="row m-0 d-none d-md-block">
      <div class="col-sm-12 offset-md-1 col-md-10 offset-lg-2 col-lg-9 text-center">
        <div class="d-flex justify-content-between font-weight-bold">
          <!-- <a>Plataforma de datos</a> -->
          <a class="footer-link" href="https://convoca.pe/deepdata/terminosycondicionesdeuso" target="_blank">Términos y
            condiciones de uso</a>
          <a class="footer-link" routerLink="/main/nosotros">Nosotros</a>
          <a class="footer-link" routerLink="/main/reportajes">Reportajes</a>
          <a class="footer-link" routerLink="/main/metodologia">Metodología</a>
          <!-- <a class="footer-link" href="https://convoca.pe/deepdata/nosotros" target="_blank">Nosotros</a>
                  <a class="footer-link" href="https://convoca.pe/deepdata/reportajes" target="_blank">Reportajes</a>
                  <a class="footer-link" href="https://convoca.pe/deepdata/metodologia" target="_blank">Metodología</a> -->
          <a class="footer-link"
            href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
            target="_blank">Laboratorio de datos</a>
        </div>
      </div>
    </div>

    <br /><br />

    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <div class="jpg-footerlogohold d-flex justify-content-end">
          <img style="height: 4.8rem; margin-right: 0.6rem" src="../../../assets/img/convoca-logo-blanco.png"
            alt="logo-convocadatos-blanco" />
          <div class="jpg-description">
            <p class="footer-text">
              <b>Sobre Convoca Deep Data: </b>Plataforma de búsqueda y análisis
              de datos para obtener información de impacto público. Hemos hecho
              el trabajo pesado de la minería de datos para entregarte lo
              esencial: las joyas del conocimiento.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
        <div class="jpg-footerlogohold">
          <div class="jpg-description">
            <p class="footer-text">
              <b>Créditos: </b>Directora general: Milagros Salazar Herrera.
              Periodistas y analistas de datos: Edwin Montesinos, Luis Enrique
              Pérez, Asís Loyola. Científica de datos: Malena Maguiña.
              Diseñadores: Jimmy Pazos y Javier Pereira. Desarrolladores: Diego
              López y Jimmy Salazar. Jefe de proyecto: Víctor Anaya. Responsable
              de Monetización: Walter Reyes. Editor de audiencias y redes
              sociales: Antonio Manco. Producción audiovisual: Francisco
              Rodríguez. Director de tecnología de Convoca.pe: Elvis Rivera.
              Coordinación general: Jackeline Cárdenas Ipenza.
            </p>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="row m-0 footer-text">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <div class="jpg-footerlogohold d-flex justify-content-end">
          <img style="height: 3.5rem; margin-right: 1.1rem" src="/../../../assets/img/logo-convoca-blanco.png"
            alt="logo-convoca-blanco" />
          <div class="jpg-description">
            <!-- <h6 class="text-white mt-4"></h6> -->
            <p class="footer-text">
              <b>Sobre Convoca: </b>Convoca es un medio digital de periodismo de
              investigación y análisis de datos fundado en 2014 por reporteros,
              analistas de datos y programadores en Perú. Juntos creamos este
              espacio para unir lo mejor de la reportería, el uso inteligente de
              las bases de datos, la mirada multidisciplinaria y las nuevas
              narrativas digitales para investigar de manera sistemática las
              redes del poder político y corporativo que afecta la vida de los
              ciudadanos.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
        <!-- <div class="jpg-footerlogohold jpg-footercol jpg-widgetcompany"> -->
        <div class="jpg-footerlogohold d-flex justify-content-end">
          <img style="height: 4rem; margin-right: 2rem" src="../../../assets/img/velocidad-blanco.png"
            alt="logo-convocadatos-blanco" />
          <div class="jpg-description">
            <!-- <h6 class="text-white mt-4"></h6> -->
            <p class="footer-text">
              Esta iniciativa cuenta con el apoyo del fondo Velocidad, que busca
              la sostenibilidad de medios digitales en crecimiento y que generan
              alto impacto público. Convoca.pe fue uno de los medios ganadores
              en América Latina.
            </p>
          </div>
        </div>
      </div>
    </div>

    <br /><br />

    <div class="jpg-haslayout jpg-footerbottom">
      <div class="container-fluid m-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <p class="jpg-copyrights">Contáctanos a: deepdataconvoca&#64;gmail.com</p>
        </div>
      </div>
    </div>

    <div class="jpg-haslayout jpg-footerbottom">
      <div class="container-fluid m-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <p class="jpg-copyrights">
            ©Convoca {{currentYear}} - Todos los derechos reservados
          </p>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
</section>
<!--Inner Credits End-->

<!-- Credits Responsive -->
<section class="d-block d-md-none jpg-haslayout pt-2 pb-2 jpg-news-2">
  <div class="row col-12 m-0 mt-2 d-flex justify-content-center">
    <form [formGroup]="formaNews" class="form-inline" (ngSubmit)="validarNews()">
      <div class="col-sm-12">
        <input type="text" class="form-control-news w-100 m-0 mb-3" placeholder="Nombre y apellido" id="nombres3"
          formControlName="nombres" name="nombres" />
        <input type="email" class="form-control-news w-100 m-0 mb-3" placeholder="Email" id="email3"
          formControlName="email" name="email" />
      </div>

      <div class="col-sm-12 mt-2">
        <button type="submit" class="btn btn-dark m-1 btn-custom-red w-100">
          Suscríbete
        </button>
      </div>

      <div class="form-group m-0 mt-3" *ngIf="!flagFormaValida">
        <span class="text-white">{{ mensajeError }}</span>
      </div>
    </form>
  </div>

  <br />

  <div class="container-fluid m-0">
    <hr class="div-white" />

    <div class="row m-0 d-block d-md-none">
      <div class="col-sm-12 text-left">
        <ul class="custom-list">
          <li>
            <a class="footer-link m-2" href="https://convoca.pe/deepdata/terminosycondicionesdeuso"
              target="_blank">Términos y condiciones de uso</a>
          </li>
          <li>
            <a class="footer-link m-2" routerLink="/main/nosotros">Nosotros</a>
          </li>
          <li>
            <a class="footer-link m-2" routerLink="/main/reportajes">Reportajes</a>
          </li>
          <li>
            <a class="footer-link m-2" routerLink="/main/metodologia">Metodología</a>
          </li>
          <li>
            <a class="footer-link m-2"
              href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
              target="_blank">Laboratorio de datos</a>
          </li>
        </ul>
      </div>
    </div>

    <hr class="div-white" />

    <div class="row m-0 mb-1 mt-4">
      <div class="col-12 text-center mb-3">
        <img class="footer-img" src="../../../assets/img/convoca-logo-blanco.png" alt="logo-convocadatos-blanco" />
      </div>

      <div class="col-12">
        <div class="footer-p">
          <p class="text-justify">
            Sobre Convoca Deep Data: Plataforma de búsqueda y análisis de datos
            para obtener información de impacto público.
          </p>
        </div>
      </div>

      <div class="col-12">
        <div class="footer-p">
          <p class="text-justify">
            Créditos: Directora general: Milagros Salazar Herrera. Periodistas y
            analistas de datos: Edwin Montesinos, Luis Enrique Pérez, Asís
            Loyola. Científica de datos: Malena Maguiña. Diseñadores: Jimmy
            Pazos y Javier Pereira. Desarrolladores: Diego López y Jimmy
            Salazar. Jefe de proyecto: Víctor Anaya. Responsable de
            Monetización: Walter Reyes. Editor de audiencias y redes sociales:
            Antonio Manco. Producción audiovisual: Francisco Rodríguez. Director
            de tecnología de Convoca.pe: Elvis Rivera. Coordinación general:
            Jackeline Cárdenas Ipenza.
          </p>
        </div>
      </div>
    </div>

    <br />

    <div class="row m-0 mb-1">
      <div class="col-12 text-center mb-3">
        <img class="footer-img" src="../../../assets/img/logo-convoca-blanco.png" alt="logo-convocadatos" />
      </div>

      <div class="col-12">
        <div class="footer-p">
          <p class="text-justify">
            Sobre Convoca: Convoca es un medio digital de periodismo de
            investigación y análisis de datos fundado en 2014 por reporteros,
            analistas de datos y programadores en Perú. Juntos creamos este
            espacio para unir lo mejor de la reportería, el uso inteligente de
            las bases de datos, la mirada multidisciplinaria y las nuevas
            narrativas digitales para investigar de manera sistemática las redes
            del poder político y corporativo que afecta la vida de los
            ciudadanos.
          </p>
        </div>
      </div>
    </div>

    <div class="row m-0 mb-1">
      <div class="col-12 text-center mb-3">
        <img class="footer-img" src="../../../assets/img/velocidad-blanco.png" alt="logo-velocidad" />
      </div>

      <div class="col-12">
        <div class="footer-p">
          <p class="text-justify">
            Esta iniciativa cuenta con el apoyo del fondo Velocidad, que busca
            la sostenibilidad de medios digitales en crecimiento y que generan
            alto impacto público. Convoca.pe fue uno de los medios ganadores en
            América Latina.
          </p>
        </div>
      </div>
    </div>

    <!-- Redes Sociales Start-->
    <div class="row m-0 mb-1 footer-social">
      <li class="nav-item nav-convoca d-md-none pt-3 w-100">
        <div class="d-flex align-items-center justify-content-center pb-3">
          <div class="social-header d-flex">
            <a class="jpg-facebook rounded-social mr-3" href="javascript:void(0);" (click)="shareFacebook()">
              <i class="fa fa-facebook-f social-icon icon-footer" style="font-size: 0.8rem"></i>
            </a>
            <a class="jpg-twitter rounded-social mr-3" href="javascript:void(0);" (click)="shareTwitter()">
              <i class="fa fa-twitter social-icon icon-footer" style="font-size: 0.8rem"></i>
            </a>
            <a class="jpg-twitter rounded-social mr-3" href="javascript:void(0);" (click)="shareLinkedin()">
              <i class="fa fa-linkedin social-icon icon-footer" style="font-size: 0.8rem"></i>
            </a>
          </div>
        </div>
      </li>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="footer-p">
          <p class="text-center mb-0">
            Contáctanos a: deepdataconvoca&#64;gmail.com
          </p>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="footer-p">
          <p class="text-center mb-0">
            ©Convoca {{currentYear}} - Todos los derechos reservados
          </p>
        </div>
      </div>
    </div>
  </div>
</section>