import { Component, OnInit } from '@angular/core';
import { NewsletterService } from '../../../services/newsletter.service';
import { AlertService } from '../../../services/util/alert.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShareSocialMediaService } from '../../../services/util/share-social-media.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  // REACTIVE FORMS
  formaNews: FormGroup;
  flagFormaValida: boolean = true;
  mensajeError: string = '';

  constructor(
    private _newsletterService: NewsletterService,
    private _alertService: AlertService,
    private _shareSocialMedia: ShareSocialMediaService
  ) { }

  ngOnInit(): void {
    this.formaNews = new FormGroup({
      nombres: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      // organizacion: new FormControl(null,[Validators.required]),
    });
  }

  validarNews() {
    if (this.formaNews.valid) {
      this.flagFormaValida = true;
      this._alertService.alertLoading('Procesando solicitud');

      this._newsletterService
        .suscribirse(
          this.formaNews.controls['email'].value,
          this.formaNews.controls['nombres'].value
        )
        .subscribe(
          (resp) => {
            if (resp?.success) {
              this._alertService.alertSuccess(
                'A un paso de unirte a nuestro newsletter',
                `Te enviamos un correo de validación a ${this.formaNews.controls['email'].value}`
              );
            }
          },
          (error) => {
            if (error.error.code === 'ALREADY_SUBSCRIBED') {
              this._alertService.alertInfo(
                'Ya eres parte de nuestra comunidad',
                `El correo ${this.formaNews.controls['email'].value} ya se encuentra suscrito a nuestro newsletter.`
              );
            } else {
              this._alertService.alertError(
                'Ups!',
                `Ocurrió un problema. Vuélvelo a intentar por favor.`
              );
            }
          }
        );
    } else {
      this.flagFormaValida = false;
      this.mensajeError =
        'Los datos ingresados no son correctos, verifique por favor.';
    }
  }

  // PARA COMPARTIR EN REDES SOCIALES
  shareFacebook() {
    this._shareSocialMedia.shareFacebook('https://deepdata.convoca.pe/');
  }

  shareTwitter() {
    this._shareSocialMedia.shareTwitter('https://deepdata.convoca.pe/');
  }

  shareLinkedin() {
    window.open('https://www.linkedin.com/company/87205149/admin/', '_blank');
    // this._shareSocialMedia.shareLinkedin('https://deepdata.convoca.pe/');
  }
}
